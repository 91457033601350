import { Slider, Typography } from '@mui/material';

const Slider_ = ({ control }: any) => {
  // Metrics to be added
  return (
    <>
      {control.visible && (
        <>
          <Typography fontSize={12} textAlign={'left'} fontWeight={500} paddingTop={1}>
            {control.controlLabel}
          </Typography>
          <Slider size="small" defaultValue={control.defaultValue} aria-label="Small" valueLabelDisplay="auto" />
        </>
      )}
    </>
  );
};

export default Slider_;
