export default function getAssetUri(assetUrl: string | undefined): string | undefined {
  try {
    if (!assetUrl) return;
    const imageSourceUrl = new URL(assetUrl);
    const assetId = imageSourceUrl.pathname.replace('/', '');
    return `asset://${assetId}`;
  } catch (e) {
    throw new Error(`Invalid asset URL: ${assetUrl}`);
  }
}
