import { AdvertisingAccountMetadata, Cards, UserDetails } from '@amzn/genaihub-typescript-client';
import { Theme } from '@mui/material/styles';
import React, { createContext, useState } from 'react';
import { AlertEventType, ColorScheme } from 'src/components/common/types';
import { defaultTheme } from 'src/config/themes';
import { AlertTargets } from 'src/constants';
import { useErrorHandler } from 'src/hooks/useErrorHandler';
import { useMetrics } from 'src/hooks/useMetrics';
import { MetricsProvider } from 'src/metrics';

// Global Type
export type AccountType = 'internal' | 'external';

// Global Type
export type AlertEvent = {
  target?: AlertTargets;
  control?: {
    controlName: string;
    position: string;
  };
  type: 'info' | 'warning' | 'error' | 'success' | 'feature-introduction';
  header?: string;
  text?: string;
  link?: string;
  linkText?: string;
  code?: number;
  show?: boolean;
  resourceKey?: string;
  resourceArgs?: Record<string, unknown> | undefined;
};

export type AppContextState = {
  activePage: string;
  setActivePage: (page: string) => void;
  openEditor: boolean;
  setOpenEditor: (open: boolean) => void;
  headerDimensions: { width: number; pageHeaderHeight: number; navHeaderHeight: number };
  setHeaderDimensions: (dimensions: { width: number; pageHeaderHeight: number; navHeaderHeight: number }) => void;
  footerDimensions: object;
  setFooterDimensions: (dimensions: object) => void;
  authenticated: boolean;
  setAuthenticated: (authenticated: boolean) => void;
  activeTheme: Theme;
  setActiveTheme: (theme: Theme) => void;
  colorScheme: ColorScheme;
  setColorScheme: (scheme: ColorScheme) => void;
  tools: Cards;
  setTools: (tools: Cards) => void;
  selectedTool: string | undefined;
  setSelectedTool: (selectedTool: string) => void;
  showLandingPage: boolean;
  setShowLandingPage: (showLandingPage: boolean) => void;
  alertEvent: AlertEvent | undefined;
  setAlertEvent: React.Dispatch<React.SetStateAction<AlertEventType | undefined>>;
  userDetails: UserDetails | undefined;
  setUserDetails: React.Dispatch<React.SetStateAction<UserDetails | undefined>>;
  metrics: MetricsProvider;
  accountType?: AccountType;
  setAccountType: React.Dispatch<React.SetStateAction<AccountType | undefined>>;
  showSwitchAccountModal: boolean;
  setShowSwitchAccountModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAdvertisingAccount: AdvertisingAccountMetadata | null;
  setSelectedAdvertisingAccount: React.Dispatch<React.SetStateAction<AdvertisingAccountMetadata | null>>;
};

export type AppContextOverride = {
  activePage?: string;
  openEditor?: boolean;
  headerDimensions?: { width: number; pageHeaderHeight: number; navHeaderHeight: number };
  footerDimensions?: { width?: number; height?: number };
  authenticated?: boolean;
  activeTheme?: Theme;
  tools?: Cards;
  selectedTool?: string;
  alertEvent?: AlertEvent;
  userDetails?: UserDetails;
  accountType?: AccountType;
  showSwitchAccountModal?: boolean;
  selectedAdvertisingAccount?: AdvertisingAccountMetadata | null;
  colorScheme?: ColorScheme;
};

declare global {
  // Duplicate from above
  type AccountType = 'internal' | 'external';

  type FileUploadEvent = {
    payload: File;
    controlName: string;
  };

  // Duplicate from above
  type AlertEvent = {
    target?: AlertTargets;
    control?: {
      controlName: string;
      position: string;
    };
    type: 'info' | 'warning' | 'error' | 'success' | 'feature-introduction';
    header?: string;
    text?: string;
    link?: string;
    linkText?: string;
    code?: number;
    show?: boolean;
    resourceKey?: string;
    resourceArgs?: Record<string, unknown> | undefined;
  };
}

export const AppContext = createContext({
  activePage: 'home',
} as AppContextState);

export const useAppContext = (initial?: AppContextOverride) => {
  const [activePage, setActivePage] = useState<string>(initial?.activePage ?? 'home');
  const [openEditor, setOpenEditor] = useState<boolean>(initial?.openEditor ?? false);
  const [headerDimensions, setHeaderDimensions] = useState<{ width: number; pageHeaderHeight: number; navHeaderHeight: number }>(
    initial?.headerDimensions ?? {
      width: 0,
      pageHeaderHeight: 0,
      navHeaderHeight: 0,
    },
  );
  const [footerDimensions, setFooterDimensions] = useState<{ width?: number; height?: number }>(initial?.footerDimensions ?? {});
  const [authenticated, setAuthenticated] = useState<boolean>(initial?.authenticated ?? false);
  const [activeTheme, setActiveTheme] = useState<Theme>(initial?.activeTheme ?? defaultTheme);
  const [colorScheme, setColorScheme] = useState<ColorScheme>(initial?.colorScheme ?? 'primary');
  const [tools, setTools] = useState<Cards>(initial?.tools ?? []);
  const [selectedTool, setSelectedTool] = useState<string>(initial?.selectedTool ?? '');
  const [alertEvent, setAlertEvent] = useState<AlertEvent | undefined>(initial?.alertEvent);
  const [userDetails, setUserDetails] = useState<UserDetails | undefined>(initial?.userDetails);
  const [accountType, setAccountType] = useState<AccountType | undefined>(initial?.accountType);
  const [showSwitchAccountModal, setShowSwitchAccountModal] = useState(initial?.showSwitchAccountModal ?? false);
  const [selectedAdvertisingAccount, setSelectedAdvertisingAccount] = useState<AdvertisingAccountMetadata | null>(
    initial?.selectedAdvertisingAccount ?? null,
  );
  const metrics = useMetrics({
    pageName: `${activePage}${openEditor ? '/editor' : ''}`,
    userDetails: userDetails,
    toolname: openEditor && selectedTool ? selectedTool : undefined,
    accountType: accountType,
    selectedAccount: selectedAdvertisingAccount,
  });
  useErrorHandler(metrics);

  const appContextState: AppContextState = {
    activePage,
    setActivePage,
    openEditor,
    setOpenEditor,
    headerDimensions,
    setHeaderDimensions,
    footerDimensions,
    setFooterDimensions,
    authenticated,
    setAuthenticated,
    activeTheme,
    setActiveTheme,
    colorScheme,
    setColorScheme,
    tools,
    setTools,
    selectedTool,
    setSelectedTool,
    showLandingPage: true,
    setShowLandingPage: () => {},
    alertEvent,
    setAlertEvent,
    userDetails,
    setUserDetails,
    metrics,
    accountType,
    setAccountType,
    showSwitchAccountModal,
    setShowSwitchAccountModal,
    selectedAdvertisingAccount,
    setSelectedAdvertisingAccount,
  };

  return appContextState;
};

export const AppContextProvider = ({ context, children }: { context?: AppContextOverride; children: React.ReactNode }) => {
  const appContextState = useAppContext(context);
  return <AppContext.Provider value={appContextState}>{children}</AppContext.Provider>;
};
