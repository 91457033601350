import { urlToFile } from 'src/components/utils/base64Encode';

interface DownloadFromUrlProps {
  url: string;
  fileName?: string;
}

export async function convertToBlobAndDownload(url: string) {
  if (!url) return;
  const file = await urlToFile(url);
  const urlObj = new URL(url);
  const extension = urlObj.pathname.split('.').pop();
  const downloadProps = { url: URL.createObjectURL(file), fileName: `ailab-${crypto.randomUUID()}.${extension}` };
  downloadFromUrl(downloadProps);
}

export function downloadFromUrl(props: DownloadFromUrlProps) {
  if (!props.url) return;
  const link = document.createElement('a');
  link.download = props.fileName || props.url;
  link.href = props.url;
  link.click();
}

interface OpenNewTabProps {
  url: string;
}

export function openNewTab(props: OpenNewTabProps) {
  if (!props.url) return;
  const link = document.createElement('a');
  link.href = props.url;
  link.target = '_blank';
  link.click();
}
