import { PublishAssetFromUrlInput } from '@amzn/genaihub-typescript-client';
import { useContext } from 'react';
import { useAIBackendHubClient } from 'src/api/useApi';
import {
  AddToAssetLibraryFailureNotification,
  AddToAssetLibraryProcessingNotification,
  AddToAssetLibrarySuccessNotification,
} from 'src/components/snackbar/notifications/AddToAssetLibraryNotifications';
import { SnackbarContext } from 'src/components/snackbar/SnackbarContext';
import { Metrics } from 'src/constants';
import { AppContext } from '../../AppContext';

interface SaveAssetProps {
  assetUrl: string;
  asin?: string;
}

const useAssetLibrary = () => {
  const client = useAIBackendHubClient();
  const snackbarContext = useContext(SnackbarContext);
  const appContext = useContext(AppContext);

  const saveAsset = async (props: SaveAssetProps): Promise<Error | void> => {
    const { assetUrl, asin } = props;

    if (!props.assetUrl) return;

    // Prepare a processing notification in case the publish asset request takes longer than expected.
    const cleanupProcessingNotification = snackbarContext.addProcessingNotification({
      SnackbarContent: AddToAssetLibraryProcessingNotification,
    });

    const alternateIds = appContext.selectedAdvertisingAccount?.alternateIds;
    // Publish the asset
    const requestPayload = {
      profileId: alternateIds && alternateIds.length > 0 ? alternateIds[0] : '',
      assetUrl,
      ...(asin && { asinList: [asin] }),
    };

    let saveResult: Error | undefined;

    try {
      await client.publishAssetFromUrl(requestPayload as PublishAssetFromUrlInput);
    } catch (err) {
      console.error(err);
      saveResult = new Error('Failed to save asset to Asset Library.');
    }

    if (saveResult instanceof Error) {
      // Track assets saved to Amazon Library failure
      appContext.metrics.trackMetrics(Metrics.Methods.SaveAsset, {}, { [Metrics.Counters.Failure]: 1 });
      // Send a failure notification to the user
      snackbarContext.addFailureNotification({
        SnackbarContent: AddToAssetLibraryFailureNotification,
      });
    } else {
      // Track assets saved to Amazon Library
      appContext.metrics.trackMetrics(Metrics.Methods.SaveAsset, {}, { [Metrics.Counters.Success]: 1 });
      // Send a success notification to the user
      snackbarContext.addSuccessNotification({
        SnackbarContent: AddToAssetLibrarySuccessNotification,
      });
    }

    // Clean up the processing notification
    cleanupProcessingNotification();

    return saveResult;
  };

  return {
    saveAsset,
  };
};

export default useAssetLibrary;
