import { Feedback as FeedbackModel, UserSentiment, WorkflowId } from '@amzn/genaihub-typescript-client';
import { Message as MessageModel } from '@amzn/red-carpet-service-client';
import { Button, Icon, Popper, Text, TextButton } from '@amzn/storm-ui';
import { IconDefinition, spinner } from '@amzn/storm-ui-icons';
import { useMutation } from '@tanstack/react-query';
import { useContext, useMemo, useState } from 'react';
import { CHAT_THEME } from 'src/components/assistant/theme';
import FeedbackPopoverStorm from 'src/components/common/storm/FeedbackPopoverStorm';
import { Metrics } from 'src/constants';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import styled, { css } from 'styled-components';
import { AppContext } from '../../../../../AppContext';

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.base};
  flex-direction: column;
  max-width: 350px;
`;

const Title = styled(Text)`
  font-size: 15px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
`;

const Message = styled(Text)`
  font-size: 13px;
  line-height: 19px;
  text-align: left;
`;

const SubmitButton = styled(Button)`
  &&,
  &&:visited,
  &&:active,
  &&:link,
  &&:hover {
    background-color: ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.black};
  }
`;

const DismissButton = styled(TextButton)`
  &&,
  &&:visited,
  &&:active,
  &&:link,
  &&:hover {
    color: ${({ theme }) => theme.palette.white};
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${CHAT_THEME.spacing.basePlus};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${CHAT_THEME.spacing.miniPlus};
`;

const IconButton = styled(TextButton)<{ inverted?: boolean }>`
  color: ${({ theme, inverted }) => (inverted ? theme.palette.white : theme.palette.gray[700])};
  ${({ inverted }) =>
    inverted &&
    css`
      &&:hover,
      &&:active {
        color: ${({ theme }) => theme.palette.gray[200]};
      }
    `}
`;

interface Props {
  icon: string | IconDefinition;
  title?: string;
  body?: string;
  imageSource?: string;
  feedback: FeedbackModel;
  message: MessageModel;
  inverted?: boolean;
  onSubmit: (feedback: FeedbackModel) => void;
}

export default function Feedback({ message, icon, title, body, feedback, onSubmit, imageSource, inverted }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [commentModelOpen, setCommentModelOpen] = useState(false);
  const genAIBackendClient = useAIBackendHubClient();

  const appContext = useContext(AppContext);
  const { metrics, selectedTool } = appContext;

  const feedbackPrompt = useMemo(() => {
    let prompt = `MessageId: ${message.messageId}`;
    if (imageSource) {
      try {
        const imageSourceUrl = new URL(imageSource);
        const assetId = imageSourceUrl.pathname.split('.')[0].replace('/', '');
        prompt += ` ImageSource: ${assetId}`;
      } catch (e) {
        console.error('Invalid image source', imageSource);
      }
    }
    return prompt;
  }, [message, imageSource]);

  const feedbackMutation = useMutation({
    mutationKey: ['feedback', message.messageId],
    mutationFn: async () => {
      metrics.trackMetrics(
        Metrics.Methods.ChatFeedback,
        {},
        { [Metrics.Counters[feedback === 'LIKE' ? 'Like' : 'Dislike']]: 1 },
        { [Metrics.Dimensions.ConversationId]: message.conversationId || '' },
      );
      await genAIBackendClient.submitFeedbackSentiment({
        body: {
          feedback,
          workflowId: (selectedTool as WorkflowId) || null,
          prompt: feedbackPrompt,
          scope: 'OTHER',
        } as UserSentiment,
      });
      return feedback;
    },
    onSuccess: () => {
      setIsOpen(true);
      onSubmit(feedback);
    },
  });

  return (
    <>
      <Popper
        withCloseButton
        type="blue"
        align="center"
        trigger={
          <IconButton
            data-testid={`user-feedback-${feedback.toString().toLowerCase()}`}
            inverted={inverted}
            onClick={() => feedbackMutation.mutate()}
            disabled={feedbackMutation.isPending}
          >
            <Icon type={feedbackMutation.isPending ? spinner : icon} blockSize={false} size="lg" />
          </IconButton>
        }
        isOpen={isOpen}
        onCloseButtonClick={() => setIsOpen(false)}
        position={'top'}
      >
        <Wrapper>
          <Body>
            <Title>{title}</Title>
            <Message>{body}</Message>
          </Body>
          <ActionButtons>
            <DismissButton onClick={() => setIsOpen(false)}>Dismiss</DismissButton>
            <SubmitButton onClick={() => setCommentModelOpen(true)} primary>
              Add a comment
            </SubmitButton>
          </ActionButtons>
        </Wrapper>
      </Popper>
      <FeedbackPopoverStorm
        toggle={commentModelOpen}
        closePopover={() => {
          setIsOpen(false);
          setCommentModelOpen(false);
        }}
        feedbackScope={'OTHER'}
        sentiment={feedback}
        // a stupid thing with the param is that it uses the same link to preview the image in the UI and to send the URI,
        // so the URI would be a signed URL which is wrong and useless, but it's fine we have the asset id in the prompt anyway
        imageSrc={imageSource}
        prompt={feedbackPrompt}
      />
    </>
  );
}
