import { MbmProvider } from '@amzn/react-arb-tools';
import { ThemeProvider as StormUIThemeProvider } from '@amzn/storm-ui';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles'; // to be removed once text to image workflow is changed
import { QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { AppContext, useAppContext } from 'src/../AppContext';
import Chat from 'src/components/pages/conversational/ChatPage';
import { queryClient } from 'src/components/QueryClient';
import { SnackbarContextProvider } from 'src/components/snackbar/SnackbarContext';
import { SnackbarManager } from 'src/components/snackbar/SnackbarManager';
import { Metrics, STUDIO } from 'src/constants';
import Stack from 'src/customUIComponents/Stack';
import { getMbmOptions } from 'src/helpers';
import WithNavigationMetrics from 'src/metrics/WithNavigationMetrics';
import { ImportImageContextProvider } from 'src/v2/contexts/ImportImageContext';
import EditorP1 from './editor/EditorP1';
import Editor from './editor/legacy/Editor';
import './App.css';
import AccountList from './layout/_storm/AccountList';
import Content from './layout/_storm/Content';
import Footer from './layout/_storm/Footer';
import CreativeGallery from './pages/CreativeGallery';
import errorpage from './pages/errorpage';
import InspirationsGallery from './pages/InspirationsGallery';
import Home from './pages/storm/Home';
import Playground from './pages/storm/Playground';
import Studio from './pages/studio/StudioPage';
import Tools from './pages/Tools';
import WithAuthentication from './WithAuthentication';
import Header from '../components/pages/studio/Header';
import arbManifest from '../i18n/translations/arbManifest';

const mbmOptions = getMbmOptions(arbManifest);

const App = ({ page, editor, unAuthenticated }: { page: string; editor?: boolean; unAuthenticated?: boolean }) => {
  const context = useAppContext({ activePage: page });

  // Set page styles for editor
  useEffect(() => {
    context.setActivePage(page);
    context.setOpenEditor(!!editor);
    localStorage.setItem('activePage', page);
    localStorage.setItem('openEditor', JSON.stringify(!!editor));
    if (page.toLocaleLowerCase() === 'studio') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [page, editor]);

  const pages: { [key: string]: React.FunctionComponent } = {
    home: Home,
    play: Tools,
    browse: InspirationsGallery,
    notfounderror: errorpage,
    chat: Chat,
    playground: Playground as React.FunctionComponent,
    studio: Studio,
    gallery: CreativeGallery,
  };

  const getContent = () => {
    const Content = pages[context.activePage];
    return context.openEditor ? (
      context.selectedTool === 'TEXT_TO_IMAGE' ? (
        <Editor />
      ) : (
        <EditorP1 type="editor" module={context.selectedTool} />
      )
    ) : (
      <Content />
    );
  };

  const showFooter = !context.openEditor && ![STUDIO.toLowerCase(), 'chat'].includes(page);

  return (
    <MbmProvider {...mbmOptions}>
      <AppContext.Provider value={context}>
        <ThemeProvider theme={context.activeTheme}>
          <StormUIThemeProvider>
            <QueryClientProvider client={queryClient}>
              <SnackbarContextProvider>
                <ImportImageContextProvider>
                  <SnackbarManager></SnackbarManager>
                  <CssBaseline />
                  <WithAuthentication
                    unAuthenticated={unAuthenticated}
                    unAuthenticatedCallback={() => {
                      context.metrics.trackMetrics(Metrics.Methods.PageMetrics, {}, { [Metrics.Names.UserBounced]: 1 });
                      window.location.replace('https://advertising.amazon.com');
                    }}
                  >
                    <WithNavigationMetrics activePage={`${context.activePage}${context.openEditor ? '/editor' : ''}`}>
                      <div id="wrapper" className="wrapper">
                        {context.accountType === 'external' && <AccountList />}
                        <Header
                          style={{
                            paddingLeft: 'var(--div-spacing-default)',
                            paddingRight: 'var(--div-spacing-default)',
                            position: context.activePage === 'chat' ? 'sticky' : 'relative',
                            width: '100%',
                            maxWidth: '2000px',
                            top: 0,
                          }}
                        />
                        <Stack>
                          <Content>{getContent()}</Content>
                          {showFooter && <Footer />}
                        </Stack>
                      </div>
                    </WithNavigationMetrics>
                  </WithAuthentication>
                </ImportImageContextProvider>
              </SnackbarContextProvider>
            </QueryClientProvider>
          </StormUIThemeProvider>
        </ThemeProvider>
      </AppContext.Provider>
    </MbmProvider>
  );
};

export default App;
