import { Children, CSSProperties, forwardRef, isValidElement, PropsWithChildren, ReactNode } from 'react';
import './css/FlexboxLayout.css';

interface StackProps {
  className?: string;
  style?: CSSProperties;
  direction?: 'row' | 'column';
  wrap?: 'wrap' | 'nowrap';
  gap?: string;
}

const Stack = forwardRef<HTMLDivElement, PropsWithChildren & StackProps>((props, ref) => {
  const { className, children, ...rest } = props;
  //const Item = (item: any) => <div className="flex-item">{item}</div>;

  const RenderChild = (children: ReactNode) => {
    return Children.map(children, (child) => {
      if (isValidElement(child)) {
        return <child.type {...(child.props as object)} />;
      }
      return child;
    });
  };

  return (
    <div ref={ref} {...rest} className={className ? `${className} flex-container` : `flex-container`}>
      <>{RenderChild(children)}</>
    </div>
  );
});

Stack.displayName = 'StackRef';
export default Stack;
