import { Asset } from '@amzn/genaihub-typescript-client';
import { SearchInput } from '@amzn/storm-ui';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import AssetGallery from './AssetGallery';
import config from '../../../config/inspirations.json';
import getBreakpoints from '../../utils/getBreakpoints';
import { getWorkflowNameById } from '../../utils/getWorkflowNameById';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 0;
  width: 100%;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px calc(50% - 475px);
  padding-bottom: 10px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  height: 200px;
  font-size: 25px;
  font-weight: 400;
  color: var(--text-color-variant-1);
  margin-bottom: -80px;
`;

export const TEST_ID_SCROLLABLE_ASSET_GALLERY = 'scrollable-asset-gallery';

export interface ScrollableGalleryProps<T> {
  content: T[];
  loading?: boolean;
  showSearch?: boolean;
  fetchContent: () => void;
  clickCallback?: (asset: Asset) => void;
  filterContent?: (searchValue: string) => T[];
}

const ScrollableAssetGallery = <T extends Asset>(props: ScrollableGalleryProps<T>) => {
  const { content, loading, showSearch, fetchContent, clickCallback, filterContent } = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const [scrollPercentage, setScrollPercentage] = useState<number>(0);
  const [xl, lg, md, sm] = getBreakpoints('xl', 'lg', 'md', 'sm');
  const columns = xl ? 3 : lg ? 3 : md ? 3 : sm ? 2 : 1;

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchContent();
    window.addEventListener('scroll', onScrollEvent, { passive: true });
    return () => window.removeEventListener('scroll', onScrollEvent);
  }, []);

  const onScrollEvent = () => {
    const { scrollingElement } = document;
    let scrollInPercentage = 0;
    if (scrollingElement) {
      scrollInPercentage = ((scrollingElement.scrollTop + scrollingElement.clientHeight) / scrollingElement.scrollHeight) * 100;
    }
    setScrollPercentage(scrollInPercentage);
  };

  useEffect(() => {
    if (scrollPercentage && scrollPercentage > 60 && !loading) {
      fetchContent();
    }
  }, [scrollPercentage]);

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchValue(event.target?.value || '');
  };

  const handleOnClear = () => {
    setSearchValue('');
  };

  const defaultFilter = (searchValue: string): T[] => {
    const value = searchValue.toLowerCase();
    return content.filter((item: T) => {
      return item.type?.toLowerCase().includes(value) || getWorkflowNameById(item.workflowId)?.toLowerCase().includes(value);
    });
  };

  const filteredContent = filterContent ? filterContent(searchValue) : defaultFilter(searchValue);
  const searchError = searchValue !== '' && filteredContent.length < 1;

  return (
    <ContentContainer data-testid={TEST_ID_SCROLLABLE_ASSET_GALLERY}>
      {showSearch && (
        <SearchContainer id="Inspirations-header">
          <SearchInput
            id="inspirations-search-bar"
            placeholder="Search"
            ariaLabel="Search Bar"
            fullWidth
            value={searchValue}
            onChange={handleSearchChange}
            onClear={handleOnClear}
            message={searchError ? config.searchError : ''}
            {...(searchError ? { statusType: 'error' } : {})}
          />
        </SearchContainer>
      )}
      <AssetGallery assets={filteredContent} columns={columns} loading={loading} spacing={2} detailsClickHandler={clickCallback} />
      {loading && (
        <Footer>
          <CircularProgress />
        </Footer>
      )}
    </ContentContainer>
  );
};

export default ScrollableAssetGallery;
