import { Button } from '@mui/material';

const Button_ = ({ control }: any) => {
  return (
    <>
      {control.visible && (
        <>
          <Button variant="contained">{control.description}</Button>
        </>
      )}
    </>
  );
};

export default Button_;
