import { Box, Dialog, ImageList, ImageListItem, LinearProgress, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { EditorContext } from 'src/components/editor/EditorContext';

const singleImage = (file: any) => (
  <ImageList gap={0} cols={1}>
    <ImageListItem rows={1}>
      <img srcSet={file?.content} src={file?.content} alt={file?.name} style={{ maxHeight: '160px' }} loading="lazy" />
    </ImageListItem>
  </ImageList>
);

export default function FileUploadProgress({ fileList = [] }: { fileList: any }) {
  const context = useContext(EditorContext);
  const { workflowOptions, openFileUploadDialog } = context;

  const FileUploading = ({ file }: any) => (
    <Stack direction={'row'}>
      <Box
        width={200}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '1em',
          overflow: 'hidden',
        }}
      >
        {singleImage(file)}
      </Box>

      <Stack direction={'column'} gap={2} pl={5} pr={5} justifyContent={'center'}>
        <Typography>Please wait while we upload your Image</Typography>

        <Typography fontSize={'12px'}>Uploading...</Typography>

        <LinearProgress />
      </Stack>
    </Stack>
  );

  return (
    <Dialog open={openFileUploadDialog}>
      <Stack direction={'column'}>
        {fileList.map((item: string, index: number) => (
          <FileUploading key={'fileUploading' + index} file={workflowOptions[item].content} />
        ))}
      </Stack>
    </Dialog>
  );
}
