import { Tools as ToolsType } from '@amzn/genaihub-typescript-client';
import { Container, Grid } from '@mui/material';
import { useEffect, useContext, useState } from 'react';
import SignIn from 'src/components/signin/Signin';
import { STUDIO } from 'src/constants';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { AppContext } from '../../../AppContext';
import { tools } from '../../config';
import conversational from '../../images/Conversational_exp.png';
import imageEditing from '../../images/Image_editing.png';
import imageToImage from '../../images/image_gen_module_images.png';
import AIModel from '../layout/mui/AIModel';
import AIModelSkeleton from '../layout/mui/AIModelSkeleton';
import HeadedContainer from '../layout/mui/HeadedContainer';

const cardImages = [imageToImage, imageEditing, conversational];

export interface renderCardsType {
  type: string;
  description: string;
  thumbnail: string;
  title: string;
  videoPreview: string;
  spotlight: string;
}

const Tools = () => {
  const context = useContext(AppContext);
  const genAIBackendClient = useAIBackendHubClient();
  const [showSignInDialog, setShowSignInDialog] = useState(false);

  const gridColumns = 4;

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    getToolsList();
  }, []);

  const getToolsList = async () => {
    try {
      const response = await genAIBackendClient.retrieveToolsList({});
      const result = response.body;
      context.setTools(result.cards || []);
    } catch (error) {
      console.log(error);
    }
  };

  const isAuthenticated = () => {
    setShowSignInDialog(true);
    return false;
  };

  const renderCards = () => {
    let cards = [];
    if (!context.tools.length) {
      for (var i = 0; i < 6; i++) {
        cards.push(<AIModelSkeleton key={`skeleton${i}`} gridColumns={gridColumns} />);
      }
    } else {
      cards = context.tools
        .sort((a, b) => a.order - b.order)
        .filter((tool: ToolsType) => tool.type !== STUDIO && (tool.spotlight as string) !== 'hidden')
        .map((item: ToolsType, index: number) => {
          if (!item.thumbnail && cardImages[index]) item.thumbnail = cardImages[index];
          return <AIModel key={index} post={item as renderCardsType} gridColumns={gridColumns} isAuthenticated={isAuthenticated} />;
        });
    }
    return cards;
  };

  return (
    <Container className="page-padding" maxWidth={false} sx={{ mt: '3em', mb: '3em', maxWidth: '1282px' }}>
      <HeadedContainer config={tools} data-testid="tools-section">
        <Grid container spacing={4} data-testid="tools-container">
          {renderCards()}
        </Grid>
      </HeadedContainer>
      <SignIn open={showSignInDialog} handleClose={() => setShowSignInDialog(false)} />
    </Container>
  );
};

export default Tools;
