import { BoundingBox } from '@amzn/genaihub-typescript-client';
import { Button, Icon, ThemeProvider, Tooltip } from '@amzn/storm-ui';
import { IconDefinition } from '@amzn/storm-ui-icons';
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from 'src/../AppContext';
import { AnimatedButton } from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/AnimatedButton';
import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import { FA_ICON_SIZE, HEADING_TEXT_SIZE } from 'src/constants';
import { ImageModalContext } from '../../ImageModalContext';
import { ProductEditAction, useWorkflow, WorkflowId } from '../utils';
import styles from './PositionControls.modules.scss';
import { ProductPositionCanvas } from './ProductPositionCanvas';
import { overrideMouseLeaveTimeout } from '../inputs/ImageEditingCanvas';
export const POSITION_CONTROL_RESET_BTN_TEST_ID = 'studio-edit-reset-btn';
export const PositionControls: FC = () => {
  const { imageUrl, savedEditsImageUrl } = useContext(ImageModalContext);
  const { activeEditProductAsset, setActiveEditProductAsset } = useContext(ImageModalContext);
  const { activePage, userDetails } = useContext(AppContext);
  const { setPendingGeneration, setActiveEditResults } = useContext(ImageModalContext);
  const {
    isPending: isPendingSubmit,
    submitWorkflow,
    updateWorkflowOptions,
    submissionQuery: { data: result },
    workflowOptions,
  } = useWorkflow<ProductEditAction>({
    workFlowId: WorkflowId.PRODUCT_EDIT,
    userAlias: userDetails?.alias,
    pageName: activePage,
  });

  const asset = activeEditProductAsset;

  const [boundingBox, setBoundingBox] = useState<BoundingBox>({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    ...asset?.boundingBox,
  });

  const [originalBoundingBox, setOriginalBoundingBox] = useState<BoundingBox>();

  useEffect(() => {
    if (asset && asset.boundingBox) {
      setBoundingBox(asset.boundingBox);
      if (!originalBoundingBox) setOriginalBoundingBox(asset.boundingBox);
    }
  }, [asset]);

  useEffect(() => {
    setPendingGeneration(isPendingSubmit);
  }, [isPendingSubmit]);
  useEffect(() => {
    if (result) {
      setActiveEditResults(result);
    } else {
      setActiveEditResults(undefined);
    }
  }, [result?.jobs?.[0].status]);
  return (
    <div className={styles.container}>
      <ControlLabel
        title="Change size and position"
        subTitle="To resize your product, drag the corners of the frame. To reposition your product, move the frame."
        titleSize={HEADING_TEXT_SIZE.MEDIUM}
      />
      <ProductPositionCanvas
        onReposition={(adjustedBoundingBox) => {
          updateWorkflowOptions({
            imageId: asset?.imageId,
            boundingBox: adjustedBoundingBox,
          });
          setBoundingBox(adjustedBoundingBox);
        }}
        ready={!!asset}
        boundingBox={boundingBox}
        canvasWidth={400}
        backgroundImageURL={savedEditsImageUrl || imageUrl}
        productImageURL={asset?.productImage || 'https://placehold.co/50x30'}
      />
      <div>
        <ThemeProvider theme={overrideMouseLeaveTimeout}>
          <Tooltip
            trigger={
              <div>
                <Button
                  data-testid={POSITION_CONTROL_RESET_BTN_TEST_ID}
                  className={styles.resetButton}
                  onClick={() => {
                    originalBoundingBox && setBoundingBox(originalBoundingBox);
                  }}
                >
                  <Icon size={FA_ICON_SIZE.LARGE} type={faRotateLeft as IconDefinition} />
                </Button>
              </div>
            }
            message="Reset scaling and position"
          />
        </ThemeProvider>
      </div>
      <div className={styles.controlSection}>
        <AnimatedButton
          dataTestId="studio-edit-generate-button"
          animate={isPendingSubmit}
          style={{ minWidth: '150px' }}
          disabled={isPendingSubmit || !asset || !workflowOptions}
          fullscreen={false}
          text={isPendingSubmit ? 'Generating' : 'Generate'}
          clickHandler={() => {
            if (!asset?.imageId) return;
            submitWorkflow();
            setActiveEditProductAsset({ ...activeEditProductAsset, boundingBox: workflowOptions.boundingBox });
          }}
        ></AnimatedButton>
      </div>
    </div>
  );
};
