import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { AlertBox } from 'src/components/common/alerts/alertBox';
import { ErrorText } from 'src/components/common/alerts/errorText';
import { EditorContext } from 'src/components/editor/EditorContext';
import { positions } from 'src/components/editor/legacy/Editor';
import { AlertTargets, productImageControlName } from 'src/constants';
import styles from './workflowLandingPage.module.scss';
import { AppContext } from '../../../../AppContext';
import sample3 from '../../../images/productSamples/chair.png';
import sample4 from '../../../images/productSamples/laptop.png';
import sample5 from '../../../images/productSamples/moisture.png';
import sample6 from '../../../images/productSamples/tumbler.jpeg';

const images = [sample3, sample5, sample4, sample6];

type Props = {
  children?: string | ReactNode | ReactNode[];
};
export const WorkflowLandingPage = (props: Props) => {
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [fileUploadStatus, setFileUploadStatus] = useState<'in_progress' | 'done'>();
  const editorContext = useContext(EditorContext);
  const appContext = useContext(AppContext);
  const imageGridRef = useRef(null);
  const context = useContext(EditorContext);
  const sampleImages: string[] = images;

  const imageOnClick = async (index: number) => {
    setSelectedIndex(index);
    setFileUploadStatus('in_progress');
    const response = await fetch(images[index]);
    const blob = await response.blob();
    const file = new File([blob], crypto.randomUUID(), { type: 'image/png' });
    editorContext.setFileUploadEvent({ payload: file, controlName: 'product_image' });
  };

  useEffect(() => {
    const option = context.workflowOptions[productImageControlName];
    if (!option?.value && fileUploadStatus !== 'in_progress') {
      setSelectedIndex(undefined);
    } else if (option?.value) {
      setFileUploadStatus('done');
    }
  }, [context.workflowOptions]);

  return (
    <>
      <Container maxWidth={false} className={`${styles.wrapper} page-margin`} style={{ padding: 0, width: 'auto' }}>
        {/*
        <Box sx={{ mx: { md: '80px', xs: '35px' } }}>

          <ImageList variant="masonry" sx={{ width: '100%', height: 500 }} cols={3} rowHeight={450} gap={8}>
            {sampleImages.map((item, index) => (
              <ImageListItem key={item}>
                <img
                  srcSet={item}
                  src={item}
                  alt={item}
                  loading="lazy"
                  style={{ cursor: 'pointer', borderRadius: '16px', border: 1, borderStyle: 'solid', borderColor: '#E6E9ED' }}
                  onClick={() => imageOnClick(index)}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
        */}
        <Stack height={'100%'} direction={'column'} gap={1}>
          {appContext.alertEvent?.target === AlertTargets.workflowLandingPage && (
            <AlertBox alertEvent={appContext.alertEvent} onClose={() => appContext.setAlertEvent(undefined)} />
          )}
          <Box className={styles.header}>
            <Typography component="h2" variant="h2" color="inherit" gutterBottom>
              Generate images
            </Typography>
            <Typography paddingBottom={'30px'} marginBottom={0} fontWeight={300} variant="body1" color="inherit" gutterBottom>
              Choose or upload a product image and describe the image you want to generate
            </Typography>
          </Box>

          <Box ref={imageGridRef} className={styles.imageGrid} mt={1}>
            <Box className={styles.imageInnerGrid}>
              {sampleImages.map((image, index) => (
                <Box key={index} className={styles.imageGridItem}>
                  <div className={styles.overlay}>
                    <Button onClick={() => imageOnClick(index)}>Select product image</Button>
                  </div>
                  <div className={`${styles.selectPlaceholder} ${selectedIndex === index ? styles.selected : ''}`}></div>
                  <img src={image} />
                </Box>
              ))}
            </Box>
          </Box>
          <Box style={{ height: '250px', flexShrink: 0 }}></Box>
          <Box className={styles.controls} sx={{ mx: { md: '80px', xs: '35px' } }}>
            {props.children}
            {appContext.alertEvent?.control?.position === positions.canvasBottom && (
              <Box className={styles.validationError} display={'flex'} paddingTop={'10px'}>
                <ErrorText {...appContext.alertEvent} />
              </Box>
            )}
          </Box>
        </Stack>
      </Container>
    </>
  );
};
