import { Button } from '@amzn/storm-ui';
import { useContext, useState } from 'react';
import { ImageModalContext } from 'src/components/imageModal/ImageModalContext';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import useAssetLibrary from 'src/hooks/useAssetLibrary';
import styles from './styles.module.scss';
import { AppContext } from '../../../AppContext';

export const SAVE_IMAGE_TO_ACCOUNT_BUTTON_TEST_ID = 'save-image-to-account';

export interface SaveImageToAccountButtonProps {
  assetUrl: string;
  disabled?: boolean;
}

export const SaveImageToAccountButton = (props: SaveImageToAccountButtonProps) => {
  const { assetUrl } = props;
  const appContext = useContext(AppContext);
  const { imageReferenceId, studioInputSettings } = useContext(ImageModalContext);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const useAssetLibraryHook = useAssetLibrary();
  const client = useAIBackendHubClient();

  const saveImage = async () => {
    if (!isSaving && assetUrl) {
      setIsSaving(true);
      const response = await client.retrieveAsset({
        id: imageReferenceId,
        entityId: appContext?.selectedAdvertisingAccount?.alternateIds?.[0],
      });
      const url = response?.body.asset?.uri || '';
      await useAssetLibraryHook.saveAsset({ assetUrl: url, asin: studioInputSettings?.asin?.asin });
      setIsSaving(false);
    }
  };

  return (
    <>
      <Button
        primary={true}
        loading={isSaving}
        loadingLabel={'Saving'}
        disabled={props.disabled || appContext.accountType != 'external'}
        className={!isSaving && styles.saveButtons}
        onClick={saveImage}
        data-testid={SAVE_IMAGE_TO_ACCOUNT_BUTTON_TEST_ID}
      >
        Save to creative assets
      </Button>
    </>
  );
};
