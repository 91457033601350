import { ContentItem } from '@amzn/red-carpet-service-client';
import { Icon, Text, TextButton } from '@amzn/storm-ui';
import { times } from '@amzn/storm-ui-icons';
import styled from 'styled-components';
import { CHAT_THEME } from '../../theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${CHAT_THEME.spacing.microPlus};
  margin: ${CHAT_THEME.spacing.mediumPlus};
  padding-inline: 10px;
  padding-block: 8px;

  color: ${(props) => props.theme.palette.gray[700]};

  background-color: ${(props) => props.theme.palette.gray[25]};

  border-radius: 13px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(Text)`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`;

const Content = styled(Text)`
  font-size: 13px;
  line-height: 19.5px;
  text-align: left;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const imageHeight = 100;

const ImageContent = styled.img`
  width: ${imageHeight * 1.91}px;
  height: ${imageHeight}px;
  padding-top: 15px;
`;

const IconButton = styled(TextButton)`
  color: ${({ theme }) => theme.palette.gray[900]};
`;

interface Props {
  contentItem: ContentItem;
  clearQuoteMessage: () => void;
}
export default function MessageReply({ contentItem, clearQuoteMessage }: Props) {
  return (
    <Wrapper>
      <Header>
        <Title>replying to</Title>
        <IconButton onClick={clearQuoteMessage}>
          <Icon type={times} blockSize={false} size="lg" />
        </IconButton>
      </Header>

      {contentItem.text?.content && <Content>{contentItem.text?.content}</Content>}
      {contentItem.image?.source && <ImageContent src={contentItem.image?.source} />}
    </Wrapper>
  );
}
