import { Skeleton } from '@mui/material';
import React from 'react';
import ProgressIndicator from '../../../icons/progressIndicator.svg';

const wrapperStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'relative',
};

export const ImagePlaceholder = (props: { animate?: boolean; showMessage: boolean }) => {
  const messageContainerStyle: React.CSSProperties = {
    fontSize: '0.8rem',
    display: 'flex',
    position: 'absolute',
    top: '0',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    animation: props.animate ? 'animation-c7515d 2s ease-in-out 0.5s infinite' : 'none',
  };

  return (
    <div style={wrapperStyle}>
      <Skeleton
        height={'100%'}
        variant="rectangular"
        style={{
          background: 'rgba(0, 0, 0, 0.21)',
        }}
        animation={props.animate && 'pulse'}
      />
      {props.animate && (
        <div style={messageContainerStyle}>
          <ProgressIndicator />
          <span style={{ padding: '10px', fontSize: '1rem', fontWeight: 'bolder' }}>Generating</span>
        </div>
      )}
      {props.showMessage && (
        <div style={messageContainerStyle}>
          <span style={{ width: '50%' }}>Sorry, we're not able to generate more images at this time.</span>
        </div>
      )}
    </div>
  );
};
