import { TextAreaFormGroup } from '@amzn/storm-ui';
import { useContext, useEffect, useState } from 'react';
import { EditorContextP1, EditorContextP1 as EditorContext } from 'src/components/editor/EditorContext';
import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';

const BrandStyleTextPromptBox = ({ control }: any) => {
  const editorContext = useContext(EditorContext);
  const controlValues = editorContext.workflowOptions;
  const [text, setText] = useState('');

  const editorContextP1 = useContext(EditorContextP1);
  const [opacity, setOpacity] = useState<number>(1);

  useEffect(() => {
    if (editorContextP1.disableControl) {
      setOpacity(0.5);
    } else setOpacity(1);
  }, [editorContextP1.disableControl]);

  const handleChange = (e: any) => {
    setText(e.target.value);
    const controlName = control.controlName;
    if (e.target.value === '') {
      const controlValuesCopy = { ...controlValues };
      delete controlValuesCopy[controlName];
      editorContext.setWorkFlowOptions({ ...controlValuesCopy });
    } else {
      const controlData: any = {};
      controlData[controlName] = { value: e.target.value };
      editorContext.setWorkFlowOptions({ ...controlValues, ...controlData });
    }
  };

  return (
    <>
      <ControlLabel title={control.controlLabel} />
      <TextAreaFormGroup
        fullWidth
        value={text}
        onChange={handleChange}
        id="textarea-basic"
        label=""
        placeholder="Describe the changes you'd like to make to your image."
        style={{ background: '#F2F4F6', opacity: opacity }}
        disabled={editorContextP1.disableControl}
      />
    </>
  );
};

export default BrandStyleTextPromptBox;
