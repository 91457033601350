import { Asset } from '@amzn/genaihub-typescript-client';
import { useContext } from 'react';
import { UPLOAD_PLACEHOLDER_TOTAL_ANIMATION_TIME } from 'src/components/pages/studio/contentTile/ContentTile';
import { convertFeed } from 'src/components/pages/studio/contentTile/convertFeed';
import { ContentItem, StudioContext } from 'src/components/pages/studio/StudioContext';
import {
  ImportImageFilteredSelectionFailureNotification,
  ImportImageFilteredSelectionPartialFailureNotification,
  ImportImageImportFailureNotification,
  ImportImageSelectionFailureNotification,
  ImportImageSelectionPartialFailureNotification,
} from 'src/components/snackbar/notifications/ImportImageNotifications';
import { SnackbarContext } from 'src/components/snackbar/SnackbarContext';
import { getAspectRatioFromFile } from 'src/components/utils/ImageUtils';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { ImageToImport, ImportImageContext } from 'src/v2/contexts/ImportImageContext';
import { v6 as uuidV6 } from 'uuid';

export const UNAVAILABLE_MESSAGE = 'unavailable';

export const useImportImage = ({ enableBringYourOwnImage }: { enableBringYourOwnImage: boolean }) => {
  if (!enableBringYourOwnImage) {
    return {
      handleImportFiles: () => Promise.reject(UNAVAILABLE_MESSAGE),
    };
  }

  const importImageContext = useContext(ImportImageContext);
  const studioContext = useContext(StudioContext);
  const snackbarContext = useContext(SnackbarContext);
  const backendClient = useAIBackendHubClient();

  const handleImageImported = async ({ imageToImport, asset }: { imageToImport: ImageToImport; asset: Asset }) => {
    studioContext.removePlaceholders([imageToImport.placeholderId]);
    const contentItems = await convertFeed(backendClient, [asset]);
    studioContext.prependResults(contentItems);
  };

  const handleImageImportError = ({ imageToImport }: { imageToImport: ImageToImport }) => {
    studioContext.transitionOutPlaceholders([imageToImport.placeholderId]);
    snackbarContext.addFailureNotification({ SnackbarContent: ImportImageImportFailureNotification });
    setTimeout(() => {
      studioContext.removePlaceholders([imageToImport.placeholderId]);
    }, UPLOAD_PLACEHOLDER_TOTAL_ANIMATION_TIME);
  };

  const handleImportFiles = async ({ files, errored, filtered }: { files: File[]; errored?: boolean; filtered?: boolean }) => {
    // Get aspect ratios of imported files
    const aspectRatioResults = await Promise.allSettled(files.map((file) => getAspectRatioFromFile({ file })));

    // Create placeholders for each result
    const placeholders: ContentItem[] = [];
    const imagesToImport: ImageToImport[] = [];
    aspectRatioResults.forEach((result) => {
      if (result.status === 'fulfilled') {
        const placeholderId = uuidV6();
        placeholders.push({
          loading: true,
          aspectRatio: result.value.aspectRatio,
          placeholderId,
          placeholderUrl: result.value.url,
        });

        imagesToImport.push({
          aspectRatio: result.value.aspectRatio,
          file: result.value.file,
          placeholderId,
          onImageImported: handleImageImported,
          onError: handleImageImportError,
        });
      } else {
        console.error(result.reason);
        errored = true;
      }
    });

    // Report Error/Filtering to User
    if (imagesToImport.length) {
      if (filtered) {
        snackbarContext.addFailureNotification({ SnackbarContent: ImportImageFilteredSelectionPartialFailureNotification });
      } else if (errored) {
        snackbarContext.addFailureNotification({ SnackbarContent: ImportImageSelectionPartialFailureNotification });
      }
    } else {
      if (filtered) {
        snackbarContext.addFailureNotification({ SnackbarContent: ImportImageFilteredSelectionFailureNotification });
      } else if (errored) {
        snackbarContext.addFailureNotification({ SnackbarContent: ImportImageSelectionFailureNotification });
      }
    }

    // Import images
    if (imagesToImport.length) {
      studioContext.addUploadPlaceholders(placeholders);
      importImageContext.importImages({ imagesToImport });
    }
  };

  return {
    handleImportFiles,
  };
};
