import { Conversation } from '@amzn/red-carpet-service-client';
import { useQuery } from '@tanstack/react-query';
import { redCarpetClient } from 'src/components/assistant/client';
import { queryClient } from 'src/components/QueryClient';

export const QUERY_KEY = ['conversations'];

export function useConversationsQuery(alias?: string) {
  return useQuery({
    queryKey: QUERY_KEY,
    queryFn: () => redCarpetClient.listConversations(alias),
  });
}

export function updateConversation(newConversationMetadata: Conversation) {
  queryClient.setQueryData<Conversation[]>(QUERY_KEY, (conversations) => {
    if (conversations?.find((item) => item.conversationId === newConversationMetadata.conversationId)) {
      return conversations?.map((conversation) => {
        if (conversation.conversationId === newConversationMetadata.conversationId) {
          return newConversationMetadata;
        }
        return conversation;
      });
    }
    return conversations ? [newConversationMetadata, ...conversations] : [newConversationMetadata];
  });
}

export function clearSuggestions(conversationId: string) {
  queryClient.setQueryData<Conversation[]>(QUERY_KEY, (conversations) => {
    if (conversations?.find((item) => item.conversationId === conversationId)) {
      return conversations?.map((conversation) => {
        if (conversation.conversationId === conversationId) {
          return { ...conversation, suggestions: [] };
        }
        return conversation;
      });
    }
    return conversations;
  });
}
