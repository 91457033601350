import type Genaihubbackend from '@amzn/genaihub-typescript-client';
import { Category } from '@amzn/genaihub-typescript-client';
import { Sha256 } from '@aws-crypto/sha256-js';
import axios, { AxiosProgressEvent } from 'axios';
import { getImageFromUrl, ImageType } from 'src/components/utils/base64Encode';

const toHexString = (byteArray: Uint8Array) => {
  return Array.from(byteArray, function (byte) {
    return ('0' + (byte & 0xff).toString(16)).slice(-2);
  }).join('');
};

interface UploadImageProps {
  file: File;
  backendClient: Genaihubbackend;
  contentCategory: Category;
  /**
   * Progress callback for the upload
   * @param value - the ratio of uploaded bytes / total bytes
   * @returns
   */
  progressCallback?: (value: number) => void;
}

export const uploadImage = async ({ file, backendClient, contentCategory, progressCallback }: UploadImageProps) => {
  const base64 = (
    await getImageFromUrl({
      url: URL.createObjectURL(file),
      type: file.type as ImageType,
    })
  ).base64Encode();
  const hash = new Sha256();
  hash.update(base64);
  const uint8Array = await hash.digest();
  const imageHash = toHexString(uint8Array);
  const uploadResponse = await backendClient.fileUploadGetPresign({
    body: {
      contentSize: file?.size,
      contentType: file?.type,
      contentCategory: contentCategory,
      contentHash: imageHash,
    },
  });

  if (uploadResponse.body.url) {
    const headers = {
      'Content-Type': file.type,
      'Content-Length': file.size.toString(),
    };

    if (progressCallback) {
      await axios.put(uploadResponse.body.url, file, {
        headers,
        onUploadProgress: (event: AxiosProgressEvent) => {
          if (event.total) {
            progressCallback(event.loaded / event.total);
          }
        },
      });
    } else {
      await fetch(uploadResponse.body.url, {
        method: 'PUT',
        headers,
        body: file,
      });
    }
  }

  return uploadResponse.body.referenceId;
};
