import { Button, Icon, Popper, Text } from '@amzn/storm-ui';
import { circleUser } from '@amzn/storm-ui-icons';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SignInButton from 'src/components/common/SignInButton';
import { useContainerDimensions } from 'src/components/utils/ContainerDimensions';
import { SIGN_OUT_PATH } from 'src/constants';
import Stack from 'src/customUIComponents/Stack';
import { clearSelectedAdvertingAccountFromLocalStorage } from 'src/helpers';
import adsLogoX2 from 'src/images/Amazon_Ads_Horizontal_SquidInk_RGB.png';
import Bars from 'src/images/icons/bars.svg';
import Close from 'src/images/icons/close.svg';
import { isCreativeAgentLaunched } from 'src/util/weblab/weblab';
import style from './Header.module.scss';
import { AppContext } from '../../../../AppContext';
import Logo from '../../../icons/CreativeStudioLogo.svg';

const Header = (props: { style?: React.CSSProperties }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(AppContext);

  const [currentPage, setCurrentPage] = useState<string>(location.pathname);
  const pageHeaderRef = useRef(null);
  const { width, height: pageHeaderHeight } = useContainerDimensions(pageHeaderRef);
  const navHeaderRef = useRef(null);
  const { height: navHeaderHeight } = useContainerDimensions(navHeaderRef);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const onMenuItemClick = useCallback(() => {
    setIsOpen(false);
    context.setShowSwitchAccountModal(true);
  }, [setIsOpen]);

  const toggleMenu = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  useLayoutEffect(() => {
    context.setHeaderDimensions({ width, pageHeaderHeight, navHeaderHeight });
  }, [width, pageHeaderHeight, navHeaderHeight]);

  const handleClick = (path: string) => {
    if (path === SIGN_OUT_PATH) {
      clearSelectedAdvertingAccountFromLocalStorage();
      localStorage.removeItem('userDetails');
      window.location.replace(path);
      return;
    }
    navigate(path);
    setIsMobileNavOpen(false);
  };

  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [context.activePage]);

  useEffect(() => {
    document.body.style.overflowY = isMobileNavOpen ? 'hidden' : 'unset';
  }, [isMobileNavOpen]);

  const selectedClassName = (page: string) => {
    if (page === currentPage) return `${style.selected} test-selected`;
    return '';
  };

  return (
    <div ref={navHeaderRef} className={style.navigationBar} style={{ ...props.style, paddingBottom: '16px' }}>
      <div className={style.leftContent}>
        <div
          onClick={() => handleClick(context.authenticated ? '/' : '/home')}
          className={`${style.logoContainer} ${context.authenticated ? selectedClassName('/') : selectedClassName('/home')}`}
          data-testid="header-nav-link-home"
        >
          <Stack>
            <div className={style.logoWrapper}>
              <Logo />
              <span className={style.logoTag}>BETA</span>
            </div>
          </Stack>
        </div>
        {context.authenticated && (
          <div
            className={style.mobileNavOpenIcon}
            onClick={() => setIsMobileNavOpen((value) => !value)}
            data-testid="header-mobile-nav-toggle-button"
          >
            <Bars />
          </div>
        )}
      </div>
      {context.authenticated && (
        <div className={`${style.navLinks} ${isMobileNavOpen ? style.mobileNavOpen : ''}`} data-testid="header-nav-menu">
          <div onClick={() => setIsMobileNavOpen(false)} className={style.mobileNavCloseIcon} data-testid="header-mobile-nav-close-button">
            <Close />
          </div>
          <div
            className={`${style.navItem} ${selectedClassName('/studio')}`}
            onClick={() => handleClick('/studio')}
            data-testid="header-nav-link-studio"
          >
            <div>Studio</div>
          </div>
          {isCreativeAgentLaunched() && (
            <div
              className={`${style.navItem} ${selectedClassName('/chat')}`}
              onClick={() => handleClick('/chat')}
              data-testid="header-nav-link-creative-agent"
            >
              <div>Creative Agent</div>
            </div>
          )}
          <div
            className={`${style.navItem} ${style.sandbox} ${selectedClassName('/playground')}`}
            onClick={() => handleClick('/playground')}
            data-testid="header-nav-link-sandbox"
          >
            <div>Sandbox</div>
          </div>
          <div
            className={`${style.navItem} ${selectedClassName('/browse')}`}
            onClick={() => handleClick('/browse')}
            data-testid="header-nav-link-gallery"
          >
            <div>Gallery</div>
          </div>
        </div>
      )}
      <div onClick={() => setIsMobileNavOpen(false)} className={style[isMobileNavOpen ? 'pageOverlay' : 'pageOverlayHidden']} />
      <div className={style.rightContent}>
        {context.authenticated ? (
          <>
            <img className={style.adsLogo} src={adsLogoX2} />
            <Popper
              id="user-menu"
              className={style.menu}
              onClick={toggleMenu}
              isOpen={isOpen}
              trigger={
                <Button className={style.menu} style={{ background: 'transparent', boxShadow: 'none' }}>
                  {context.accountType === 'internal' && context?.userDetails?.alias ? (
                    <img
                      className={style.userImage}
                      src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${context?.userDetails?.alias}`}
                    />
                  ) : (
                    <Icon type={circleUser} style={{ fontSize: '25px' }} />
                  )}
                </Button>
              }
              align="end"
              position="bottom"
            >
              {context.accountType === 'internal' && context?.userDetails?.alias && (
                <>
                  <div>
                    <Text className={style.accHeading}>{context?.userDetails?.alias}</Text>
                  </div>
                  <Text className={style.accValue} style={{ color: '#495566' }}>
                    {`@${context?.userDetails?.alias}`}
                  </Text>
                </>
              )}

              {context.accountType === 'external' && (
                <div>
                  <div onClick={onMenuItemClick} style={{ cursor: 'pointer' }}>
                    <Text className={style.accValue} style={{ color: '#4305F4' }}>
                      {context.selectedAdvertisingAccount ? 'Switch accounts' : 'Choose an account'}
                    </Text>
                  </div>
                  {context.selectedAdvertisingAccount && (
                    <Stack
                      style={{
                        width: '100%',
                        background: '#F2F4F6',
                        borderRadius: '8px',
                        padding: '12px',
                        overflow: 'hidden',
                        gap: '0px',
                        marginTop: '12px',
                        marginBottom: '12px',
                        color: '#495566',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div>
                        <Text className={style.accHeading}>{context.selectedAdvertisingAccount?.accountName}</Text>
                      </div>
                      <div>
                        <Stack style={{ flexDirection: 'row', justifyContent: 'flex-start', gap: '2px' }}>
                          <Text className={style.accKey}>ID:</Text>
                          <Text className={style.accValue}>{context.selectedAdvertisingAccount?.adsAccountId}</Text>
                        </Stack>
                      </div>
                    </Stack>
                  )}
                </div>
              )}
              {context.accountType === 'external' && (
                <div onClick={() => handleClick(SIGN_OUT_PATH)} style={{ cursor: 'pointer' }}>
                  <Text className={style.accValue} style={{ color: '#4305F4' }}>
                    Sign out
                  </Text>
                </div>
              )}
            </Popper>
          </>
        ) : (
          <>
            <a className={style.link} href="https://advertising.amazon.com">
              <Text className={style.linkText}>Learn about Amazon Ads!</Text>
            </a>
            <SignInButton className={style.signIn} />
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
