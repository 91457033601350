const primaryColours = { v1: '#232F3F', v2: '#5E65FF', hover: '#5E65FF', disabled: '', border: '#4305F4' };
const secondaryColours = { v1: '#4305F4', v2: '#F2F4F6', hover: '#E6E9ED', disabled: '', border: '#4305F4', gray900: '#354152' };

const aiLabTheme = {
  title: { primary: primaryColours.v1, secondary: secondaryColours.v1 },
  subTitle: { primary: primaryColours.v1, secondary: secondaryColours.v1 },
  buttonText: { primary: primaryColours.v1, secondary: secondaryColours.v1, secondaryV2: secondaryColours.gray900 },
  buttonBackground: {},
  buttonBorder: { primary: primaryColours.border, secondary: secondaryColours.border, secondaryV2: secondaryColours.gray900 },
};

export default aiLabTheme;
