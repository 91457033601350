import { Asset, InspirationalContent } from '@amzn/genaihub-typescript-client';

export default function isInspirationaContent(asset: Asset): asset is InspirationalContent {
  const content: InspirationalContent = asset as InspirationalContent;
  return (
    content.textPrompt !== undefined ||
    content.resolution !== undefined ||
    content.productCategory !== undefined ||
    content.creativeFeature !== undefined
  );
}
