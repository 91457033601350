import { Feedback } from '@amzn/genaihub-typescript-client';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, ImageList, ImageListItem, Popover, TextField } from '@mui/material';
import { useState } from 'react';
import styles from 'src/components/editor/UiContols/uiGeneratorControls/mui/Submit.module.scss';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';

interface FeedbackPopoverProps {
  src: string;
  openResultViewer: boolean;
  setOpenResultViewer: any;
  feedback: Feedback;
  setFeedbackSent: any;
  feedbackSentiment: any;
}

const FeedbackPopover = (props: FeedbackPopoverProps) => {
  const { openResultViewer, setOpenResultViewer, src, feedback } = props;

  const genAIBackendClient = useAIBackendHubClient();

  const [feedbackMessage, setFeedbackMessage] = useState<string>('');
  const sendFeedback = () => {
    const assetUrl = new URL(props.src);
    genAIBackendClient.submitFeedbackSentiment({
      body: {
        feedback: feedback,
        workflowId: props.feedbackSentiment?.workflowId || '',
        assetUri: assetUrl.origin + assetUrl.pathname,
        contentCreationTime: props.feedbackSentiment.contentCreationTime,
        textFeedback: feedbackMessage,
        prompt: props.feedbackSentiment.textPrompt,
        scope: 'ASSET',
      },
    });
    props.setFeedbackSent(props.src);
    setOpenResultViewer(false);
  };

  const onChangeHandler = (event: any) => {
    setFeedbackMessage(event.target.value);
  };

  const singleImage = (src: string) => (
    <>
      <ImageList gap={0} cols={1}>
        <ImageListItem rows={100}>
          <img
            srcSet={src}
            src={src}
            alt={src}
            style={{ objectFit: 'contain', maxHeight: '279px', maxWidth: '376px', marginLeft: '18px', marginRight: '18px' }}
            loading="lazy"
          />
        </ImageListItem>
      </ImageList>
      <div style={{ marginLeft: '18px', marginRight: '18px', fontSize: '13px' }}>
        <span style={{ float: 'left' }}>
          {props.feedback === 'LIKE' ? <strong>Tell us why you like this image</strong> : <strong>Tell us why you don't like this image</strong>}
        </span>
        <span style={{ color: '#5C687C', float: 'right' }}>{2000 - feedbackMessage.length} characters left</span>
      </div>
      <br />
      <TextField
        id="filled-multiline-static"
        multiline
        rows={6}
        placeholder="Please don't include personal information"
        onChange={onChangeHandler}
        inputProps={{ maxLength: 2000 }}
        // variant="filled"
        style={{
          marginLeft: '18px',
          marginRight: '18px',
          color: '#afafaf',
          fontSize: '13px',
          borderStyle: 'solid',
          borderWidth: '1px',
          marginTop: '10px',
          marginBottom: '20px',
          borderRadius: '2px',
          borderColor: '#E6E9ED',
          width: '373px',
          backgroundColor: 'transparent',
        }}
      />
      <div
        style={{ borderTop: 'solid', borderColor: '#E1E1E1', borderWidth: '1px', padding: '10px 1px', display: 'flex', justifyContent: 'flex-end' }}
      >
        <button
          className={styles.secondaryButton}
          onClick={() => setOpenResultViewer(false)}
          style={{
            float: 'right',
            width: '72.55px',
            height: '31.61px',
            fontSize: '13.26px',
            marginTop: '5px',
            color: 'black',
            border: 'white',
            padding: '4px 8px',
            marginRight: '10px',
          }}
        >
          Cancel
        </button>
        <button
          className={styles.generateButton}
          onClick={() => sendFeedback()}
          style={{
            width: '72.55px',
            height: '31.61px',
            fontSize: '13.26px',
            marginTop: '5px',
            marginRight: '18px',
            padding: '4px 8px',
            backgroundColor: '#5E65FF',
            background: '#5E65FF',
          }}
        >
          Submit
        </button>
      </div>
    </>
  );
  const clickHandler = () => {
    setFeedbackMessage('');
    setOpenResultViewer(false);
  };

  const DisplayView = singleImage(src);

  return (
    <Popover
      open={openResultViewer}
      onClose={clickHandler}
      anchorReference={'none'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255, 255, 255, 0.8)',
      }}
    >
      <div style={{ width: '410px', height: '40.47px', borderBottom: 'solid', borderColor: '#E1E1E1', borderWidth: '1px' }}>
        <span style={{ marginLeft: '18px', marginRight: '18px', marginTop: '8px', position: 'absolute' }}>
          {props.feedback === 'LIKE' ? <strong>Thumbs Up!</strong> : <strong>Thumbs Down!</strong>}
        </span>
        <IconButton style={{ float: 'right', marginRight: '10px', cursor: 'pointer' }} onClick={clickHandler}>
          <CloseIcon />
        </IconButton>

        {/*<svg data-testid="CloseIcon"></svg>*/}
      </div>
      <div style={{ width: '410px' }}>{DisplayView}</div>
    </Popover>
  );
};
export default FeedbackPopover;
