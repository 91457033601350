import Genaihubbackend from '@amzn/genaihub-typescript-client';
import { OperationExecutorBuilder } from 'src/api/operationExecutor';
import { MetricsProvider } from 'src/metrics';

export class GenAIHubClientProvider {
  private static instance: GenAIHubClientProvider;
  private readonly genAiHubBackendClientInstance: Genaihubbackend;
  private readonly metrics: MetricsProvider;

  private constructor(metrics: MetricsProvider) {
    this.metrics = metrics;
    this.genAiHubBackendClientInstance = new Genaihubbackend(new OperationExecutorBuilder().build(metrics));
  }

  public static getInstance(metrics: MetricsProvider): GenAIHubClientProvider {
    if (!GenAIHubClientProvider.instance || GenAIHubClientProvider.instance.metrics !== metrics) {
      GenAIHubClientProvider.instance = new GenAIHubClientProvider(metrics);
    }
    return GenAIHubClientProvider.instance;
  }
  public getGenAIHubBackendClient(): Genaihubbackend {
    return this.genAiHubBackendClientInstance;
  }
}
