import { useContext, useEffect, useState } from 'react';
import { EditorContext } from 'src/components/editor/EditorContext';
import styles from './Submit.module.scss';

const Submit = (props: any) => {
  const { submit } = props;
  const context = useContext(EditorContext);
  const [disableSubmit, setDisableSubmit] = useState(true);

  useEffect(() => {
    setDisableSubmit(!validate());
  }, [context.workflowOptions, context.jobInProgress]);

  const validate = () => {
    const { aspect_ratio, text_prompt, num_of_images, product_image } = context.workflowOptions;
    return aspect_ratio.value && num_of_images.value && text_prompt.value && product_image.value && !context.jobInProgress;
  };

  return (
    <button className={styles.generateButton} disabled={disableSubmit} onClick={submit}>
      Generate
    </button>
  );
};

export default Submit;
