import { Feedback as FeedbackModel } from '@amzn/genaihub-typescript-client';
import { Message, MessageRole } from '@amzn/red-carpet-service-client';
import { Icon, Text, TextButton } from '@amzn/storm-ui';
import { IconDefinition, solidThumbsDown, solidThumbsUp, thumbsDown, thumbsUp } from '@amzn/storm-ui-icons';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import Feedback from 'src/components/assistant/components/Feedback';
import { FEEDBACK_BODY, FEEDBACK_TITLE } from 'src/components/assistant/components/Feedback/constants';
import { HeaderConfig } from 'src/components/assistant/model';
import useFeedback from 'src/components/assistant/queries/useFeedback';
import styled from 'styled-components';

const AssistantName = styled(Text)<{ $color?: string }>`
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.36px;
  text-transform: uppercase;
  color: ${(props) => props.$color};
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const IconButtonsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.mini};

  > :first-child {
    margin-right: ${({ theme }) => theme.spacing.small};
  }
`;

const IconButton = styled(TextButton)`
  color: ${({ theme }) => theme.palette.gray[700]};
`;

interface HeaderProps {
  message: Message;
  role?: MessageRole;
  headerConfig?: HeaderConfig;
  onReply: () => void;
}

export default function Header({ headerConfig, role, onReply, message }: HeaderProps) {
  const { feedback, setFeedback } = useFeedback(message, 0);

  const onSubmit = (feedback: FeedbackModel) => setFeedback(feedback);

  return (
    <HeaderWrapper>
      {headerConfig?.name && (
        <AssistantName fontSize="small" type="span" $color={headerConfig?.nameColor}>
          {headerConfig.name}
        </AssistantName>
      )}
      {role == MessageRole.ASSISTANT && headerConfig?.hasActions && (
        <IconButtonsWrapper>
          <IconButton onClick={onReply}>
            <Icon type={faReply as IconDefinition} blockSize={false} size="lg" />
          </IconButton>
          <Feedback
            icon={feedback == 'LIKE' ? solidThumbsUp : thumbsUp}
            title={FEEDBACK_TITLE}
            body={FEEDBACK_BODY}
            feedback={'LIKE'}
            message={message}
            onSubmit={onSubmit}
          />
          <Feedback
            icon={feedback == 'DISLIKE' ? solidThumbsDown : thumbsDown}
            title={FEEDBACK_TITLE}
            body={FEEDBACK_BODY}
            feedback={'DISLIKE'}
            message={message}
            onSubmit={onSubmit}
          />
        </IconButtonsWrapper>
      )}
    </HeaderWrapper>
  );
}
