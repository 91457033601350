import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styles from './buttonWithBackground.module.scss';
import background from '../../../../../images/Touch link_.png';

const ButtonWithBackground = ({ onClick, children }: any) => {
  return (
    <button
      onClick={onClick}
      className={styles.buttonWithBackground}
      style={{
        background: `url(${background})`,
      }}
    >
      <span>{children}</span>
      <ChevronRightIcon />
    </button>
  );
};

export default ButtonWithBackground;
