import { Feedback } from '@amzn/genaihub-typescript-client';
import { Fragment } from 'react';
import ArrowExpandIcon from 'src/icons/arrowExpand.svg';
import Download from 'src/icons/download.svg';
import DownRating from 'src/icons/downRating.svg';
import UpRating from 'src/icons/upRating.svg';
import styles from './generationOverlay.module.scss';

export enum OverlayVisibility {
  always = 1,
  onHover = 2,
}

export const GenerationOverlay = (props: {
  imageSrc: string;
  className?: string;
  onSelected: (imageSrc: string, feedback: Feedback) => void;
  visibility?: OverlayVisibility;
  disableMagnifier?: boolean;
  disableSentiment?: boolean;
  onZoom?: (imageSrc: string) => void;
}) => {
  const handleDownloadClick = async () => {
    const link = document.createElement('a');
    link.download = props.imageSrc;
    link.href = props.imageSrc;
    link.click();
  };

  const handleThumbsUpClick = async () => {
    props.onSelected(props.imageSrc, 'LIKE');
  };

  const handleThumbsDownClick = async () => {
    props.onSelected(props.imageSrc, 'DISLIKE');
  };

  const handleEditImageClick = () => {
    typeof props?.onZoom === 'function' && props.onZoom(props.imageSrc);
  };

  return (
    <>
      <div className={`${styles.overlay} ${props.className}`}>
        <div className={props.visibility == OverlayVisibility.always ? styles.visibleActions : styles.actions}>
          {props.visibility != OverlayVisibility.always && (
            <Fragment>
              <div className={styles.shadow}></div>
              <div className={styles.editImage} onClick={handleEditImageClick}>
                <ArrowExpandIcon />
              </div>
            </Fragment>
          )}
          <span className={styles.downloadIcon} onClick={handleDownloadClick}>
            <Download />
            <span>Download</span>
          </span>
          {!props.disableSentiment ? (
            <span className={styles.feedback}>
              <span onClick={handleThumbsUpClick}>
                <UpRating />
              </span>
              <div className={styles.vl}></div>
              <span onClick={handleThumbsDownClick}>
                <DownRating />
              </span>
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
