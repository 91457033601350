import { Text } from '@amzn/storm-ui';
import { CSSProperties, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonWithBorder from 'src/components/common/storm/ButtonWithBorder';
import Title from 'src/components/common/storm/Title';
import { Metrics, STUDIO } from 'src/constants';
import Carousel from 'src/customUIComponents/Carousel';
import Stack from 'src/customUIComponents/Stack';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { isCreativeAgentLaunched } from 'src/util/weblab/weblab';
import componentStyles from './Playground.module.scss';
import PlaygroundCard from './PlaygroundCard';
import { AppContext } from '../../../../AppContext';
import './playgroundStyles.css';

import generativeResizingV2 from '../../../images/tools/generativeResizingV2.png';
import imageEditV2 from '../../../images/tools/imageEditV2.png';
import lifestyleV2 from '../../../images/tools/lifestyleV2.png';
import liveImageV3 from '../../../images/tools/liveImageV3.png';
import restyleImageV2 from '../../../images/tools/restyleImageV2.png';
import { getPageName } from '../../utils/getPageName';

const UNAUTHENTICATED_CARDS = [
  {
    description:
      'Add subtle motion in a few easy steps. | To animate your creative asset, upload an image, select the area you want to animate, and choose your camera settings.',
    order: 1,
    spotlight: 'NEW',
    thumbnail: liveImageV3,
    title: 'Live images',
    type: 'PARALLAX_MOTION',
    videoPreview: '',
  },
  {
    description:
      'Update creative assets with new locations, studio backgrounds, and holiday themes. | Create themed variations of your creative content. Just upload an image and choose a style—it’s that easy.',
    order: 2,
    spotlight: 'NEW',
    thumbnail: restyleImageV2,
    title: 'Restyle images',
    type: 'IMAGE_THEMING',
    videoPreview: '',
  },
  {
    description:
      'Create horizontal, vertical, and square versions by extending the background. | To get started, upload an image and choose an aspect ratio.',
    order: 3,
    spotlight: 'NEW',
    thumbnail: generativeResizingV2,
    title: 'Extend images',
    type: 'GENERATIVE_RESIZING',
    videoPreview: '',
  },
  {
    description: 'Add, remove, or replace objects. | To get started, upload an image then select the area you want to update.',
    order: 4,
    spotlight: 'NEW',
    thumbnail: imageEditV2,
    title: 'Edit images',
    type: 'IMAGE_EDITOR',
    videoPreview: '',
  },
  {
    description:
      'Generate compelling lifestyle imagery inspired by your product. | To create lifestyle images inspired by your product, enter an ASIN and describe the type of image you want.',
    order: 5,
    spotlight: 'NEW',
    thumbnail: lifestyleV2,
    title: 'Create lifestyle images',
    type: 'LIFESTYLE_IMAGERY',
    videoPreview: '',
  },
];

interface PlaygroundProps {
  cards: number;
  showButton: boolean;
  wrap: boolean;
  width: string;
  cardStyle: CSSProperties;
  navigate: string;
}

const Playground = (props: PlaygroundProps) => {
  const { cards, showButton, wrap, width, cardStyle, navigate } = props;
  const { activePage, authenticated, metrics, tools, setTools, setSelectedTool } = useContext(AppContext);
  const genAIBackendClient = useAIBackendHubClient();
  const defaultWidth = '1410px';
  const title = 'Preview our latest AI tools';
  const linkTo = useNavigate();

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    if (authenticated) {
      getToolsList();
    }
  }, [authenticated]);

  const getToolsList = async () => {
    try {
      const response = await genAIBackendClient.retrieveToolsList({});
      const result = response.body;
      setTools(result.cards || []);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickEvent = (page: string) => {
    metrics.trackMetrics(
      Metrics.Methods.HomePage,
      { [Metrics.Names.ButtonId]: Metrics.Buttons.ViewAll, [Metrics.Names.Destination]: getPageName(page) },
      { [Metrics.Counters.Clicks]: 1 },
    );
    linkTo(page);
  };

  const wrapContent = wrap === undefined ? true : wrap ? true : false;

  const items = (authenticated ? tools : UNAUTHENTICATED_CARDS)
    .map((item) => ({ ...item }))
    .sort((a, b) => a.order - b.order)
    .filter((tool) => tool.type !== STUDIO && tool.spotlight !== 'hidden')
    .filter((tool) => {
      if (tool.type !== 'CONVERSATIONAL') {
        return true;
      } else {
        return isCreativeAgentLaunched();
      }
    })
    .slice(0, cards)
    .map((item: { type: string }, index: number) => {
      return (
        <PlaygroundCard
          data-testid="sandbox-card"
          {...item}
          cardStyle={{
            ...cardStyle,
            cursor: authenticated ? 'pointer' : 'default',
          }}
          callback={() => {
            if (!authenticated) {
              return;
            }
            setSelectedTool(item.type);
            window.localStorage.setItem('selectedTool', item.type);
            handleClickEvent(item.type === 'CONVERSATIONAL' ? '/chat' : `/${activePage}/editor`);
          }}
          key={'playground' + index}
        />
      );
    });

  const playgroundClass = `playgroundContainer ${wrapContent && 'playgroundContainerResponsive'}`;

  return (
    <Stack className={componentStyles.playground} style={{ flexDirection: 'column', alignContent: 'center' }}>
      {showButton && (
        <Stack
          style={{
            flexDirection: 'row',
            width: width || defaultWidth,
            alignSelf: 'flex-start',
            paddingRight: 'var(--div-spacing-default)',
            paddingLeft: 'var(--div-spacing-default)',
            maxWidth: '2008px',
            marginBottom: wrapContent ? 0 : 'var(--tools-description-container-margin-bottom)',
          }}
        >
          <Text
            className={componentStyles.title}
            style={{
              width: '100%',
              paddingBottom: 'var(--tools-title-padding-bottom)',
              fontFamily: 'AmazonEmberMono-Bold',
              letterSpacing: '3px',
            }}
            data-testid="sandbox-section-header-title"
          >
            SANDBOX
          </Text>
          <Title className={componentStyles.description} title={title} style={{ flex: 1 }} data-testid="sandbox-section-header-description" />

          {authenticated && (
            <ButtonWithBorder
              style={{ minWidth: '92px' }}
              text="Visit sandbox"
              clickHandler={() => handleClickEvent('./playground')}
              data-testid="sandbox-section-header-page-link"
            />
          )}
        </Stack>
      )}

      <Stack
        className={playgroundClass}
        data-testid="playground-container"
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '20px',
          overflow: wrapContent ? 'hidden' : 'overlay',
          alignSelf: 'flex-start',
          justifyContent: 'flex-start',
          flexWrap: wrapContent ? 'wrap' : 'nowrap',
          padding: wrapContent ? 'var(--div-spacing-default)' : '0',
          paddingTop: '0',
        }}
      >
        {!wrapContent ? <Carousel items={items} width="auto" itemWidth="368px" itemHeight="594px" navigate={navigate} /> : items}
      </Stack>
    </Stack>
  );
};

export default Playground;
