import { Button } from '@amzn/storm-ui';
import { useState } from 'react';

import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import Stack from 'src/customUIComponents/Stack';
import styles from './RadioGroup.module.scss';
interface RadioGroupOption {
  label: string;
  value: number;
}
export interface RadioGroupProps {
  title: string;
  options: RadioGroupOption[];
  onChange?: (value: number) => void;
  defaultValue?: number;
  disabled?: boolean;
}

const RadioGroup = (props: RadioGroupProps) => {
  const [selected, setSelected] = useState<number | undefined>(props.defaultValue);

  const handleClick = (value: number) => {
    setSelected(value);
    props.onChange && props.onChange(value);
  };

  const options = props.options.map((option: RadioGroupOption, index: number) => {
    return (
      <Button
        className={styles.button}
        onClick={() => handleClick(option.value)}
        key={'button' + index}
        style={{
          borderColor: option.value === selected ? '#6236FF' : '#f1f3f5',
        }}
        disabled={props.disabled}
        data-testid={'button' + index}
      >
        {option.label}
      </Button>
    );
  });

  return (
    <div className={styles.radioGroupWrapper}>
      <ControlLabel title={props.title} />
      <Stack className={styles.buttonGroup}>{options}</Stack>
    </div>
  );
};

export default RadioGroup;
