import { SubmitWorkflowByIdOutput } from '@amzn/genaihub-typescript-client';
import React, { createContext } from 'react';
import { FileUploadEventType } from 'src/components/common/types';

export type EditorContextStateP1 = {
  asinMetaData: any;
  setAsinMetaData: React.Dispatch<React.SetStateAction<any>>;
  workflowOptions: any;
  setWorkFlowOptions: React.Dispatch<React.SetStateAction<any>>;
  fileUploadEvent: FileUploadEventType | undefined;
  setFileUploadEvent: React.Dispatch<React.SetStateAction<FileUploadEventType | undefined>>;
  disableControl: any;
  setDisableControl: (disableControl: any) => void;
  promptUpdateEvent: string;
  setPromptUpdateEvent: React.Dispatch<React.SetStateAction<any>>;
  activateGuide: boolean;
  setActivateGuide: React.Dispatch<React.SetStateAction<any>>;
  rawJobResponse?: SubmitWorkflowByIdOutput;
  setRawJobResponse: (rawJobResponse?: SubmitWorkflowByIdOutput) => void;
};

export type EditorContextState = {
  theme?: string | null | undefined;
  workflowOptions: any;
  workflowConfig: any;
  setWorkflowConfig: (workflowConfig: any) => void;
  setWorkFlowOptions: React.Dispatch<React.SetStateAction<any>>;
  openFileUploadDialog: boolean;
  setOpenFileUploadDialog: (open: boolean) => void;
  childControls: any;
  setChildControls: (controls: any) => void;
  fileUploadEvent: FileUploadEventType | undefined;
  setFileUploadEvent: React.Dispatch<React.SetStateAction<FileUploadEventType | undefined>>;
  componentVisibility: any;
  setComponentVisibility: (componentVisibility: any) => void;
  jobInProgress: boolean;
  setJobInProgress: (jobInProgress: boolean) => void;
};
export const EditorContext = createContext({
  theme: null,
} as EditorContextState);

export const EditorContextP1 = createContext({} as EditorContextStateP1);
