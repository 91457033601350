import { BatchResult, PublishAssetInput } from '@amzn/genaihub-typescript-client';
import { Modal, TabbedNavigationItem } from '@amzn/storm-ui';
import _cloneDeep from 'lodash/cloneDeep';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from 'src/../AppContext';
import { TabbedNavigation } from 'src/components/common/storm/TabbedNavigation';
import { AssetType } from 'src/components/imageModal/components/utils';
import { ImageModalCloseConfirmPopup } from 'src/components/imageModal/ImageModalCloseConfirmPopup';
import { SaveImageToAccountButton } from 'src/components/imageModal/SaveImageToAccountButton';
import { SaveImageToAccountDropdown } from 'src/components/imageModal/SaveImageToAccountDropdown';
import { ContentItem, StudioContext, getValidFormatForStudioInputSettings } from 'src/components/pages/studio/StudioContext';
import {
  PublishAssetFailureNotification,
  PublishAssetProcessingNotification,
  PublishAssetSuccessNotification,
} from 'src/components/snackbar/notifications/PublishAssetNotifications';
import { SnackbarContext } from 'src/components/snackbar/SnackbarContext';
import { getAssetTypeFromWorkflowId } from 'src/components/utils/assetUtils';
import { getContentTypeByWorkflowId } from 'src/components/utils/getWorkflowNameById';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import Arrow from 'src/images/icons/arrow.svg';
import { isSponsoredCampaignLaunched } from 'src/util/weblab/weblab';
import { WorkflowId } from './components/utils';
import { DownloadImageButton } from './DownloadImageButton';
import { ImageModalContext } from './ImageModalContext';
import { EditModes, ImageModalEditTab } from './ImageModalEditTab';
import { ImageModalPreviewTab } from './ImageModalPreviewTab';
import styles from './styles.module.scss';

export const TEST_ID_IMAGE_MODAL_PREVIOUS_ARROW = 'image-modal-previous-arrow';
export const TEST_ID_IMAGE_MODAL_NEXT_ARROW = 'image-modal-next-arrow';

export const useImageModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);
  return {
    isOpen,
    closeModal,
    openModal,
  };
};

const PREVIEW_TAB_INDEX = 0;

const findUrl = (urls: string[] | undefined, searchUrl: string) => {
  if (!urls || !urls.length || !searchUrl) return;
  const searchUrlObj = new URL(searchUrl);
  const baseSearchUrl = searchUrlObj.origin + searchUrlObj.pathname;
  return urls.find((url) => url?.startsWith(baseSearchUrl));
};

const findImageId = (url: string): string | undefined => {
  const pattern = /([^/]+\/[^/]+\/[^/]+)\.png/;
  const match = pattern.exec(url);
  if (match) {
    return match[1];
  }
};
export const ImageModal: FC<{
  currentItem?: ContentItem;
  isOpen: boolean;
  currentTab?: number;
  closeModal: () => void;
  onCloseModal?: () => void;
}> = ({ currentItem, isOpen, currentTab, closeModal, onCloseModal }) => {
  const toggleRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [imageModalCloseConfirmPopupCallback, setImageModalCloseConfirmPopupCallback] = useState<{ callback: () => void }>();
  const appContext = useContext(AppContext);
  const imageModalContext = useContext(ImageModalContext);
  const studioContext = useContext(StudioContext);
  const snackbarContext = useContext(SnackbarContext);
  const genAIBackendClient = useAIBackendHubClient();
  const downloadOrSaveImageUrl = imageModalContext.savedEditsImageUrl || imageModalContext.imageUrl;
  const canSaveAsset = !!(appContext.accountType === 'external' && appContext.selectedAdvertisingAccount);

  const localOnCloseModal = () => {
    setActiveTab(PREVIEW_TAB_INDEX);
    imageModalContext.setPendingGeneration(false);
    closeModal();
    onCloseModal?.();
  };

  useEffect(() => {
    setActiveTab(currentTab ?? 0);
  }, [currentTab]);

  const handleDiscardEdits = () => {
    imageModalContext.clearActiveEdit();
    imageModalCloseConfirmPopupCallback?.callback?.();
  };

  const handleAcceptEdits = async () => {
    const activeEditResults = imageModalContext.activeEditResults;
    const editMode = imageModalContext.editMode as EditModes;
    if (currentItem && imageModalContext.activeEditsImageUrl) {
      const workflowId = imageModalContext.activeEditsWorkflowId;
      if (!workflowId) return;

      imageModalContext.setIsPublishingAsset(true);

      const newImageUrl = imageModalContext.activeEditsImageUrl;
      const newImageOriginalUrl = imageModalContext.activeEditsOriginalImageUrl;
      const newImageReferenceId = imageModalContext.activeEditsImageReferenceId;
      const newImageAspectRatio = imageModalContext.activeEditsAspectRatio;
      const savedImageAspectRatio = imageModalContext.savedEditsImageAspectRatio;
      const newImageFeedback = _cloneDeep(imageModalContext.activeEditsImageFeedback);

      let studioInputSettings = _cloneDeep(currentItem.studioInputSettings);
      if (studioInputSettings) {
        studioInputSettings.format = getValidFormatForStudioInputSettings(
          editMode === EditModes.REFRAME ? newImageAspectRatio : (savedImageAspectRatio ?? currentItem?.aspectRatio),
        );
      }

      const newAssetType = getAssetTypeFromWorkflowId(workflowId);
      const newResult: ContentItem = {
        ..._cloneDeep(currentItem),
        content: newImageUrl,
        contentType: getContentTypeByWorkflowId(workflowId),
        workflowId: workflowId,
        originalUri: newImageUrl,
        referenceId: newImageReferenceId,
        feedback: newImageFeedback,
        assetType: newAssetType,
        ...(editMode === EditModes.REFRAME
          ? { aspectRatio: newImageAspectRatio }
          : savedImageAspectRatio
            ? { aspectRatio: savedImageAspectRatio }
            : {}),
        studioInputSettings,
      };

      // Publish Asset
      const batchId = activeEditResults?.batchId;
      const jobId = activeEditResults!.jobs?.[0].jobId;

      const cleanupProcessingNotification = snackbarContext.addProcessingNotification({
        SnackbarContent: PublishAssetProcessingNotification,
      });
      let isError = false;
      let imageId;
      if (activeEditResults && newImageReferenceId && newImageOriginalUrl && batchId && jobId) {
        try {
          // Obtain a fresh signed URL from Catwalk
          const response: { body: BatchResult } = await genAIBackendClient.retrieveResultByWorkflowIdAndBatchId({
            workflowId,
            batchId,
            studioRequest: true,
          });
          const job = response.body?.jobs?.find((job) => job?.jobId === jobId);
          const freshImageOriginalUrl = findUrl(job?.urls, newImageOriginalUrl);
          const assetUrl = freshImageOriginalUrl ?? newImageOriginalUrl;
          imageId = findImageId(assetUrl);
          const payload: PublishAssetInput = {
            workflowId,
            entityId: appContext?.selectedAdvertisingAccount?.alternateIds?.[0],
            body: {
              feedOptions: {
                jobId,
                batchId,
                assetUrl,
                aspectRatio: newResult.aspectRatio,
                imageId,
                prompt: currentItem.studioInputSettings?.prompt,
              },
            },
          };
          const publishRespose = await genAIBackendClient.publishAsset(payload);
          if (publishRespose.body?.assetId) {
            newResult.referenceId = publishRespose.body.assetId;
            // @ts-ignore
          } else if (publishRespose.body?.body?.assetId) {
            // @ts-ignore
            newResult.referenceId = publishRespose.body.body!.assetId;
          } else {
            throw new Error("Publish asset response missing 'assetId'");
          }
        } catch (error) {
          isError = true;
          console.error('Failed to publish asset', error);
        }
      } else {
        isError = true;
        const missingData = [];
        if (!activeEditResults) missingData.push('Edit Results');
        if (!batchId) missingData.push('Batch ID');
        if (!jobId) missingData.push('Job ID');
        if (!newImageReferenceId) missingData.push('Image ID');
        if (!newImageOriginalUrl) missingData.push('Image URL');
        console.error('Failed to publish asset. Missing the following data:', missingData);
      }

      imageModalContext.setIsPublishingAsset(false);
      imageModalContext.setImageReferenceId(newResult.referenceId || imageModalContext.imageReferenceId);
      cleanupProcessingNotification();
      if (isError) {
        snackbarContext.addFailureNotification({ SnackbarContent: PublishAssetFailureNotification });
      } else {
        newResult.workflowId = workflowId;
        newResult.batchId = batchId;
        studioContext.prependResults([newResult]);

        // do not update modal state for video edits. Preserve the input image state and save video into feeds only.
        if (newAssetType === AssetType.VIDEO) {
          imageModalContext.clearActiveEdit();
          imageModalCloseConfirmPopupCallback?.callback?.();
        } else {
          imageModalContext.setSavedEditsImageUrl(newImageUrl);
          imageModalContext.setSavedEditsAssetType(newAssetType);
          imageModalContext.setSavedEditsImageReferenceId(newImageReferenceId);
          imageModalContext.setSavedEditsWorkflowId(workflowId as WorkflowId);
          imageModalContext.setSavedEditsBatchId(batchId);
          imageModalContext.setSavedEditsImageId(imageId);
          // Only update the saved aspect ratio with a new value if edit mode was Reframe.
          // This ensures Reframe uses the correct aspect ratio on successive edits.
          imageModalContext.setSavedEditsImageAspectRatio(editMode === EditModes.REFRAME ? newImageAspectRatio : savedImageAspectRatio);
          imageModalContext.clearActiveEdit();
          // Clears productBoundingBox only if the saved edit mode was not POSITION
          if (editMode !== EditModes.POSITION) {
            imageModalContext.setActiveEditProductAsset(undefined);
          }
          imageModalCloseConfirmPopupCallback?.callback?.();
        }

        // Send a success notification to the user
        snackbarContext.addSuccessNotification({
          SnackbarContent: () => <PublishAssetSuccessNotification assetType={newAssetType} />,
        });
      }
    } else {
      console.error('Failed to accept edit.');
      snackbarContext.addFailureNotification({ SnackbarContent: PublishAssetFailureNotification });
      imageModalContext.clearActiveEdit();
      imageModalCloseConfirmPopupCallback?.callback?.();
    }
  };

  const currentAssetType = imageModalContext.savedEditsAssetType || imageModalContext.assetType;

  const tabs = [
    {
      label: 'Preview',
      child: <ImageModalPreviewTab currentItem={currentItem} />,
    },
    ...(currentAssetType !== AssetType.VIDEO
      ? [
          {
            label: 'Edit',
            child: (
              <ImageModalEditTab
                currentItem={currentItem}
                handleAcceptEdits={handleAcceptEdits}
                openConfirmModal={(callback) => setImageModalCloseConfirmPopupCallback({ callback })}
              />
            ),
          },
        ]
      : []),
  ];

  const handleChangeActiveTab = (index: number) => {
    if (imageModalContext.unsavedWork) {
      setImageModalCloseConfirmPopupCallback({
        callback: () => {
          imageModalContext.clearActiveEdit();
          setActiveTab(index);
        },
      });
    } else {
      imageModalContext.clearActiveEdit();
      setActiveTab(index);
    }
  };

  return (
    <>
      <div id="studio-preview-modal" className={styles.imageModal}>
        <Modal
          isOpen={isOpen}
          onClose={() => {
            if (imageModalContext.unsavedWork || imageModalContext.pendingGeneration) {
              setImageModalCloseConfirmPopupCallback({ callback: localOnCloseModal });
            } else {
              localOnCloseModal();
            }
          }}
          header={
            <div className={styles.navigationHeader}>
              <TabbedNavigation>
                {tabs.map(({ label }, index) => (
                  <TabbedNavigationItem
                    className={styles.navItem}
                    data-testid={`studio-preview-modal-${label.toLowerCase()}-tab`}
                    role={'tab'}
                    name={label}
                    key={index}
                    label={label}
                    active={activeTab === index}
                    onClick={() => handleChangeActiveTab(index)}
                  />
                ))}
              </TabbedNavigation>
              <div className={styles.buttons}>
                {!!downloadOrSaveImageUrl && (
                  <>
                    <DownloadImageButton url={downloadOrSaveImageUrl} disabled={imageModalContext.isPublishingAsset} />
                    {isSponsoredCampaignLaunched() && (
                      <SaveImageToAccountDropdown assetUrl={downloadOrSaveImageUrl} disabled={!canSaveAsset || imageModalContext.isPublishingAsset} />
                    )}
                    {!isSponsoredCampaignLaunched() && (
                      <SaveImageToAccountButton assetUrl={downloadOrSaveImageUrl} disabled={!canSaveAsset || imageModalContext.isPublishingAsset} />
                    )}
                  </>
                )}
              </div>
            </div>
          }
          modalElementId="studio-preview-modal"
        >
          {tabs[activeTab].child}
        </Modal>
        {isOpen && activeTab === PREVIEW_TAB_INDEX && (
          <>
            <div
              data-testid={TEST_ID_IMAGE_MODAL_PREVIOUS_ARROW}
              aria-label="Show previous result"
              className={`${styles.arrowNavigation} ${styles.left}`}
              onClick={() => imageModalContext.handleSwitchToPrevContentItem()}
              role="button"
            >
              <Arrow />
            </div>
            <div
              data-testid={TEST_ID_IMAGE_MODAL_NEXT_ARROW}
              aria-label="Show next result"
              className={`${styles.arrowNavigation} ${styles.right}`}
              onClick={() => imageModalContext.handleSwitchToNextContentItem()}
              role="button"
            >
              <Arrow />
            </div>
          </>
        )}
      </div>
      <div id={'image-modal-close-confirm'} ref={toggleRef} />
      <ImageModalCloseConfirmPopup
        isOpen={!!imageModalCloseConfirmPopupCallback}
        onSave={() => {
          handleAcceptEdits();
        }}
        onDiscard={() => {
          handleDiscardEdits();
        }}
        onClose={() => {
          setImageModalCloseConfirmPopupCallback(undefined);
        }}
      />
    </>
  );
};
