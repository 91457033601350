import { Feedback } from '@amzn/genaihub-typescript-client';
import { useEffect, useRef } from 'react';
import { GenerationOverlay, OverlayVisibility } from 'src/components/generationOverlay/generationOverlay';
import styles from './../generationOverlay/generationOverlay.module.scss';

export const MagnifiedImage = (props: {
  imageUrl: string;
  onBlur: () => void;
  aspectRatio: string;
  onSelected: (imageSrc: string, feedback: Feedback) => void;
  disableSentiment?: boolean;
}) => {
  const divElementRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    divElementRef.current?.focus();
  }, []);

  const aspectRatio = props.aspectRatio.replace(':', ' / ');

  return (
    <div
      style={{
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        background: 'rgba(0,0,0, 0.31)',
        zIndex: 999999999,
        display: 'flex',
        padding: '50px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          borderRadius: '10px',
          outline: 'none',
          height: '100%',
          maxWidth: '100%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          ref={divElementRef}
          tabIndex={1}
          onBlur={(event) => {
            if (!event.relatedTarget) {
              props.onBlur();
            }
          }}
          style={{ position: 'relative', display: 'flex', alignItems: 'center', aspectRatio: aspectRatio, maxHeight: '100%', width: '100%' }}
        >
          <img
            style={{
              borderRadius: '10px',
              width: '100%',
              boxShadow: '0px 4px 100px 0px #FFFFFF',
            }}
            src={props.imageUrl}
          ></img>
          <GenerationOverlay
            visibility={OverlayVisibility.always}
            className={styles.expandedOverlay}
            imageSrc={props.imageUrl}
            onSelected={props.onSelected}
            disableSentiment={props.disableSentiment}
          />
        </div>
      </div>
    </div>
  );
};
