import { Button } from '@amzn/storm-ui';
import styled from 'styled-components';

export const PrimaryButton = styled(Button)`
  && {
    background-color: var(--AI-purple, #4305f4);
    color: white;
  }

  &&:hover {
    background-color: var(--AI-purple, #4305f4);
    color: white;
  }

  &&:disabled {
    background-color: var(--disabled-button-color);
  }
`;

export const PrimaryButtonInverted = styled(Button)`
  && {
    background-color: white;
    color: var(--AI-purple, #4305f4);
  }

  &&:hover {
    background-color: white;
    color: var(--AI-purple, #4305f4);
  }

  &&:disabled {
    background-color: var(--disabled-button-color);
  }
`;
