import { useState } from 'react';

export default function usePerConversationState<T>(
  conversationId: string,
  defaultValue: T | undefined = undefined,
): [T | undefined, (newValue: T | undefined) => void] {
  const [stateMap, setStateMap] = useState<Map<string, T>>(new Map());

  const setState = (newValue: T | undefined) => {
    setStateMap((prev) => {
      if (!conversationId) return prev;
      const newMap = new Map(prev);
      if (!newValue) {
        newMap.delete(conversationId);
      } else {
        newMap.set(conversationId, newValue);
      }
      return newMap;
    });
  };

  return [stateMap?.get(conversationId) || defaultValue, setState];
}
