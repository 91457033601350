import * as KatalMetrics from '@amzn/katal-metrics';
import { Box, Divider, Stack, TextField, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { useState, useContext, useEffect } from 'react';
import { ErrorText } from 'src/components/common/alerts/errorText';
import { WorkFlowModalObject } from 'src/components/common/types';
import { EditorContext } from 'src/components/editor/EditorContext';
import { Metrics } from 'src/constants';
import { AlertTargets } from 'src/constants';
import Submit from './Submit';
import Tooltip from './Tooltip';
import { AppContext } from '../../../../../../AppContext';
import commonStyles from '../../../../common/common.module.scss';

const TextPrompt = ({ control, submit, createControl }: any) => {
  const { controlLabel, controlName, order, description } = control;
  const [prompt, setPrompt] = useState<string>('');
  const [label, setLabel] = useState<string>(description);
  const [children, setChildren] = useState<any>([]);
  const context = useContext(EditorContext);
  const appContext = useContext(AppContext);
  const { metrics } = appContext;
  const [publisher, setPublisher] = useState<KatalMetrics.Publisher | null>(null);
  const [stopWatch, setStopWatch] = useState<KatalMetrics.Metric.TimerStopwatch | null>(null);

  useEffect(() => {
    //	context.childControls.map
    const ch: any = context.childControls.filter((child: any) => child.parent === controlName);
    setChildren([...ch]);
  }, [context.childControls]);

  useEffect(() => {
    if (context.workflowOptions && context.workflowOptions[controlName]) {
      setPrompt(context.workflowOptions[controlName].value);
    }
  }, [context.workflowOptions]);

  const onChangeHandler = (event: any) => {
    if (event.target.value.length > 0) {
      setLabel('');
    } else {
      setLabel(description);
    }

    const obj: WorkFlowModalObject = {};
    obj[controlName] = { value: event.target.value, type: 'text' };
    setPrompt(event.target.value);
    context.setWorkFlowOptions({ ...context.workflowOptions, ...obj });
  };

  const onBlurHandler = () => {
    // Publish Value
    publisher?.publishString(Metrics.Names.Value, prompt ?? '');
    // Publish timer
    if (stopWatch) {
      publisher?.publish(stopWatch);
    }
    // Clear timer and publisher
    setPublisher(null);
    setStopWatch(null);
  };

  const onFocusHandler = () => {
    // Start timer
    setPublisher(metrics.getMetrics().newChildActionPublisherForMethod(Metrics.Methods.EnterTextPrompt));
    setStopWatch(new KatalMetrics.Metric.TimerStopwatch(Metrics.Timers.TextInput));
  };

  const getControl = () => {
    if (children.length) {
      return [{ order }, ...children]
        .sort((a: any, b: any) => a.order - b.order)
        .map((card, index) => {
          const Comp = card.controlType ? createControl(card, index) : Control(index);
          return Comp;
        });
    } else {
      return Control();
    }
  };

  const TandC = () => (
    <Box style={{ overflowY: 'scroll' }}>
      <Typography
        sx={{ p: 2 }}
        style={{ width: '417px', height: '285px', fontSize: '13px', fontWeight: '400', lineHeight: '19px', marginRight: '10px' }}
      >
        By clicking or tapping to enter the Amazon Ads AI Creative Studio, which is an experimental Amazon confidential beta feature provided “as is,”
        you agree to Amazon’s{' '}
        <Link color={'blue'} underline={'none'} href={'https://www.amazon.com/gp/help/customer/display.html?nodeId=GLSBYFE9MGKKQXXM'}>
          {'Conditions of Use'}
        </Link>
        ,
        <Link color={'blue'} underline={'none'} href={'https://www.amazon.com/gp/help/customer/display.html?nodeId=GX7NJQ4ZB8MHFRNJ'}>
          {' Privacy Notice'}
        </Link>
        ,
        <Link color={'blue'} underline={'none'} href={'https://advertising.amazon.com/resources/ad-specs?ref_=a20m_us_hnav_smb_spcs'}>
          {' Advertising Agreement'}
        </Link>
        , and{' '}
        <Link color={'blue'} underline={'none'} href={'https://www.amazon.com/gp/help/customer/display.html?nodeId=GX7NJQ4ZB8MHFRNJ'}>
          {' Ads Policies'}
        </Link>
        . You are responsible for ensuring your use of the AI Creative Studio and any outputs you generate complies with the forgoing and all
        applicable laws. If you provide feedback, comments, or other content in connection with your use of the AI Creative Studio, do not include
        personal data, health information, or other sensitive information or information that you are not authorized to share. You may not discuss any
        aspect of the AI Creative Studio, such as functionality and documentation, with anyone outside of the Ads Creative Experience team. You may
        not share or distribute any outputs generated by the AI Creative Studio without explicit approval from the Ads Creative Experience team. The
        AI Creative Studio may be subject to change, disruption, or deprecation without notice.
      </Typography>
    </Box>
  );
  const Control = (index: number = 0) => {
    return (
      <Stack direction="column" width={'100%'} gap={2} flex={1} alignItems={'flex-start'} style={{ gap: '7px', marginTop: '3px' }}>
        <Stack direction="row" alignItems={'flex-end'} justifyContent={'space-between'} style={{ width: 'calc(100% - 139px)' }}>
          <Typography className={commonStyles.textFieldLabel} textAlign={'left'} fontWeight={600}>
            {controlLabel}
          </Typography>
          <Typography style={{ float: 'right', color: '#495566', fontSize: '13px', margin: '8px 8px 3px 0px' }}>
            Terms and conditions
            <Tooltip title={<TandC />}></Tooltip>
          </Typography>
        </Stack>
        <Box sx={{ display: 'flex', width: '100%', gap: '10px', alignItems: 'center', marginTop: '6px' }}>
          <TextField
            color={appContext.alertEvent?.target === AlertTargets.textPrompt ? 'error' : 'primary'}
            key={'tf' + index}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            inputProps={{ maxLength: 700 }}
            fullWidth
            label={label}
            value={prompt}
            sx={{
              fontSize: '12px',
              '& legend': { display: 'none' },
              '& .MuiInputLabel-shrink': { opacity: 0, transition: 'all 0.2s ease-in' },
              '&.MuiOutlinedInput-notchedOutline': { fontSize: '12px' },
              fieldset: { borderColor: appContext.alertEvent?.target === AlertTargets.textPrompt ? 'red' : '' },
            }}
            id="fullWidth"
          />
          <Submit submit={submit} />
        </Box>
        <div id="fullWidth" style={{ width: '89%' }}>
          <span style={{ color: '#5C687C', fontSize: '12px', marginTop: '-5px' }}>{700 - prompt.length} characters left</span>
        </div>
        {appContext.alertEvent?.target === AlertTargets.textPrompt && (
          <Box display={'flex'} paddingBottom={'10px'}>
            <ErrorText {...appContext.alertEvent} />
          </Box>
        )}
      </Stack>
    );
  };

  return (
    <>
      {
        <Box>
          <Stack pl={2} pr={2} gap={2} direction={'row'} alignItems={'stretch'} divider={<Divider orientation="vertical" flexItem />}>
            {getControl()}
          </Stack>
        </Box>
      }
    </>
  );
};
export default TextPrompt;
