import { Icon, IconProps, TextButton } from '@amzn/storm-ui';
import { solidThumbsDown, solidThumbsUp, thumbsDown, thumbsUp } from '@amzn/storm-ui-icons';
import { useContext, useRef } from 'react';
import styled from 'styled-components';
import { FeedbackAlert } from './FeedbackAlert';
import { FeedbackContext, FeedbackSentiment } from './FeedbackContext';
import styles from './FeedbackThumbs.module.scss';

export enum IconColor {
  WHITE = 'white',
  BLACK = 'black',
}

const ThumbIcon = styled(Icon)<{ iconColor: IconColor }>`
  font-size: 18px;
  color: ${({ iconColor }) => iconColor};

  &:hover {
    color: ${({ iconColor }) => iconColor};
  }

  &:disabled {
    color: var(--disabled-button-color);
  }
`;

const FeedbackThumb = (props: {
  associatedFeedbackSentiment: FeedbackSentiment;
  activeIconType: IconProps['type'];
  inactiveIconType: IconProps['type'];
  onClick: (feedbackSentiment: FeedbackSentiment) => void;
  iconColor?: IconColor;
}) => {
  const feedbackContext = useContext(FeedbackContext);
  const triggerRef = useRef<HTMLButtonElement>(null);

  return (
    <FeedbackAlert
      associatedFeedbackSentiment={props.associatedFeedbackSentiment}
      triggerRef={triggerRef}
      trigger={
        <TextButton
          ref={triggerRef}
          onClick={() => props.onClick(props.associatedFeedbackSentiment)}
          disabled={feedbackContext.feedbackSentiment === props.associatedFeedbackSentiment}
        >
          <ThumbIcon
            iconColor={props.iconColor ?? IconColor.WHITE}
            type={feedbackContext.feedbackSentiment === props.associatedFeedbackSentiment ? props.activeIconType : props.inactiveIconType}
          />
        </TextButton>
      }
    />
  );
};

export const FeedbackThumbsControl = (props: { iconColor?: IconColor }) => {
  const feedbackContext = useContext(FeedbackContext);

  const onClickThumb = (feedbackSentiment: FeedbackSentiment) => {
    if (feedbackContext.feedbackSentiment !== feedbackSentiment) {
      feedbackContext.setFeedbackSentiment(feedbackSentiment);
      feedbackContext.setIsFeedbackPopoverOpen(true);
    }
  };

  return (
    <div className={styles.feedbackThumbsControl}>
      <FeedbackThumb
        associatedFeedbackSentiment={FeedbackSentiment.LIKE}
        activeIconType={solidThumbsUp}
        inactiveIconType={thumbsUp}
        iconColor={props.iconColor}
        onClick={onClickThumb}
      />
      <FeedbackThumb
        associatedFeedbackSentiment={FeedbackSentiment.DISLIKE}
        activeIconType={solidThumbsDown}
        inactiveIconType={thumbsDown}
        iconColor={props.iconColor}
        onClick={onClickThumb}
      />
    </div>
  );
};
