import styles from './Cancel.module.scss';

const CancelButton = (props: any) => {
  const { callback } = props;
  //const context = useContext(EditorContext);

  return (
    <button className={styles.cancelButton} onClick={callback}>
      Cancel
    </button>
  );
};

export default CancelButton;
