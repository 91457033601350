import { Button, Modal } from '@amzn/storm-ui';
import { FC, useContext } from 'react';
import { ImageModalContext } from 'src/components/imageModal/ImageModalContext';
import { getAssetTypeText } from 'src/components/utils/assetUtils';
import styles from './ImageModalCloseConfrimPopup.module.scss';

export const TEST_ID_IMAGE_MODAL_CLOSE_CONFIRM_POPUP = 'image-modal-close-confirm-popup';

export const ImageModalCloseConfirmPopup: FC<{
  isOpen: boolean;
  onDiscard: () => void;
  onSave: () => void;
  onClose: () => void;
}> = ({ isOpen, onDiscard, onSave, onClose }) => {
  const { pendingGeneration, activeEditsAssetType } = useContext(ImageModalContext);

  return (
    <Modal
      modalElementId={'image-modal-close-confirm'}
      header={
        <div className={styles.header}>
          {pendingGeneration
            ? `${getAssetTypeText(activeEditsAssetType, { useCapital: true, usePlural: true })} are generating`
            : `Leave without saving your ${getAssetTypeText(activeEditsAssetType)}?`}
        </div>
      }
      footer={
        <div className={styles.footer}>
          <Button
            onClick={() => {
              onDiscard();
              onClose();
            }}
          >
            {pendingGeneration ? 'Leave' : 'Continue'}
          </Button>
          <Button
            primary
            className={styles.cta}
            onClick={() => {
              !pendingGeneration && onSave();
              onClose();
            }}
          >
            {pendingGeneration
              ? `Wait for ${getAssetTypeText(activeEditsAssetType, { usePlural: true })}`
              : `Save ${getAssetTypeText(activeEditsAssetType)}`}
          </Button>
        </div>
      }
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <div data-testid={TEST_ID_IMAGE_MODAL_CLOSE_CONFIRM_POPUP} className={styles.content}>
        {pendingGeneration
          ? `If you leave now, ${getAssetTypeText(activeEditsAssetType, { usePlural: true })} will stop generating and your changes will be lost.`
          : `If you leave without saving your ${getAssetTypeText(activeEditsAssetType)}, your ${getAssetTypeText(activeEditsAssetType)} results will be deleted.`}
      </div>
    </Modal>
  );
};
