import _cloneDeep from 'lodash/cloneDeep';
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { Feedback, FeedbackContext, SubmittedFeedback } from 'src/components/feedback/FeedbackContext';
import { FeedbackThumbsControl } from 'src/components/feedback/FeedbackThumbsControl';
import { getImageFromUrl, LoadedImage } from 'src/components/utils/ImageUtils';
import styles from './ImageWithThumbnails.module.scss';
import { LoadingAnimation } from './LoadingAnimation';
import { AssetType, WorkflowId } from '../imageModal/components/utils';

export enum ImageStatus {
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR',
}

export interface Image {
  originalUrl: string;
  referenceId: string;
  url: string;
}

export interface ImageWithThumbnailsRef {
  setImages: (images: Image[]) => void;
}

export interface ImageWithThumbnailsProps {
  assetType: AssetType;
  workflowId: WorkflowId;
  onChangeIsLoading?: (isLoading: boolean) => void;
  onChangeSelectedImage?: (selectedImage: ImageState | undefined) => void;
  onSubmittedFeedback?: (submittedFeedback: SubmittedFeedback) => void;
}

export interface ImageState {
  status: ImageStatus;
  image: Image;
  feedback?: Feedback;
  loadedImage?: LoadedImage;
}

const SPACE_ASPECT_RATIO = 100 / 70;

export const ImageWithThumbnails = forwardRef<ImageWithThumbnailsRef, ImageWithThumbnailsProps>((props, ref) => {
  const [images, setImages] = useState<Image[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
  const [aspectRatio, setAspectRatio] = useState<number>(0);
  const [loadingImageWidth, setLoadingImageWidth] = useState<string>('');
  const [loadingImageHeight, setLoadingImageHeight] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [imageStates, setImageStates] = useState<ImageState[]>([]);

  const { setOnSubmittedFeedback, isFeedbackPopoverOpen, setFeedbackMetadata, setFeedbackComment, setFeedbackSentiment, closeAndSubmitFeedback } =
    useContext(FeedbackContext);

  useEffect(() => {
    setOnSubmittedFeedback(() => (submittedFeedback: SubmittedFeedback) => {
      // Assign submitted feedback to the image
      if (submittedFeedback) {
        setImageStates((value: ImageState[]) =>
          value.map((imageState) =>
            imageState.image.referenceId === submittedFeedback.feedbackMetadata?.referenceId
              ? {
                  ...imageState,
                  feedback: _cloneDeep(submittedFeedback.feedback),
                }
              : imageState,
          ),
        );
        props.onSubmittedFeedback?.(submittedFeedback);
      }
    });
  }, []);

  useImperativeHandle(ref, () => ({
    setImages,
  }));

  useEffect(() => {
    props.onChangeIsLoading?.(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (!images?.length) {
      setIsLoading(false);
      setImageStates([]);
      setSelectedImageIndex(-1);
      return;
    }

    let apply = true;
    let aspectRatioSet = false;

    const newImageStates: ImageState[] = (images || []).map((image: Image) => ({
      image: { ...image },
      status: ImageStatus.LOADING,
    }));

    const updateImageState = (updatedImageState: ImageState) => {
      if (!apply) return;
      setImageStates((value) =>
        value.map((imageState) => (imageState.image.referenceId === updatedImageState.image.referenceId ? updatedImageState : imageState)),
      );
    };

    const updateAspectRatio = ({ width, height }: { width: number; height: number }) => {
      if (!apply) return;
      if (!aspectRatioSet) {
        aspectRatioSet = true;
        const newAspectRatio = width / height;
        let newLoadingImageWidth, newLoadingImageHeight;
        if (newAspectRatio < SPACE_ASPECT_RATIO) {
          newLoadingImageWidth = '100%';
          newLoadingImageHeight = `${100 * (1 / newAspectRatio)}%`;
        } else {
          newLoadingImageWidth = `${100 * newAspectRatio}%`;
          newLoadingImageHeight = '100%';
        }
        setLoadingImageWidth(newLoadingImageWidth);
        setLoadingImageHeight(newLoadingImageHeight);
        setAspectRatio(width / height);
      }
    };

    const fetchImages = async () => {
      await Promise.all(newImageStates.map((imageState) => fetchImage(imageState)));
      if (apply) {
        setIsLoading(false);
      }
    };

    const fetchImage = async (imageState: ImageState) => {
      try {
        if (props.assetType === AssetType.VIDEO) {
          updateImageState({
            ...imageState,
            status: ImageStatus.READY,
          });
        } else {
          // preload for image only; video assets natively preload on the <video /> element
          const loadedImage = await getImageFromUrl({
            url: imageState.image.url,
            onFetchDimensions: updateAspectRatio,
          });
          updateAspectRatio({
            width: loadedImage.width,
            height: loadedImage.height,
          });
          updateImageState({
            ...imageState,
            loadedImage,
            status: ImageStatus.READY,
          });
        }
      } catch (error) {
        console.error('Failed to load image', error);
        updateImageState({
          ...imageState,
          status: ImageStatus.ERROR,
        });
      }
    };

    setImageStates(newImageStates);
    setSelectedImageIndex(0);
    setIsLoading(true);
    fetchImages();

    // cancel application of this useEffect instance's fetchImage if useEffect cleaned up.
    return () => {
      apply = false;
    };
  }, [images]);

  useEffect(() => {
    if (isFeedbackPopoverOpen) {
      closeAndSubmitFeedback();
    }

    if (selectedImageIndex >= 0 && selectedImageIndex < imageStates.length) {
      const imageState = imageStates[selectedImageIndex];
      setFeedbackComment(imageState.feedback?.comment);
      setFeedbackSentiment(imageState.feedback?.sentiment);
      setFeedbackMetadata({
        assetUrl: imageState.image.originalUrl,
        prompt: undefined,
        referenceId: imageState.image.referenceId,
        workflowId: props.workflowId,
        assetType: props.assetType,
      });
      props.onChangeSelectedImage?.(imageState);
    } else {
      setFeedbackComment(undefined);
      setFeedbackSentiment(undefined);
      setFeedbackMetadata(undefined);
      props.onChangeSelectedImage?.(undefined);
    }
  }, [selectedImageIndex]);

  const onClickThumbnail = (index: number) => {
    if (!isLoading) {
      setSelectedImageIndex(index);
    }
  };

  return (
    <div className={`${styles.imageWithThumbnails}`}>
      <div className={`${styles.selectedImage}`}>
        <div className={`${styles.imgWrapper} ${!isLoading ? styles.show : ''} ${isFeedbackPopoverOpen ? styles.feedbackPopoverOpen : ''}`}>
          {imageStates && imageStates[selectedImageIndex] && imageStates[selectedImageIndex].status === ImageStatus.READY ? (
            <>
              {props.assetType === AssetType.VIDEO ? (
                <video
                  preload="auto"
                  controls={true}
                  autoPlay
                  playsInline
                  muted
                  loop
                  src={imageStates[selectedImageIndex].image.url}
                  data-testid="image-thumbnails-selected-video"
                />
              ) : (
                <img src={imageStates[selectedImageIndex].image.url} data-testid="image-thumbnails-selected-image" />
              )}
            </>
          ) : (
            <></>
          )}
          {props.assetType !== AssetType.VIDEO && (
            <div className={styles.overlay}>
              <FeedbackThumbsControl />
            </div>
          )}
        </div>
        <LoadingAnimation
          className={`${styles.loadingAnimation} ${isLoading ? styles.show : ''}`}
          primaryMessage="Loading"
          style={{
            width: loadingImageWidth || '100%',
            height: loadingImageHeight || '100%',
          }}
        />
      </div>
      <div
        className={`${styles.thumbnails}`}
        style={{
          minHeight: '60px',
          maxHeight: `clamp(60px, ${(1 / (aspectRatio || 1.91)) * 80}px, 20% - 20px)`,
        }}
      >
        {imageStates.length > 1 &&
          imageStates.map((imageState, index) => (
            <div
              key={imageState.image.referenceId}
              className={`${styles.thumbnail} ${selectedImageIndex === index ? styles.selected : ''}`}
              onClick={() => onClickThumbnail(index)}
            >
              <div className={`${styles.imgWrapper} ${!isLoading ? styles.show : ''}`}>
                {imageState.status === ImageStatus.READY ? <img src={imageState.image.url} data-testid={'image-results-thumbnail'} /> : <></>}
              </div>
              <LoadingAnimation className={`${styles.loadingAnimation} ${isLoading ? styles.show : ''}`} />
            </div>
          ))}
      </div>
    </div>
  );
});

ImageWithThumbnails.displayName = 'ImageWithThumbnails';
