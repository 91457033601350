import { Feedback, Scope, WorkflowId } from '@amzn/genaihub-typescript-client';
import { Button, Modal, Text, TextAreaFormGroup } from '@amzn/storm-ui';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useContext, useRef, useState } from 'react';
import CancelButton from 'src/components/editor/UiContols/uiGeneratorControls/components/CancelButton';
import cssStyles from 'src/components/editor/UiContols/uiGeneratorControls/storm/uiform.module.scss';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import styled from 'styled-components';
import { AppContext } from '../../../../AppContext';

const SubmitButton = styled(Button)`
  &&,
  &&:visited,
  &&:active,
  &&:link,
  &&:hover {
    background-color: #4305f4;
    color: ${({ theme }) => theme.palette.white};
  }
`;

interface FeedbackPopoverStormProps {
  toggle: boolean;
  closePopover: any;
  feedbackScope: Scope;
  sentiment: Feedback;
  imageSrc?: string;
  type?: string;
  prompt?: string;
}

const FeedbackPopoverStorm = (props: FeedbackPopoverStormProps) => {
  const { toggle, closePopover, feedbackScope, sentiment, imageSrc, type, prompt } = props;

  const [text, setText] = useState('');
  const buttonRef = useRef();

  const handleCancel = () => {
    closePopover('cancel');
  };

  const appContext = useContext(AppContext);
  const { selectedTool } = appContext;

  const genAIBackendClient = useAIBackendHubClient();

  const commentFeedBackMutation = useMutation({
    mutationFn: () => {
      const assetUrl = imageSrc ? new URL(imageSrc) : undefined;

      return genAIBackendClient.submitFeedbackSentiment({
        body: {
          feedback: sentiment,
          workflowId: selectedTool as WorkflowId,
          assetUri: assetUrl ? assetUrl.origin + assetUrl.pathname : undefined,
          contentCreationTime: assetUrl ? 30.55 : undefined, // TODO: get this from parent
          textFeedback: text,
          prompt,
          scope: feedbackScope,
        },
      });
    },
    onSuccess: () => {
      closePopover('submit');
      setText('');
    },
  });

  const handleSubmit = () => {
    commentFeedBackMutation.mutate();
  };

  const Footer = () => (
    <>
      <CancelButton style={{ color: '#4305F4' }} callback={handleCancel}>
        Cancel
      </CancelButton>
      <SubmitButton loading={commentFeedBackMutation.isPending} onClick={handleSubmit} primary>
        Submit
      </SubmitButton>
    </>
  );

  const handleChange = (e: any) => {
    setText(e.target.value);
  };

  const ImageDisplay = useCallback(() => {
    return (
      <>
        {type !== 'video' ? (
          <span
            style={{
              marginRight: '20px',
            }}
          >
            <img src={imageSrc} alt={'thumbnail'} style={{ borderRadius: '8px', height: '100px' }} />
          </span>
        ) : (
          <></>
        )}
      </>
    );
  }, [imageSrc, type]);

  return (
    <>
      <Modal
        isOpen={toggle}
        // header="Tell us what you thought of this tool"
        hasCloseButton={false}
        footer={<Footer />}
        onClose={handleCancel}
        // The trigger element ref for Modal
        // Modal will use this ref to restore focus when closing
        toggleEl={buttonRef.current}
      >
        <div
          style={{
            width: '444px',
          }}
        >
          <div style={{ width: '100%', display: 'flex', marginBottom: '18px' }}>
            {imageSrc && <ImageDisplay />}
            <span>
              <Text
                style={{
                  // width: '100% - 10px',
                  textAlign: 'left',
                  marginBottom: '10px',
                  fontWeight: '700',
                  fontSize: '16px',
                  lineHeight: '19.15px',
                }}
              >
                Tell us what you thought of this tool
              </Text>
              <Text
                style={{
                  textAlign: 'left',
                  fontWeight: '400',
                  fontSize: '12px',
                  lineHeight: '18px',
                  fontStyle: 'italic',
                  color: '#5C687C',
                }}
              >
                Your feedback is important to help us continually improve our AI models to help you generate better ads. Please do not include
                personal information.
              </Text>
            </span>
          </div>

          <TextAreaFormGroup
            fullWidth
            value={text}
            onChange={handleChange}
            id="textarea-basic"
            label=""
            hideLabel
            placeholder=""
            disabled={commentFeedBackMutation.isPending}
            style={{
              background: '#F2F4F6',
              width: '100%',
              height: '248px',
            }}
            className={cssStyles.inputFieldStyle}
          />
        </div>
      </Modal>
    </>
  );
};

export default FeedbackPopoverStorm;
