import { Box, IconButton } from '@mui/material';
import { Button } from '@mui/material/';
import { useContext } from 'react';
import { Metrics } from 'src/constants';
import { AppContext } from '../../../AppContext';
import LightBulb from '../../icons/lightBulb.svg';

const AppBarMenu = (props: { showFeedbackButton?: boolean }) => {
  const appContext = useContext(AppContext);
  const { metrics } = appContext;

  const handleOpenUserMenu = () => {
    metrics.trackMetrics(Metrics.Methods.UserIcon, { [Metrics.Names.Action]: Metrics.Actions.Open }, { [Metrics.Counters.Count]: 1 });
  };

  return (
    <Box sx={{ flexGrow: 0, ml: '20px' }}>
      {props.showFeedbackButton && (
        <Button
          style={{
            backgroundColor: '#D9DEE4',
            color: 'black',
            padding: '6px 22px',
            marginRight: '25px',
            fontSize: '0.95rem',
            borderRadius: '100px',
          }}
          onClick={() => {}}
        >
          {' '}
          <LightBulb />
          &nbsp;&nbsp;Feedback
        </Button>
      )}
      {appContext.userDetails?.alias && (
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <img
            style={{
              borderRadius: '50%',
              width: '1.5em',
              height: '1.5em',
              objectFit: 'cover',
            }}
            src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${appContext.userDetails.alias}`}
          />
        </IconButton>
      )}
    </Box>
  );
};
export default AppBarMenu;
