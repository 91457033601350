export const ExclamationMark = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1685_3973)">
        <path
          d="M9.60039 13.8001C9.60039 14.6838 8.88405 15.4001 8.00039 15.4001C7.11673 15.4001 6.40039 14.6838 6.40039 13.8001C6.40039 12.9164 7.11673 12.2001 8.00039 12.2001C8.88405 12.2001 9.60039 12.9164 9.60039 13.8001ZM8.00039 2.6001C8.88405 2.6001 9.60039 3.31644 9.60039 4.2001V9.8001C9.60039 10.6838 8.88405 11.4001 8.00039 11.4001C7.11674 11.4001 6.40039 10.6838 6.40039 9.8001V4.2001C6.40039 3.31644 7.11673 2.6001 8.00039 2.6001Z"
          fill="#D82624"
        />
      </g>
      <defs>
        <clipPath id="clip0_1685_3973">
          <rect width="4" height="12.8" fill="white" transform="translate(6 2.6001)" />
        </clipPath>
      </defs>
    </svg>
  );
};
