import { useContext, useEffect } from 'react';
import { EditorContextStateP1 } from 'src/components/editor/EditorContext';
import batchStateUpdate from 'src/components/utils/batchStateUpdate';
import removePunctuationAndSpecialChars from 'src/components/utils/removePunctuationAndSpecialChars';
const HiddenAsin = ({ control, editorContext, bulkUpdateCallback }: any) => {
  const context: EditorContextStateP1 = useContext(editorContext);

  useEffect(() => {
    publishAsin();
  }, []);

  const publishAsin = () => {
    //context.setDisableControl(false);
    const asin = 'B0CC62ZG1M';
    const title =
      'Fitbit Charge 6 Fitness Tracker with Google apps, Heart Rate on Exercise Equipment, 6-Months Premium Membership Included, GPS, Health Tools and More, Obsidian/Black, One Size ';
    const workflowOptions: any = { image_count: { value: 4 } };
    workflowOptions[control.controlName] = { value: asin };
    workflowOptions.title = { value: removePunctuationAndSpecialChars(title) };
    // context.setWorkFlowOptions({ ...context.workflowOptions, ...workflowOptions });
    batchStateUpdate(workflowOptions, bulkUpdateCallback, 500);
  };

  return <></>;
};

export default HiddenAsin;
