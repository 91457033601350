import { Box, Card, Grid, Skeleton } from '@mui/material';

export default function AIModelSkeleton({ gridColumns }: { gridColumns: number }) {
  return (
    <Grid item xs={12} md={gridColumns}>
      <Card>
        <Box sx={{ padding: '1em 1em 1em 1em' }}>
          <Skeleton variant="rounded" width={'100%'} height={'175px'} />
        </Box>
        <Box sx={{ padding: '0em 1em 0.5em 1em' }}>
          <Skeleton variant="rounded" width={'10%'} height={12} />
        </Box>
        <Box sx={{ padding: '0em 1em 0.5em 1em' }}>
          <Skeleton variant="rounded" width={'50%'} height={14} />
        </Box>

        <Box sx={{ padding: '0em 1em 1em 1em' }}>
          <Skeleton variant="rounded" width={'90%'} height={12} />
        </Box>
        <Box sx={{ padding: '1em 1em 1em 1em' }}>
          <Skeleton variant="rounded" width={'60px'} height={'36px'} style={{ borderRadius: '16px' }} />
        </Box>
      </Card>
    </Grid>
  );
}
