import { useBundle } from '@amzn/react-arb-tools';
import { getErrorResourceKeyDetailsByCode } from 'src/api/errorTypes';
import { ALERTS_MESSAGE_BUNDLE_NAME } from 'src/constants';
import { t } from 'src/helpers';
import { ExclamationMark } from 'src/icons/exclamationMark';

export const ErrorText = (props: { code?: number; resourceKey?: string; message?: string; resourceArgs?: Record<string, unknown> }) => {
  const [bundle] = useBundle(ALERTS_MESSAGE_BUNDLE_NAME);

  return (
    <div className="centeredRowContent errorMessage">
      <ExclamationMark />
      <text style={{ paddingLeft: '5px' }}>
        {props.message || t(bundle, getErrorResourceKeyDetailsByCode(props.code) || props.resourceKey || 'generic_error_text', props.resourceArgs)}
      </text>
    </div>
  );
};
