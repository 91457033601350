export enum ErrorCodes {
  ERROR_CODE_10026 = 10026,
  ERROR_CODE_20025 = 20025,
  ERROR_CODE_20026 = 20026,
  ERROR_CODE_20027 = 20027,
  ERROR_CODE_20028 = 20028,
  ERROR_CODE_20029 = 20029,
  ERROR_CODE_20030 = 20030,
  ERROR_CODE_20031 = 20031,
  ERROR_CODE_20032 = 20032,
  ERROR_CODE_20033 = 20033,
  ERROR_CODE_20034 = 20034,
  ERROR_CODE_20035 = 20035,
  /**
   * File size more than 20MB
   */
  ERROR_CODE_20036 = 20036,
  ERROR_CODE_20037 = 20037,
  ERROR_CODE_20038 = 20038,
  ERROR_CODE_20039 = 20039,
}

export const errorTypes: { [key: string]: any } = {
  [ErrorCodes.ERROR_CODE_10026]: {
    resource_key: '',
  },
  [ErrorCodes.ERROR_CODE_20025]: {
    resource_key: 'image_description_moderation_error_text',
  },
  [ErrorCodes.ERROR_CODE_20026]: {
    resource_key: 'product_image_policy_violation_error_text',
  },
  [ErrorCodes.ERROR_CODE_20027]: {
    resource_key: '',
  },
  [ErrorCodes.ERROR_CODE_20028]: {
    resource_key: '',
  },
  [ErrorCodes.ERROR_CODE_20029]: {
    resource_key: '',
  },
  [ErrorCodes.ERROR_CODE_20030]: {
    resource_key: '',
  },
  [ErrorCodes.ERROR_CODE_20031]: {
    resource_key: '',
  },
  [ErrorCodes.ERROR_CODE_20032]: {
    resource_key: '',
  },
  [ErrorCodes.ERROR_CODE_20033]: {
    resource_key: '',
  },
  [ErrorCodes.ERROR_CODE_20034]: {
    resource_key: '',
  },
  [ErrorCodes.ERROR_CODE_20035]: {
    resource_key: '',
  },
  [ErrorCodes.ERROR_CODE_20036]: {
    resource_key: 'upload_image_error_text',
  },
  [ErrorCodes.ERROR_CODE_20037]: {
    resource_key: 'upload_image_format_error_text',
  },
  [ErrorCodes.ERROR_CODE_20038]: {
    resource_key: 'upload_image_format_error_text',
  },
  [ErrorCodes.ERROR_CODE_20039]: {
    resource_key: '',
  },
};

export const getErrorResourceKeyDetailsByCode = (code: ErrorCodes | undefined | null): string => {
  if (!code) return '';
  return errorTypes[code.toString()]?.resource_key ?? '';
};

export const isTextPromptError = (code: ErrorCodes) => {
  return code === ErrorCodes.ERROR_CODE_20025;
};
