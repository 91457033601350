import { Stack } from '@mui/material';
import React from 'react';
import UIGenerator from './UIGenerator';

interface Control {
  controlType: string;
  controlName: string;
  controlLabel?: string;
  visible: boolean;
  parent?: boolean;
  tooltip?: string;
  section?: string;
  order: number;
}

interface LeftPanelProps {
  componentList: Control[];
}

const LeftPanel = ({ componentList }: LeftPanelProps): React.JSX.Element => {
  if (!componentList || componentList.length === 0) return <></>;
  return (
    <>
      <Stack minWidth={'300px'} padding={1} direction={'column'} spacing={2}>
        <UIGenerator config={componentList} />
      </Stack>
    </>
  );
};

export default LeftPanel;
