export const ImportMessages = {
  SINGLE_FAILURE: 'Sorry, something went wrong. Please try again later.',
};

export const FileSelectionMessages = {
  PARTIAL_FAILURE: "We're sorry, some of your images could not be uploaded",
  PARTIAL_FAILURE_FILTERED: "We're sorry, some of your images could not be uploaded. Upload JPEG or PNG images smaller than 20 MB.",
  FAILURE: "We're sorry, your image(s) could not be uploaded",
  FAILURE_FILTERED: "We're sorry, your image(s) could not be uploaded. Upload JPEG or PNG images smaller than 20 MB.",
};

const SubmitNotification = ({ message }: { message: string }) => {
  return <>{message}</>;
};

export const ImportImageImportFailureNotification = () => <SubmitNotification message={ImportMessages.SINGLE_FAILURE} />;
export const ImportImageSelectionFailureNotification = () => <SubmitNotification message={FileSelectionMessages.FAILURE} />;
export const ImportImageSelectionPartialFailureNotification = () => <SubmitNotification message={FileSelectionMessages.PARTIAL_FAILURE} />;
export const ImportImageFilteredSelectionFailureNotification = () => <SubmitNotification message={FileSelectionMessages.FAILURE_FILTERED} />;
export const ImportImageFilteredSelectionPartialFailureNotification = () => (
  <SubmitNotification message={FileSelectionMessages.PARTIAL_FAILURE_FILTERED} />
);
