import { Persona } from '@amzn/red-carpet-service-client';
import DefaultImage from '../assets/personas/default.png';
import DesignerImage from '../assets/personas/designer.png';

interface PersonaUiConfig {
  image: string;
  color: string;
}

const PERSONAL_TO_CONFIG: Record<Persona, PersonaUiConfig> = {
  [Persona.DESIGNER]: { image: DesignerImage, color: '#11A8A8' },
};

export default function getPersonaUiConfig(persona?: Persona): PersonaUiConfig {
  return (persona ? PERSONAL_TO_CONFIG[persona] : undefined) || { image: DefaultImage, color: '#ff7453' };
}
