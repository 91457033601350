import { useEffect, useState } from 'react';
import styles from './Action.module.scss';

const ActionButton = (props: any) => {
  const { callback, validation, buttonText } = props;
  //const context = useContext(EditorContext);
  const [disableSubmit, setDisableSubmit] = useState(true);

  useEffect(() => {
    setDisableSubmit(!validation);
  }, [validation]);

  return (
    <button className={styles.actionButton} disabled={disableSubmit} onClick={callback}>
      {buttonText}
    </button>
  );
};

export default ActionButton;
