import { Modal, ModalProps, Text, TextAreaFormGroup } from '@amzn/storm-ui';
import React, { useContext } from 'react';
import { PrimaryButton } from 'src/components/_common/buttons/PrimaryButton';
import { SecondaryButton } from 'src/components/_common/buttons/SecondaryButton';
import { AssetType } from 'src/components/imageModal/components/utils';
import styles from './FeedbackCommentModal.module.scss';
import { AssetFeedbackMetadata, FeedbackContext, FeedbackScope } from './FeedbackContext';

const Footer = () => {
  const feedbackContext = useContext(FeedbackContext);

  const handleCancel = () => {
    feedbackContext.setFeedbackComment('');
    feedbackContext.submitFeedback();
  };

  const handleSubmit = () => {
    feedbackContext.submitFeedback({ includeComment: true });
  };

  return (
    <>
      <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
      <PrimaryButton onClick={handleSubmit}>Submit</PrimaryButton>
    </>
  );
};

export interface FeedbackCommentModalProps {
  isOpen: boolean;
  toggleEl: ModalProps['toggleEl'];
}

const InstructionsHeading = {
  [FeedbackScope.ASSET]: 'Tell us what you thought of this asset',
  [FeedbackScope.MODULE]: 'Tell us what you thought of this tool',
  [FeedbackScope.OTHER]: 'Tell us about your experience',
};

export const FEEDBACK_COMMENT_MODAL_ID = 'feedback-comment-modal';

export const FeedbackCommentModal = (props: FeedbackCommentModalProps) => {
  const feedbackContext = useContext(FeedbackContext);

  const handleTextAreaChange: React.ChangeEventHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    feedbackContext.setFeedbackComment(e.target.value);
  };

  const handleTextAreaKeyDown: React.KeyboardEventHandler = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
      feedbackContext.submitFeedback({ includeComment: true });
    }
  };

  const instructionsHeading = InstructionsHeading[feedbackContext.feedbackScope as FeedbackScope];
  const instructionsText =
    'Your feedback is important to help us continually improve our AI models to help you generate better ads. Please do not include personal information.';

  return (
    <Modal
      id={FEEDBACK_COMMENT_MODAL_ID}
      className={styles.feedbackCommentModal}
      contentClassName={styles.modalContent}
      isOpen={props.isOpen}
      hasCloseButton={false}
      footer={<Footer />}
      toggleEl={props.toggleEl}
    >
      <div className={styles.topSection}>
        {feedbackContext.feedbackScope === FeedbackScope.ASSET &&
          (feedbackContext.feedbackMetadata as AssetFeedbackMetadata)?.assetType === AssetType.VIDEO && (
            <video className={styles.imageBox} preload={'metadata'}>
              <source src={feedbackContext.feedbackMetadata?.assetUrl} type="video/mp4" />
            </video>
          )}
        {feedbackContext.feedbackScope === FeedbackScope.ASSET &&
          (feedbackContext.feedbackMetadata as AssetFeedbackMetadata)?.assetType === AssetType.IMAGE && (
            <img className={styles.imageBox} alt="&nbsp;thumbnail of image" src={feedbackContext.feedbackMetadata?.assetUrl} />
          )}
        <div className={styles.instructions}>
          <Text className={styles.instructionsHeading} textColor="base" type="h2">
            {instructionsHeading}
          </Text>
          <Text className={styles.instructionsText} textColor="tertiary" type="p">
            <i>{instructionsText}</i>
          </Text>
        </div>
      </div>
      <TextAreaFormGroup
        id="feedback-comment-modal-textarea"
        className={styles.commentTextArea}
        label=""
        fullWidth={true}
        hideLabel={true}
        onChange={handleTextAreaChange}
        onKeyDown={handleTextAreaKeyDown}
      ></TextAreaFormGroup>
    </Modal>
  );
};
