import { AnchoredAlert, Button, Text } from '@amzn/storm-ui';

interface FeedbackAlertProps {
  userFeedback: string;
  sentiment: string;
  handleAddComment: any;
  handleDismiss: any;
  FeedbackIcon: any;
  position: any;
  style?: any;
}
const FeedbackAlert = (props: FeedbackAlertProps) => {
  const { userFeedback, sentiment, handleAddComment, handleDismiss, FeedbackIcon, position, style } = props;
  const Alert = (props: any) => {
    return (
      <>
        {style ? (
          <AnchoredAlert
            align="center"
            type="blue"
            trigger={<FeedbackIcon userFeedback={userFeedback} />}
            isOpen={userFeedback.toString() === sentiment.toLowerCase()}
            onCloseButtonClick={handleDismiss}
            position={position}
          >
            {props.children}
          </AnchoredAlert>
        ) : (
          <AnchoredAlert
            align="center"
            type="blue"
            trigger={<FeedbackIcon userFeedback={userFeedback} />}
            isOpen={userFeedback.toString() === sentiment.toLowerCase()}
            onCloseButtonClick={handleDismiss}
            position={position}
          >
            {props.children}
          </AnchoredAlert>
        )}
      </>
    );
  };

  return (
    <>
      <Alert>
        <div style={{ width: '300px' }}>
          <Text style={{ color: '#FFFFFF', textAlign: 'initial', fontWeight: '700', fontSize: '15px', lineHeight: '19px' }}>
            {'Thanks for your feedback!'}
          </Text>
          <Text
            style={{
              color: '#FFFFFF',
              textAlign: 'initial',
              marginBottom: '15px',
              marginTop: '5px',
              fontWeight: '400',
              fontSize: '13px',
              lineHeight: '19px',
            }}
            type="p"
          >
            {"We'd love to hear more about your thoughts on this tool. Please help us improve by adding a comment"}
          </Text>
          <Button style={{ color: 'rgb(1, 132, 133)', backgroundColor: '#FFFFFF', float: 'right' }} onClick={handleAddComment} primary>
            Add a comment
          </Button>
          <Button style={{ color: '#FFFFFF', backgroundColor: 'transparent', float: 'right', boxShadow: 'none' }} onClick={handleDismiss} primary>
            Dismiss
          </Button>
        </div>
      </Alert>
    </>
  );
};

export default FeedbackAlert;
