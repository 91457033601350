import Genaihubbackend from '@amzn/genaihub-typescript-client';
import { uploadImage } from 'src/components/utils/uploadImage';
import { StudioContextType } from '../pages/studio/StudioContext';
import { LifestyleImageryWorkflowProps, TextToImageWorkflowProps } from '../pages/studio/WorkflowService';

export const generateLifestyleImageryWorkflowProps = (
  backendHubClient: Genaihubbackend,
  studioContext: StudioContextType,
  effects: string,
  entityId: string | undefined,
  outputCount: number,
  asinImageReferenceId?: string,
): LifestyleImageryWorkflowProps => {
  return {
    client: backendHubClient,
    aspectRatio: studioContext.format,
    effects,
    outputCount: outputCount || 6,
    prompt: studioContext.textPrompt,
    theme: studioContext.style?.value,
    rewriteUserCustomPrompt: studioContext.enablePromptRewriting,
    adsEntityId: entityId,
    asinImageReferenceId,
  } as LifestyleImageryWorkflowProps;
};

export const generateTextToImageWorkflowProps = (
  backendHubClient: Genaihubbackend,
  studioContext: StudioContextType,
  effects: string,
  asinImageRefId: string,
  entityId?: string,
  outputCount?: number,
): TextToImageWorkflowProps => {
  return {
    asinItem: studioContext.asin,
    aspectRatio: studioContext.format,
    client: backendHubClient,
    effects,
    outputCount: outputCount || 6,
    productImage: asinImageRefId,
    prompt: studioContext.textPrompt,
    theme: studioContext.style?.value,
    adsEntityId: entityId,
  } as TextToImageWorkflowProps;
};

export const runBeforeJobSubmission = async (
  workflowId: string,
  workflowOptions: Record<string, { value: number | string; file?: File }>,
  backendClient: Genaihubbackend,
) => {
  if (!workflowId) {
    throw new Error('Workflow ID is required');
  }

  if (workflowId === 'IMAGE_EDITOR' || workflowId === 'PARALLAX_MOTION') {
    if (workflowOptions['mask_ref']) {
      workflowOptions['mask_ref'].value = await uploadImage({
        file: workflowOptions['mask_ref'].file as File,
        backendClient,
        contentCategory: 'MASK_IMAGE',
      });
    }
    workflowOptions['seed'] = {
      value: 47,
    };
    workflowOptions['image_count'] = {
      value: 4,
    };
  }
};
