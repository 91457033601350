import styles from './Progress.module.scss';

interface ProgressProps {
  /**
   * A number between 0 and 1
   */
  progress: number;
}

export const TEST_ID_PROGRESS = 'creative-studio-progress';
export const TEST_ID_PROGRESS_TRACK = 'creative-studio-progress-track';
export const TEST_ID_PROGRESS_BAR = 'creative-studio-progress-bar';

export const Progress = ({ progress }: ProgressProps) => {
  const indeterminate = progress < 0 || progress > 1;

  return (
    <div className={styles.progress} data-testid={TEST_ID_PROGRESS} data-test-progress={progress} data-test-indeterminate={indeterminate}>
      <div className={`${styles.track} ${indeterminate ? styles.indeterminate : ''}`} data-testid={TEST_ID_PROGRESS_TRACK}>
        <div
          className={styles.bar}
          style={{
            ...(!indeterminate ? { width: `${(progress * 100).toFixed(0)}%` } : {}),
          }}
          data-testid={TEST_ID_PROGRESS_BAR}
        />
      </div>
    </div>
  );
};
