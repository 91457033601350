import { Text } from '@amzn/storm-ui';
import { FC } from 'react';
import { ProductThumbnail } from 'src/components/common/storm/ProductThumbnail';
import { formatBytes } from 'src/components/utils/formatBytes';
import { ImageModalContextType } from '../ImageModalContext';
import styles from './ImageModalProductPreview.module.scss';

export const ImageModalProductPreview: FC<ImageModalContextType['productImageDetails']> = (props) => {
  if (!props) return null;
  let details;
  if ('asin' in props) {
    const { asin, title } = props;
    details = (
      <div className={styles.productInfo}>
        <Text className={styles.primary}>{title}</Text>
        <Text fontSize={'mini'} className={styles.secondary}>
          ASIN: {asin}
        </Text>
      </div>
    );
  } else {
    const { name, size } = props;
    details = (
      <div className={`${styles.productInfo} ${styles.center}`}>
        <Text className={styles.primary}>{name}</Text>
        <Text fontSize={'mini'} className={styles.secondary}>
          {formatBytes(size)}
        </Text>
      </div>
    );
  }

  const { url } = props;
  return (
    <div className={styles.productPreview}>
      <ProductThumbnail imageUrl={url} size={70}></ProductThumbnail>
      {details}
    </div>
  );
};
