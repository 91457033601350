import { HelpTip, Icon, Menu, MenuItemButton, Text } from '@amzn/storm-ui';
import { ellipsisH } from '@amzn/storm-ui-icons';
import { Skeleton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { CSSProperties, memo, useCallback, useContext, useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { AppContext } from 'src/../AppContext';
import starsAnimation from 'src/animations/Star C Generating.json';
import AssetContentCard from 'src/components/common/assetGallery/AssetContentCard';
import { FeedbackContext, FeedbackScope, useFeedbackContext } from 'src/components/feedback/FeedbackContext';
import { FeedbackThumbsControl } from 'src/components/feedback/FeedbackThumbsControl';
import { AssetType } from 'src/components/imageModal/components/utils';
import { ContentItem, PlaceholderType, StudioContext, isImportedMediaWorkflow } from 'src/components/pages/studio/StudioContext';
import { DeleteAssetFailureNotification, DeleteAssetProcessingNotification } from 'src/components/snackbar/notifications/DeleteAssetNotifications';
import { SnackbarContext } from 'src/components/snackbar/SnackbarContext';
import { downloadFromUrl } from 'src/components/utils/LinkUtils';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import useAssetImageCache from 'src/hooks/useAssetImageCache';
import useAssetLibrary from 'src/hooks/useAssetLibrary';
import { isSponsoredCampaignLaunched } from 'src/util/weblab/weblab';
import style from './ContentTile.module.scss';
import { ImageUploadPlaceholder } from './ImageUploadPlaceholder';

const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: starsAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const UPLOAD_PLACEHOLDER_FADE_OUT_TIMEOUT = 4000;
export const FADE_OUT_TRANSITION_TIME = 500;
export const UPLOAD_PLACEHOLDER_TOTAL_ANIMATION_TIME = UPLOAD_PLACEHOLDER_FADE_OUT_TIMEOUT + FADE_OUT_TRANSITION_TIME;

const ContentTile = (props: { contentItem: ContentItem; index?: number; onClick?: (currentTab: number) => void }) => {
  const { contentItem, index } = props;
  const {
    aspectRatio,
    contentHeadline,
    contentType,
    isEmptyPlaceholder,
    loading,
    message,
    originalUri,
    referenceId,
    studioInputSettings,
    width,
    workflowId,
    assetType,
    transitionOut,
  } = contentItem;
  const containerInlineStyle: CSSProperties = {};
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFadeOut, setIsFadeOut] = useState<boolean>(false);
  const studioContext = useContext(StudioContext);
  const useAssetLibraryHook = useAssetLibrary();
  const snackbarContext = useContext(SnackbarContext);
  const content = useAssetImageCache(contentItem);
  const client = useAIBackendHubClient();

  const feedbackContext = useFeedbackContext({
    feedbackScope: FeedbackScope.ASSET,
    onSubmittedFeedback: (submittedFeedback) => {
      const updatedContentItem: ContentItem = {
        ...props.contentItem,
        feedback: submittedFeedback.feedback,
      };
      studioContext.updateResult(updatedContentItem);
    },
  });
  const appContext = useContext(AppContext);
  const entityId = appContext?.selectedAdvertisingAccount?.alternateIds?.[0];
  const canSaveAsset = !!(appContext.accountType === 'external' && appContext.selectedAdvertisingAccount);

  useEffect(() => {
    feedbackContext.setFeedbackSentiment(props.contentItem.feedback?.sentiment);
    feedbackContext.setFeedbackComment(props.contentItem.feedback?.comment);
    feedbackContext.setFeedbackMetadata({
      assetUrl: originalUri || '',
      // NOTE: temporarily falling back on contentHeadline for prompt since Feed assets include an incomplete model
      // TODO: only use studioInputSettings.prompt after Feed is fixed to provide studioInputSettings
      prompt: studioInputSettings?.prompt || contentHeadline || '',
      workflowId,
      referenceId,
      assetType: assetType || AssetType.IMAGE,
    });
  }, [props.contentItem]);

  const toggleMenu = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onClickReuseSettingsMenuItem = () => {
    closeMenu();
    if (studioInputSettings) {
      studioContext.handleReuseSettings({
        studioInputSettings,
      });
    }
  };

  const onClickAddToAssetLibrary = async () => {
    closeMenu();
    if (!referenceId) return;
    const response = await client.retrieveAsset({
      id: referenceId,
      entityId: entityId,
    });
    const url = response?.body.asset?.uri || '';
    await useAssetLibraryHook.saveAsset({ assetUrl: url, asin: studioInputSettings?.asin?.asin });
  };

  const onClickEdit = (assetType?: AssetType) => {
    closeMenu();
    handleOpenPreview(assetType === AssetType.VIDEO ? 0 : 1);
  };

  const onClickDeleteImageMenuItem = () => {
    if (canSaveAsset) {
      const cleanupDeleteProcessingNotification = snackbarContext.addProcessingNotification({
        SnackbarContent: DeleteAssetProcessingNotification,
      });
      setIsOpen(false);
      deleteAssetMutation.mutate({ cleanupDeleteProcessingNotification });
    } else {
      setIsOpen(false);
      studioContext.removeResult(referenceId || '');
    }
  };

  const deleteAssetMutation = useMutation({
    mutationFn: () => {
      return client.deleteAsset({
        id: referenceId || '',
        entityId: entityId,
      });
    },
    onSuccess: (_data, { cleanupDeleteProcessingNotification }: { cleanupDeleteProcessingNotification: () => void }) => {
      if (referenceId !== undefined) {
        studioContext.removeResult(referenceId);
      }
      cleanupDeleteProcessingNotification();
    },
    onError: () => {
      snackbarContext.addFailureNotification({ SnackbarContent: DeleteAssetFailureNotification });
    },
  });

  if (width) {
    containerInlineStyle.width = width;
  }
  if (isFadeOut) {
    containerInlineStyle.opacity = '0';
    containerInlineStyle.aspectRatio = '1/0.05';
    containerInlineStyle.transition = `opacity 0.3s ease-in-out, aspect-ratio ${FADE_OUT_TRANSITION_TIME}ms ease-in-out`;
  } else if (aspectRatio) {
    if (aspectRatio) {
      containerInlineStyle.aspectRatio =
        aspectRatio === '3:1' || aspectRatio === '1.91:1' ? '1.5/1' : aspectRatio === '9:16' || aspectRatio === '4:5' ? '1/1.5' : '1/1';
    } else {
      containerInlineStyle.aspectRatio = '1.5/1';
    }
  } else {
    containerInlineStyle.aspectRatio = '1.91/1';
  }

  const handleOpenPreview = (currentTab?: number) => {
    if (content && index !== undefined) {
      props.onClick?.(currentTab ?? 0);
    }
  };

  const downloadAsset = async () => {
    if (content) {
      downloadFromUrl({ url: content, fileName: `ailab-${crypto.randomUUID()}` });
      closeMenu();
    }
  };

  const isImportedMediaWorkflowResult = isImportedMediaWorkflow(contentItem.workflowId);

  const getContentWithImage = () => {
    return (
      <>
        <AssetContentCard
          asset={{
            uri: content || '',
            type: assetType || AssetType.IMAGE,
          }}
          className={style.displayImageContent}
          containerTestId="studio-feed-item-image"
          referenceId={referenceId}
          clickHandler={() => handleOpenPreview()}
        />
        <div className={style.overlay} data-testid="studio-feed-item-overlay">
          <div className={style.shadow} />
          <div className={style.contentHeader}>
            <div className={style.contentHeaderText} data-testid="studio-feed-item-content-type-text">
              {contentType}
            </div>
          </div>
          {contentHeadline && (
            <div className={style.suggestedHeadline}>
              <div className={style.suggestedHeadlineDetails} data-testid="studio-feed-item-headline-text">
                {contentHeadline.length > 145 ? `${contentHeadline.slice(0, 140)}...` : contentHeadline}
              </div>
            </div>
          )}
          <div className={style.topBar}>
            {!isImportedMediaWorkflowResult ? (
              <FeedbackContext.Provider value={feedbackContext}>
                <FeedbackThumbsControl />
              </FeedbackContext.Provider>
            ) : (
              <span />
            )}
            <div className={style.actionsButton}>
              <Menu
                id="example-menu"
                onClick={toggleMenu}
                onClose={closeMenu}
                isOpen={isOpen}
                trigger={<Icon type={ellipsisH} />}
                data-testid="studio-feed-item-menu-toggle"
              >
                <MenuItemButton onClick={onClickAddToAssetLibrary} disabled={!canSaveAsset} data-testid="studio-feed-item-menu-save-button">
                  Save to creative assets
                </MenuItemButton>
                <MenuItemButton onClick={downloadAsset} data-testid="studio-feed-item-menu-download-button">
                  Download
                </MenuItemButton>
                {!!studioInputSettings && (
                  <MenuItemButton onClick={onClickReuseSettingsMenuItem} data-testid="studio-feed-item-menu-reuse-settings-button">
                    Reuse settings
                  </MenuItemButton>
                )}
                <MenuItemButton className={style.edit} onClick={() => onClickEdit(assetType)} data-testid="studio-feed-item-menu-edit-button">
                  {assetType === AssetType.VIDEO ? 'Preview' : 'Edit'}
                </MenuItemButton>
                <MenuItemButton onClick={onClickDeleteImageMenuItem} data-testid="studio-feed-item-menu-delete-button">
                  Delete
                </MenuItemButton>
                <MenuItemButton hidden={!isSponsoredCampaignLaunched()} onClick={() => {}} data-testid="studio-feed-item-menu-start-a-sb-campaign">
                  Start a Sponsored Brands campaign
                </MenuItemButton>
              </Menu>
            </div>
          </div>
        </div>
        <div className={style.overlayNoHover} data-testid="studio-feed-item-overlay-no-hover">
          {isImportedMediaWorkflowResult && (
            <div className={style.uploadBadge}>
              <Text type="span">Uploaded</Text>
            </div>
          )}
        </div>
      </>
    );
  };

  const getContentWithNoImage = () => {
    const icon = loading ? (
      <Lottie style={{ margin: '0px' }} options={defaultLottieOptions} height={50} width={40} />
    ) : message ? (
      <HelpTip>{message}</HelpTip>
    ) : (
      ''
    );
    const texts = loading ? (
      contentType === 'ASIN' ? (
        <>
          <span>Loading </span> product data
        </>
      ) : (
        <>
          <span>Generating </span> {contentType}
        </>
      )
    ) : message ? (
      'Sorry, something went wrong'
    ) : (
      ''
    );
    if (message && !isFadeOut) {
      setTimeout(() => setIsFadeOut(true), 4000);
      setTimeout(() => setIsFadeOut(false), 4500);
    }

    return (
      <>
        {(loading || message) && (
          <div className={`${style.loading} ${contentType === 'lifestyle image' ? style.lifestyleType : style.productType}`} />
        )}
        <div className={style.text} data-testid="studio-feed-item-status-message">
          {icon}
          {texts}
        </div>
      </>
    );
  };

  const getUploadPlaceholder = () => {
    if (transitionOut && !isFadeOut) {
      setTimeout(() => setIsFadeOut(true), UPLOAD_PLACEHOLDER_FADE_OUT_TIMEOUT);
    }
    return <ImageUploadPlaceholder placeholderId={contentItem.placeholderId} placeholderUrl={contentItem.placeholderUrl} />;
  };

  const aspectRatioClassName = style[`as_${aspectRatio}`] ?? style.as_fallback;

  const isUploadPlaceholder = !content && contentItem.placeholderType === PlaceholderType.UPLOAD;

  return (
    <div
      style={containerInlineStyle}
      className={`${style.container} ${aspectRatioClassName} ${isOpen ? style.kebabMenuActive : ''} ${
        feedbackContext.isFeedbackPopoverOpen ? style.feedbackPopoverOpen : ''
      }`}
      data-testid={content ? 'studio-feed-item-asset' : 'studio-feed-item-non-asset'}
      data-test-aspect-ratio={aspectRatio}
    >
      {content && getContentWithImage()}
      {isUploadPlaceholder && getUploadPlaceholder()}
      {!isUploadPlaceholder && getContentWithNoImage()}
      {(isEmptyPlaceholder || (originalUri && !content)) && <Skeleton className={style.placeholder} variant="rounded" animation={'wave'} />}
    </div>
  );
};

export default memo(ContentTile);
