import { Icon } from '@amzn/storm-ui';
import { IconDefinition } from '@amzn/storm-ui-icons';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const style = {
  width: '70px',
  height: '70px',
  gap: '0px',
  opacity: '0.6',
  fontSize: '40px',
  color: '#232F3F',
  cursor: 'pointer',
  background: 'lightgrey',
  alignContent: 'center',
  borderRadius: '50px',
  textAlign: 'center' as const,
  margin: '10px',
};
const ArrowNav = (props: any) => {
  const { dir, visible } = props;
  return (
    <>
      <>
        {dir == 'left' ? (
          <div style={{ ...style, visibility: visible ? 'visible' : 'hidden' }} onClick={() => props.clickHandler('left')}>
            <Icon size={'lg'} type={faChevronLeft as IconDefinition} />
          </div>
        ) : (
          <div style={{ ...style, visibility: visible ? 'visible' : 'hidden' }} onClick={() => props.clickHandler('right')}>
            <Icon size={'lg'} type={faChevronRight as IconDefinition} />
          </div>
        )}
      </>
    </>
  );
};

export default ArrowNav;
