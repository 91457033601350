// TODO: we should move those to an actual theme
import { css } from 'styled-components';

export const CHAT_THEME = {
  // this is non-standard storm spacing, that UX uses.
  spacing: {
    microPlus: '5px',
    miniPlus: '8px',
    smallPlus: '12px',
    basePlus: '16px',
    mediumPlus: '20px',
    largePlus: '28px',
  },
  border: {
    sharp: {
      radius: '3px',
    },
    roundedThin: {
      radius: '11px',
    },
    rounded: {
      radius: '20px',
    },
    roundedExtra: {
      radius: '32px',
    },
  },
  color: {
    aiPurple: '#4C40D6',
    imageShadow: '#00000033',
    assistant: '#FF7453',
    primaryBlue: '#354152',
  },
  size: {
    photo: {
      small: '36px',
      base: '75px',
    },
  },
  font: {
    ember: {
      size: '16px',
    },
  },
  compactScreenBreakPoint: 732,
  maxImageHeight: 279,
};

export const ScrollBar = css`
  // FIXME: ask UX for scroll bar specs
  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 20px;
  }
`;
