import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AnimatedBanner from 'src/images/Banner-Testing-01x2-1440.gif';

interface MainFeatureProps {
  post: {
    title: string;
    subTitle: string;
    description: string;
  };
}

export default function MainFeature(props: MainFeatureProps) {
  const { title, subTitle, description } = props.post;

  return (
    <Box
      sx={{
        position: 'relative',
        paddingTop: 'var(--div-spacing-default)',
        paddingBottom: 'var(--div-spacing-default)',
        backgroundSize: '100% 100% !important',
        background: `url(${AnimatedBanner}) no-repeat`,
      }}
    >
      <div id={'homepage-banner-text'} className={'page-margin'}>
        <Typography component="h1" variant="h1" fontSize={'56px'} color="inherit" gutterBottom sx={{ color: 'white' }}>
          {title}
        </Typography>
        <Typography component="h2" variant="h2" fontWeight={300} color="inherit" gutterBottom sx={{ color: 'white' }}>
          {subTitle}
        </Typography>
        <Typography variant="body1" color="inherit" paragraph>
          {description}
        </Typography>
      </div>
    </Box>
  );
}
