export const FAILURE_TEXT = 'Sorry, something went wrong. Please try again later.';
export const PROCESSING_TEXT = 'This is taking longer than expected. To submit your feedback, wait a little longer or try again later.';
export const SUCCESS_TEXT = 'Feedback submitted';

export const SubmitFeedbackProcessingNotification = () => {
  return <>{PROCESSING_TEXT}</>;
};

export const SubmitFeedbackFailureNotification = () => {
  return <>{FAILURE_TEXT}</>;
};

export const SubmitFeedbackSuccessNotification = () => {
  return <>{SUCCESS_TEXT}</>;
};
