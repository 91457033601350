import { CSSProperties } from 'react';

export enum StandardButtonTypes {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary',
}

export enum StandardButtonSizes {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export enum StandardButtonIcons {
  AddImage = 'Add image',
  Edit = 'Edit',
  Lottie = 'Lottie',
}

export interface StandardButtonProps {
  onClick: () => void;
  label: string;
  type?: StandardButtonTypes;
  size?: StandardButtonSizes;
  fullWidth?: boolean;
  icon?: StandardButtonIcons;
  iconOnly?: boolean;
  iconSize?: string;
  options?: string[];
  dataTestId?: string;
  style?: CSSProperties;
  disabled?: boolean;
}
