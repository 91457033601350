import { useContext } from 'react';
import HeroGenerate from 'src/components/hero/HeroGenerate';
import HeroUnauthenticated from 'src/components/hero/HeroUnauthenticated';
import styles from './Hero.module.scss';
import { AppContext } from '../../../../AppContext';

const Hero = () => {
  const { authenticated } = useContext(AppContext);
  return (
    <div className={`${styles.hero} ${authenticated ? styles.authenticated : ''}`}>{authenticated ? <HeroGenerate /> : <HeroUnauthenticated />}</div>
  );
};
export default Hero;
