import React from 'react';
import styles from './basicTextInputWithSubmit.module.scss';
import SparkleIcon from '../../../../../icons/sparkle.svg';

const BasicTextInputWithSubmit = (props: any) => {
  const disabled: boolean = false;
  const [textValue, setTextValue] = React.useState(true);

  const handleOnChange = (event: any) => {
    setTextValue(event.target.value);
  };

  const clickHandler = () => {
    if (props.clickHandler) {
      props.clickHandler(textValue);
    }
  };

  return (
    <div className={styles.container}>
      <input
        className={styles.inputFieldStyle}
        id="textarea-basic"
        placeholder={props.placeholderText}
        onChange={handleOnChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && props.useReturn) {
            props.clickHandler(textValue);
          }
        }}
        style={{}}
        {...(props.inputTestId ? { 'data-testid': props.inputTestId } : {})}
      ></input>
      <button
        className={styles.generateButton}
        disabled={disabled}
        onClick={clickHandler}
        {...(props.submitButtonTestId ? { 'data-testid': props.submitButtonTestId } : {})}
      >
        <SparkleIcon /> Generate
      </button>
    </div>
  );
};

export default BasicTextInputWithSubmit;
