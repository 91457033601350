import { useSearchParams } from 'react-router-dom';

export const CHAT_ID_PARAM_KEY = 'chatId';
export const NEW_CHAT_PARAM_KEY = 'new';
export default function useConversationId() {
  const [searchParams, setSearchParams] = useSearchParams();
  const conversationId = searchParams.get(CHAT_ID_PARAM_KEY) || NEW_CHAT_PARAM_KEY;
  const setConversationId = (id?: string) => setSearchParams({ [CHAT_ID_PARAM_KEY]: id || NEW_CHAT_PARAM_KEY });
  return { conversationId, setConversationId };
}
