import { Asset, PublishAssetInput, RetrieveAssetInput } from '@amzn/genaihub-typescript-client';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AppContext } from 'src/../AppContext';
import { uploadImage } from 'src/components/utils/uploadImage';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';

export interface ImageToImport {
  aspectRatio: string;
  placeholderId: string;
  file: File;
  onImageImported: (props: { imageToImport: ImageToImport; asset: Asset }) => void;
  onError: (props: { imageToImport: ImageToImport }) => void;
}

export enum ImportStatus {
  QUEUED = 'queued',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface Upload {
  imageToImport: ImageToImport;
  progress: number;
  status: ImportStatus;
  asset?: Asset;
}

interface Uploads {
  [placeholderId: string]: Upload;
}

interface UploadsQueue {
  isProcessing: boolean;
  uploadIds: string[];
}

interface ImportImagesProps {
  imagesToImport: ImageToImport[];
}

interface UpdateUploadProps {
  id: string;
  updates: Partial<Upload>;
}

const CONCURRENT_UPLOADS = 3;

export const useImportImageContext = (props?: {
  initialState?: {
    uploads?: Uploads;
    uploadsQueue?: UploadsQueue;
  };
}) => {
  const [uploads, setUploads] = useState<Uploads>(props?.initialState?.uploads ?? {});
  const [uploadsQueue, setUploadsQueue] = useState<UploadsQueue>(props?.initialState?.uploadsQueue ?? { isProcessing: false, uploadIds: [] });
  const appContext = useContext(AppContext);
  const backendClient = useAIBackendHubClient();

  useEffect(() => {
    const processQueue = async () => {
      if (uploadsQueue.uploadIds.length === 0) return;
      if (uploadsQueue.isProcessing) return;

      const dequeuedUploads = uploadsQueue.uploadIds.slice(0, Math.min(uploadsQueue.uploadIds.length, CONCURRENT_UPLOADS));

      console.log('[processQueue] process uploads', dequeuedUploads);

      setUploadsQueue((currUploadsQueue) => ({
        isProcessing: true,
        uploadIds: currUploadsQueue.uploadIds.slice(dequeuedUploads.length),
      }));

      try {
        const results = await Promise.allSettled(dequeuedUploads.map((uploadId) => importImage({ imageToImport: uploads[uploadId].imageToImport })));
        results.forEach((result, index) => {
          if (result.status === 'rejected') {
            const uploadId = dequeuedUploads[index];
            const upload = uploads[uploadId];
            console.error('Error while processing upload', result.reason);
            updateUpload({
              id: uploadId,
              updates: {
                status: ImportStatus.ERROR,
              },
            });
            upload?.imageToImport.onError({ imageToImport: upload.imageToImport });
          }
        });
      } catch (err) {
        console.error('Error while processing upload queue', err);
      } finally {
        setUploadsQueue((currUploadsQueue) => ({
          isProcessing: false,
          uploadIds: currUploadsQueue.uploadIds,
        }));
      }
    };

    processQueue().catch((err) => console.error('Error while processing upload queue', err));
  }, [uploadsQueue]);

  const updateUpload = ({ id, updates }: UpdateUploadProps) => {
    setUploads((currUploads) => {
      return {
        ...currUploads,
        [id]: {
          ...(currUploads[id] ?? {}),
          ...updates,
        },
      };
    });
  };

  const handleUploadImage = async ({ imageToImport }: { imageToImport: ImageToImport }) => {
    let imageReferenceId;

    imageReferenceId = await uploadImage({
      file: imageToImport.file,
      backendClient,
      contentCategory: 'REFERENCE_IMAGE',
      progressCallback: (value) => {
        updateUpload({
          id: imageToImport.placeholderId,
          updates: {
            progress: value,
          },
        });
      },
    });
    updateUpload({
      id: imageToImport.placeholderId,
      updates: {
        progress: 1,
      },
    });

    return imageReferenceId;
  };

  const handlePublishAsset = async ({ imageToImport, imageReferenceId }: { imageToImport: ImageToImport; imageReferenceId: string }) => {
    const entityId = appContext?.selectedAdvertisingAccount?.alternateIds?.[0];
    const payload: PublishAssetInput = {
      // @ts-ignore
      workflowId: 'IMPORT_IMAGE', //WorkflowId.IMPORT_IMAGE,
      entityId,
      body: {
        feedOptions: {
          aspectRatio: imageToImport.aspectRatio,
          input_image: imageReferenceId,
        },
      },
    };

    const publishRespose = await backendClient.publishAsset(payload);
    let assetId: string;
    if (publishRespose.body?.assetId) {
      assetId = publishRespose.body.assetId;
      // @ts-ignore
    } else if (publishRespose.body?.body?.assetId) {
      // @ts-ignore
      assetId = publishRespose.body.body!.assetId;
    } else {
      throw new Error("Publish asset response missing 'assetId'");
    }

    return assetId;
  };

  const handleRetrieveAsset = async ({ assetId }: { assetId: string }) => {
    const entityId = appContext?.selectedAdvertisingAccount?.alternateIds?.[0];
    const payload: RetrieveAssetInput = {
      id: assetId,
      entityId,
    };

    const retrieveAssetResponse = await backendClient.retrieveAsset(payload);
    const asset = retrieveAssetResponse.body.asset;

    if (!asset) {
      throw new Error(`Failed to retrieve asset with id: '${assetId}'`);
    }

    return asset;
  };

  const importImage = async ({ imageToImport }: { imageToImport: ImageToImport }) => {
    updateUpload({
      id: imageToImport.placeholderId,
      updates: {
        progress: -1,
        status: ImportStatus.PENDING,
      },
    });

    const imageReferenceId = await handleUploadImage({ imageToImport });
    const assetId = await handlePublishAsset({ imageToImport, imageReferenceId });
    const asset = await handleRetrieveAsset({ assetId });

    updateUpload({
      id: imageToImport.placeholderId,
      updates: {
        asset,
        status: ImportStatus.SUCCESS,
      },
    });

    imageToImport.onImageImported({ imageToImport, asset });
  };

  const importImages = ({ imagesToImport }: ImportImagesProps) => {
    const newUploads: Uploads = {};
    const newUploadIdsForQueue: string[] = [];

    // Prepare Uploads
    imagesToImport.forEach((imageToImport) => {
      if (uploads[imageToImport.placeholderId]) {
        console.error(`An upload already exists with id '${imageToImport.placeholderId}'`, imageToImport);
        return;
      }

      newUploads[imageToImport.placeholderId] = {
        imageToImport,
        progress: -1,
        status: ImportStatus.QUEUED,
      };

      newUploadIdsForQueue.push(imageToImport.placeholderId);
    });

    // Add Uploads with Queued status
    setUploads((currUploads) => ({
      ...currUploads,
      ...newUploads,
    }));

    // Enqueue Uploads by ID
    setUploadsQueue((currUploadsQueue) => ({
      isProcessing: currUploadsQueue.isProcessing,
      uploadIds: [...currUploadsQueue.uploadIds, ...newUploadIdsForQueue],
    }));
  };

  const getUpload = (id: string) => uploads?.[id];

  const getUploadsQueueIsProcessing = () => uploadsQueue.isProcessing;

  return {
    getUpload,
    getUploadsQueueIsProcessing,
    importImages,
  };
};

export type ImportImageContextType = ReturnType<typeof useImportImageContext>;
export const ImportImageContext = createContext({} as ImportImageContextType);

export type ImportImageContextProviderProps = {
  children: React.ReactNode;
};

export const ImportImageContextProvider = ({ children }: ImportImageContextProviderProps) => {
  const importImageContext = useImportImageContext();
  return <ImportImageContext.Provider value={importImageContext}>{children}</ImportImageContext.Provider>;
};
