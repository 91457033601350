import { ControlValue, WorkflowListResponseDataItemControlsItem } from '@amzn/genaihub-typescript-client';
import { Card, FileUploadDropZone, InlineMessage, Icon, Text, Modal, Divider } from '@amzn/storm-ui';
import { times } from '@amzn/storm-ui-icons';
import React from 'react';

import { useEffect, useContext, useState, SyntheticEvent } from 'react';
import { AppContext, AppContextState } from 'src/../AppContext';
import Spinner from 'src/components/common/storm/Spinner';
import { EditorContextP1 as EditorContext, EditorContextStateP1 } from 'src/components/editor/EditorContext';
import { ContentItem } from 'src/components/pages/studio/StudioContext';
import { urlToFile } from 'src/components/utils/base64Encode';
import batchStateUpdate from 'src/components/utils/batchStateUpdate';
import guideHelper from 'src/components/utils/guideHelper';
import { uploadImage } from 'src/components/utils/uploadImage';
import Stack from 'src/customUIComponents/Stack';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import ImageIcon from 'src/icons/image.svg';
import { handleStormOuterModalClick } from 'src/util/util';
import { AICS_WEBLAB_FEEDS_IN_PLAYGROUND, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { ImageGrid } from 'src/v2/components/ImageGrid/ImageGrid';
import { StandardButton } from 'src/v2/components/StandardButton/StandardButton';
import { StandardButtonIcons, StandardButtonSizes, StandardButtonTypes } from 'src/v2/components/StandardButton/types';
import { useFeedImages } from 'src/v2/hooks/useFeedImages/useFeedImages';
import styles from './DropZone.module.scss';
import ControlLabel from './UI/ControlLabel';

interface DropZoneProps {
  control: WorkflowListResponseDataItemControlsItem;
  editorContext?: React.Context<EditorContextStateP1>;
  bulkUpdateCallback?: (data: Record<string, unknown>) => void;
}

interface Alert {
  type: 'error' | 'warning' | 'info' | 'success';
  message: string;
}

const DropZone = (props: DropZoneProps) => {
  const appContext: AppContextState = useContext(AppContext);
  const editorContext: EditorContextStateP1 = useContext(props.editorContext || EditorContext);
  const { control, bulkUpdateCallback } = props;
  const [uploaded, setUploaded] = useState<string>('');
  const [alert, setAlert] = useState<Alert[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const backendClient = useAIBackendHubClient();
  const isFeedsInPlaygroundEnabled =
    appContext.activePage === 'playground' && isWeblabInTreatment(AICS_WEBLAB_FEEDS_IN_PLAYGROUND, WeblabTreatment.T1);
  const { assetsNextToken, feedImages, getAssets, isFeedsCallRunning } = useFeedImages();

  useEffect(() => {
    if (uploaded !== 'uploaded') {
      editorContext.setDisableControl(true);
    } else {
      editorContext.setDisableControl(false);
    }
  }, [uploaded]);

  useEffect(() => {
    guideHelper(control.controlValues, editorContext.activateGuide, guideCallback);
  }, [editorContext.activateGuide]);

  const guideCallback = async (guide: ControlValue) => {
    if (guide.guideCustomValue) {
      const fileUrl = new URL(guide.guideCustomValue);
      // add query param to avoid getting cached response
      fileUrl.searchParams.append('v', crypto.randomUUID());
      const file: File = await urlToFile(fileUrl.href);
      prepareUpload(file);
    }
  };

  const handleOnChange = (e: SyntheticEvent, files: File[]) => {
    const dropped = handleDroppedFiles(files);
    setUploaded('');
    if (dropped) {
      prepareUpload(files[0]);
      if (modalOpen) {
        setModalOpen(false);
      }
    }
  };

  const handleDroppedFiles = (files: File[]) => {
    let alertsArray = [];

    if (files.length > 1) {
      alertsArray.push({ type: 'error', message: 'Only 1 file can be uploaded at a time' });
    } else {
      if (files[0].type.indexOf('image') === -1) alertsArray.push({ type: 'warning', message: 'Only image files can be uploaded' });
      // add more error conditions here if need be, and push them into the alerts array
    }
    setAlert(alertsArray as []);

    return !alertsArray.length;
  };

  const prepareUpload = async (file: File) => {
    try {
      if (file !== null) {
        setUploaded('uploading');
        const referenceId = await uploadImage({
          file,
          backendClient,
          contentCategory: 'PRODUCT_IMAGE',
        });
        const controlName = control.controlName;
        const controlData = {
          [controlName]: { value: referenceId, file },
        };
        if (editorContext.activateGuide && bulkUpdateCallback) {
          batchStateUpdate(controlData, bulkUpdateCallback);
        } else {
          editorContext.setWorkFlowOptions({ ...editorContext.workflowOptions, ...controlData });
        }
        setUploaded('uploaded');

        editorContext.setFileUploadEvent({ payload: file, controlName });
        //editorContext.setFileUploadEvent({ payload: file });
      }
    } catch (e) {
      console.log((e as Alert).message);
    }
  };

  const ShowPreview = () => {
    const file = editorContext.workflowOptions[control.controlName]?.file;

    if (file && file.name) {
      const name = file.name;
      const imageUrl = URL.createObjectURL(file);
      const size = Math.round((file.size / 1000000) * 10) / 10;

      return (
        <Card padding={10} className={styles.previewCard}>
          <Stack className={styles.previewContentWrapper}>
            <div className={styles.previewContentImageContainer}>
              <Card className={styles.previewImageCard} padding={0}>
                <img className={styles.previewImage} src={imageUrl} alt={'Upload Image Preview'} />
              </Card>
            </div>
            <div className={styles.previewTextContent}>
              {name}
              <br />
              {size} MB
            </div>
            <Icon
              type={times}
              className={styles.previewCloseIcon}
              onClick={() => {
                const controlData = { ...editorContext.workflowOptions };
                delete controlData[control.controlName];
                editorContext.setWorkFlowOptions({ ...controlData });
                editorContext.setFileUploadEvent(undefined);
                setUploaded('');
                editorContext.setActivateGuide(false);
              }}
            />
          </Stack>
        </Card>
      );
    } else {
      return <></>;
    }
  };

  const handleScroll = (event: React.SyntheticEvent) => {
    const { currentTarget } = event;
    const scrollTotalHeight = currentTarget.scrollHeight;
    const scrollDistance = currentTarget.scrollTop + currentTarget.clientHeight;
    const loadResultsTriggerPoint = scrollTotalHeight - 200;
    if (!isFeedsCallRunning && scrollDistance > loadResultsTriggerPoint) {
      getAssets(assetsNextToken);
    }
  };

  useEffect(() => {
    const el = document.getElementById('drop-zone');
    el && el.setAttribute('style', 'width:100%');
  });
  const ShowLoading = () => (
    <>
      <div role="alert" aria-live="assertive" style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
        <Spinner size="lg" />
      </div>
    </>
  );

  const alerts = () =>
    alert.length > 0 &&
    alert.map((item: Alert, index: number) => (
      <InlineMessage className={styles.inlineError} key={`alert${index}`} messageType={item.type} message={item.message} />
    ));

  const fileUpload = ({ dropZoneId }: { dropZoneId: string }) => (
    <FileUploadDropZone
      accept={['image/png', 'image/jpeg']}
      fullWidth={true}
      dropzoneId={dropZoneId}
      inputId="file-uploader"
      heading={
        <Text type={'h5'} textColor="base">
          Choose image or drag and drop
        </Text>
      }
      onChange={handleOnChange}
      onError={(err) => {
        console.error('File Upload ERROR', err);
        setAlert([{ type: 'error', message: 'File Upload ERROR' }]);
      }}
    />
  );

  const FileUploadControl = () => {
    if (isFeedsInPlaygroundEnabled) {
      return (
        <div id="dropzone-modal">
          <Modal
            onClick={(e) => handleStormOuterModalClick(e, () => setModalOpen(false))}
            className={styles.modal}
            modalElementId="dropzone-modal-add-image"
            isOpen={modalOpen}
            hasCloseButton={false}
            header={
              <div className={styles.modalHeader}>
                <span className={styles.title} data-testid={'dropzone-modal-header-label'}>
                  <ImageIcon />
                  <Text styleAs="h2" fontSize="large" type="h4" textColor="base">
                    Add image
                  </Text>
                </span>
                <StandardButton
                  dataTestId="dropzone-modal-header-cancel"
                  label="Cancel"
                  type={StandardButtonTypes.Tertiary}
                  onClick={() => {
                    setModalOpen(false);
                  }}
                />
              </div>
            }
          >
            <div data-testid="dropzone-modal-content">
              {fileUpload({ dropZoneId: 'drop-zone-playground' })}
              {alerts()}
              <Divider className={styles.modalContentDivider} />
              <Text styleAs="h3" fontSize="medium" type="h3" textColor="base" className={styles.feedHeader}>
                From your images
              </Text>
              <ImageGrid
                scrollHandler={handleScroll}
                componentLabel="studio-feed"
                images={feedImages || []}
                onFeedSelect={async (e: SyntheticEvent, image: ContentItem) => {
                  if (image.originalUri && image.originalUri.startsWith('http')) {
                    const file: File = await urlToFile(image.originalUri, 'image/png');
                    handleOnChange(e, [file]);
                    setModalOpen(false);
                  } else {
                    console.error(`Sandbox - ${appContext.selectedTool}: Invalid file format selected for generation`, image);
                    setModalOpen(false);
                  }
                }}
              />
            </div>
          </Modal>
          <StandardButton
            dataTestId={'add-image-button'}
            label="Add image"
            size={StandardButtonSizes.Medium}
            type={StandardButtonTypes.Secondary}
            icon={StandardButtonIcons.AddImage}
            fullWidth={true}
            onClick={() => {
              setModalOpen(true);
            }}
          />

          {alerts()}
        </div>
      );
    }
    return (
      <>
        {fileUpload({ dropZoneId: 'drop-zone' })}
        {alerts()}
      </>
    );
  };

  return (
    <>
      <ControlLabel title={control.controlLabel} defaultValue={control.defaultValue} />
      <div className={styles.dropZone}>
        <Card className={styles.card} padding={0} style={{ minHeight: isFeedsInPlaygroundEnabled ? 'auto' : '80px' }}>
          {uploaded === 'uploading' ? <ShowLoading /> : uploaded === 'uploaded' ? ShowPreview() : FileUploadControl()}
        </Card>
      </div>
    </>
  );
};

export default DropZone;
