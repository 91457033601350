import { Text } from '@amzn/storm-ui';
import Markdown, { MarkdownToJSX, slugify } from 'markdown-to-jsx';
import styled from 'styled-components';
import { CHAT_THEME } from '../../../theme';

interface Props {
  content: string;
}

const StyledText = styled(Text)`
  font-size: ${CHAT_THEME.font.ember.size};
  font-style: normal;
  line-height: 24px; /* 150% */
`;

const markdownOptions: MarkdownToJSX.Options = {
  // Make sure generate ids don't collide with other page content
  slugify: (text) => '_md_' + slugify(text),
};

export default function TextMessage({ content }: Props) {
  return (
    <StyledText as="div" data-testid={'text-message'}>
      <Markdown options={markdownOptions}>{content}</Markdown>
    </StyledText>
  );
}
