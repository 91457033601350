export default function Info() {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_582_1872)">
        <path
          d="M5.94961 0.974609C9.04242 0.974609 11.5496 3.48181 11.5496 6.57461C11.5496 9.66742 9.04242 12.1746 5.94961 12.1746C2.85681 12.1746 0.349609 9.66742 0.349609 6.57461C0.349609 3.48181 2.85681 0.974609 5.94961 0.974609ZM6.64961 5.52461H5.24961V9.72461C5.24961 10.1112 5.56301 10.4246 5.94961 10.4246C6.33621 10.4246 6.64961 10.1112 6.64961 9.72461V5.52461ZM5.94961 2.37461C5.36971 2.37461 4.89961 2.84471 4.89961 3.42461C4.89961 4.00451 5.36971 4.47461 5.94961 4.47461C6.52951 4.47461 6.99961 4.00451 6.99961 3.42461C6.99961 2.84471 6.52951 2.37461 5.94961 2.37461Z"
          fill="#5C687C"
        />
      </g>
      <defs>
        <clipPath id="clip0_582_1872">
          <rect width="11.9" height="11.9" fill="white" transform="translate(0 0.549805)" />
        </clipPath>
      </defs>
    </svg>
  );
}
