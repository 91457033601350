import { Text } from '@amzn/storm-ui';
import { memo, useContext } from 'react';
import { ContentItem } from 'src/components/pages/studio/StudioContext';
import { Progress } from 'src/v2/components/common/progress/Progress';
import { ImportImageContext, ImportStatus } from 'src/v2/contexts/ImportImageContext';
import style from './ContentTile.module.scss';

interface ImageUploadPlaceholderProps {
  placeholderId: ContentItem['placeholderId'];
  placeholderUrl: ContentItem['placeholderUrl'];
}

export const TEST_ID_UPLOAD_PLACEHOLDER_IMAGE = 'upload-placeholder-image';
export const TEST_ID_UPLOAD_PLACEHOLDER_OVERLAY = 'upload-placeholder-overlay';
export const TEST_ID_UPLOAD_PLACEHOLDER_BADGE = 'upload-placeholder-badge';

export const IMPORTING_BADGE_LABEL = 'Importing Image';
export const ERROR_BADGE_LABEL = 'Error';

export const ImageUploadPlaceholder = memo(({ placeholderId, placeholderUrl }: ImageUploadPlaceholderProps) => {
  const importImageContext = useContext(ImportImageContext);
  const upload = placeholderId ? importImageContext.getUpload(placeholderId) : undefined;
  let progress = -1;
  switch (upload?.status) {
    case ImportStatus.QUEUED:
      progress = -1;
      break;
    case ImportStatus.PENDING:
      progress = upload.progress >= 0 ? upload.progress * 0.9 : -1;
      break;
    case ImportStatus.ERROR:
    case ImportStatus.SUCCESS:
      progress = 1;
      break;
  }

  const badgeLabel = upload?.status === ImportStatus.ERROR ? ERROR_BADGE_LABEL : IMPORTING_BADGE_LABEL;

  return (
    <>
      <img className={style.uploadPlaceholderImage} src={placeholderUrl} data-testid={TEST_ID_UPLOAD_PLACEHOLDER_IMAGE} />
      <div className={style.uploadPlaceholderOverlay} data-testid={TEST_ID_UPLOAD_PLACEHOLDER_OVERLAY}>
        <div className={style.uploadBadge} data-testid={TEST_ID_UPLOAD_PLACEHOLDER_BADGE}>
          <Text type="span">{badgeLabel}</Text>
        </div>
        <Progress progress={progress} />
      </div>
    </>
  );
});
ImageUploadPlaceholder.displayName = 'ImageUploadPlaceholder';
