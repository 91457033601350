import { Button, Icon } from '@amzn/storm-ui';
import { download } from '@amzn/storm-ui-icons';
import { downloadFromUrl } from 'src/components/utils/LinkUtils';

export const DOWNLOAD_IMAGE_BUTTON_TEST_ID = 'download-image-button';
export interface DownloadImageButtonProps {
  url: string;
  disabled?: boolean;
}

export const DownloadImageButton = ({ url, disabled }: DownloadImageButtonProps) => {
  return (
    <>
      <Button
        disabled={disabled}
        onClick={() => !disabled && downloadFromUrl({ url, fileName: `ailab-${crypto.randomUUID()}` })}
        data-testid={DOWNLOAD_IMAGE_BUTTON_TEST_ID}
      >
        <Icon type={download} /> Download
      </Button>
    </>
  );
};
