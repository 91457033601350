import { TabbedNavigation as _TabbedNavigation } from '@amzn/storm-ui';
import { ComponentProps, FC } from 'react';
import styles from './TabbedNavigation.module.scss';

export const TabbedNavigation: FC<ComponentProps<typeof _TabbedNavigation>> = (props) => {
  return (
    <div className={styles.tabbedNavigation}>
      <_TabbedNavigation {...props} />
    </div>
  );
};
