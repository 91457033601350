import { FileUploadButton } from '@amzn/storm-ui';
import { useEffect, useState } from 'react';
import { uploadImage } from 'src/components/utils/uploadImage';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';

const ProductImageUpload = ({ control, appendControlValues }: any) => {
  const [file, setFile] = useState<File | null>(null);
  const backendClient = useAIBackendHubClient();

  useEffect(() => {
    if (file != null) {
      const referenceId = uploadImage({
        file,
        backendClient,
        contentCategory: 'PRODUCT_IMAGE',
      });
      const controlName = control.controlName;
      const controlData = {
        [controlName]: referenceId,
      };
      appendControlValues(controlData);
    }
  }, [file]);

  const handleOnChange = (e: any) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <>
      <h3>{control.controlLabel}</h3>
      <FileUploadButton inputId="file-uploader" label="Upload file" onChange={handleOnChange} />
    </>
  );
};

export default ProductImageUpload;
