import { Chip, Stack } from '@mui/material';

const Chip_ = ({ control }: any) => {
  // Metrics to be added
  return (
    <>
      {control.visible && (
        <>
          <Stack spacing={1} direction={'row'} marginTop={2}>
            {control.controlValues &&
              control.controlValues.map((item: any, index: number) => {
                return <Chip key={'chip' + index} label={item.label} size="small" clickable color="primary" />;
              })}
          </Stack>
        </>
      )}
    </>
  );
};

export default Chip_;
