import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher';

/**
 * Enum representing the different statuses of Dwell Time Metric Publisher.
 */
enum TimerStatus {
  YET_TO_START,
  PAUSED,
  RUNNING,
  STOPPED,
}

/**
 * This publisher can be used to calculate total time taken for a given action when there is need to pause and resume
 * the timer depending on certain events. If pause and resume functionality is not required, one can directly use the
 * {@link KatalMetricTimerStopwatch}.
 *
 * The timer doesn't start by itself on initialization. You need to call either start or resume to start the timer.
 */
class DwellTimePublisher {
  private readonly metricName: string;
  private metricsPublisher: KatalMetricsPublisher;

  private totalTime: number;
  private startTime?: number;
  private stopTime?: number;
  private lastResumedTime?: number;
  private timerStatus: TimerStatus;

  constructor(metricsPublisher: KatalMetricsPublisher, metricName: string) {
    this.metricsPublisher = metricsPublisher;
    this.metricName = metricName;
    this.totalTime = 0;
    this.timerStatus = TimerStatus.YET_TO_START;
  }

  start = (): void => {
    if (this.timerStatus === TimerStatus.YET_TO_START) {
      this.startTime = DwellTimePublisher.now();
      this.lastResumedTime = this.startTime;
      this.timerStatus = TimerStatus.RUNNING;
    }
  };

  stop = (): void => {
    this.stopTime = DwellTimePublisher.now();
    if (this.timerStatus === TimerStatus.RUNNING && this.lastResumedTime) {
      this.totalTime += this.stopTime - this.lastResumedTime;
    }
    this.timerStatus = TimerStatus.STOPPED;
  };

  pause = (): void => {
    if (this.timerStatus === TimerStatus.RUNNING && this.lastResumedTime) {
      this.totalTime += DwellTimePublisher.now() - this.lastResumedTime;
      this.timerStatus = TimerStatus.PAUSED;
    }
  };

  resume = (): void => {
    if (this.timerStatus === TimerStatus.YET_TO_START) {
      this.start();
    } else if (this.timerStatus === TimerStatus.PAUSED) {
      this.lastResumedTime = DwellTimePublisher.now();
      this.timerStatus = TimerStatus.RUNNING;
    }
  };

  withMetics = (metrics: KatalMetricsPublisher): DwellTimePublisher => {
    this.metricsPublisher = metrics;
    return this;
  };

  publish = (): void => {
    if (this.timerStatus !== TimerStatus.STOPPED) {
      this.stop();
    }
    this.metricsPublisher.publishTimerMonitor(this.metricName, Math.round(this.totalTime));
  };

  static now() {
    return performance.now();
  }
}

export default DwellTimePublisher;
