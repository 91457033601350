import { CSSProperties } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../_common/buttons/PrimaryButton';

export const SIGNIN_LINK = `/signin`;
export const SIGNIN_TEXT = 'Login with Amazon';

export const signinClickHandler = () => {
  window.location.href = SIGNIN_LINK;
};

const Button = styled(PrimaryButton)`
  && {
    width: 170px;
    height: 42px;
    font-size: 15px;
    background: linear-gradient(90deg, #4305f4 0%, #8631ee 100%);
    background: white;
    padding: 0 12px;
    border: 2px solid #4305f4;
    border-radius: 50px;
    color: #4305f4;
  }
`;

const SignInButton = ({ text, className, style }: { text?: string; className?: string; style?: CSSProperties }) => {
  return (
    <Button style={style} className={className} onClick={signinClickHandler}>
      {text ? text : SIGNIN_TEXT}
    </Button>
  );
};

export default SignInButton;
