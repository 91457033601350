export enum WeblabTreatment {
  C = 'C',
  T1 = 'T1',
  T2 = 'T2',
}

export interface Weblab {
  name: string;
  treatment: WeblabTreatment;
}

export const AICS_WEBLAB_SPLIT_GENERATE_BUTTON = 'AICS_1122014';
export const AICS_WEBLAB_FEEDS_DEACTIVATION = 'AICS_1075347';
export const AICS_WEBLAB_EDIT_TAB_POSITION_EDIT = 'AICS_1095436';
export const AICS_WEBLAB_SELECTOR_FOR_SPONSOR_BRAND = 'AICS_1109632';
export const AICS_WEBLAB_FEEDS_IN_PLAYGROUND = 'AICS_1121243';
export const AICS_WEBLAB_LIVE_IMAGE_EDIT = 'AICS_1114141';
// Feature: Bring Your Own Image
// - allow users to upload an image to the Feed from their computer
export const AICS_WEBLAB_BRING_YOUR_OWN_IMAGE = 'AICS_1105868';

// Valid groups AI_LAB_1061066: T1, AI_LAB_997080: T1, AICS_1055830: T1
export const CLOSED_BETA_TREATMENT_GROUPS: Weblab[] = [
  { name: 'AI_LAB_1061066', treatment: WeblabTreatment.T1 },
  { name: 'AI_LAB_1027305', treatment: WeblabTreatment.T1 },
  { name: 'AI_LAB_997080', treatment: WeblabTreatment.T1 },
  { name: 'AI_LAB_1072131', treatment: WeblabTreatment.T1 },
  { name: 'AI_LAB_1073705', treatment: WeblabTreatment.T1 },
  { name: 'AI_LAB_1073743', treatment: WeblabTreatment.T1 },
  { name: 'AI_LAB_1073744', treatment: WeblabTreatment.T1 },
  { name: 'AI_LAB_1073745', treatment: WeblabTreatment.T1 },
  { name: 'AI_LAB_1073747', treatment: WeblabTreatment.T1 },
  { name: 'AI_LAB_1073746', treatment: WeblabTreatment.T1 },
];

export const CREATIVE_AGENT_ACCESS_CONTROL_LIST: Weblab[] = [{ name: 'RCS_MONS_AUTH_1072881', treatment: WeblabTreatment.T1 }];
