import { createTheme } from '@mui/material';
import styleGuide from './styleGuide';

const defaultTheme = createTheme({
  palette: {
    primary: { main: styleGuide.buttonBackground.primary },
    secondary: { main: styleGuide.buttonBackground.secondary },
    background: {
      default: 'white',
    },
  },
  typography: {
    fontFamily: ['Amazon Ember', '"Helvetica Neue"', 'Arial'].join(','),
    h6: {
      fontFamily: 'Amazon Ember',
      color: 'var(--Text-Base, #232F3F)',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '150%',
      textTransform: 'capitalize',
    },
    h5: {
      fontFamily: 'Amazon Ember',
      color: 'black',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    h4: {
      fontFamily: 'Amazon Ember',
      color: 'black',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    h3: {
      fontFamily: 'Amazon Ember',
      color: 'black',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    h2: {
      fontFamily: 'Amazon Ember',
      color: 'white',
      fontSize: '28px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '44px',
    },
    h1: {
      fontFamily: 'Amazon Ember',
      color: 'white',
      fontSize: '36px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '44px',
    },
    body1: {
      fontFamily: 'Amazon Ember',
      color: 'black',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '21px',
    },
    body2: {
      fontFamily: 'Amazon Ember',
      color: 'black',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '15px',
    },
    subtitle1: {
      fontFamily: 'Amazon Ember Monospace',
      color: '#FFF',
      fontSize: '35px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    subtitle2: {
      color: '#FFF',
      fontFamily: 'Amazon Ember',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineWeight: 'normal',
    },
  },
  components: {
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: '16px',
          fontFamily: 'Amazon Ember',
          textTransform: 'capitalize',
          minWidth: '100px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '0px',
          fontSize: '14px',
          color: '#5C687C',
          '&.Mui-focused': {
            color: 'white',
          },
        },
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          // Some CSS
          color: 'black',
          borderRadius: '16px',
          fontFamily: 'Amazon Ember',
          textTransform: 'capitalize',
        },
      },
    },
  },
});

export default defaultTheme;
