import { useState } from 'react';
import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import styles from './TileSelector.module.scss';

interface TileSelectorOption {
  label: string;
  value: string;
  thumbnail: string;
}
export interface TileSelectorProps {
  title: string;
  options: TileSelectorOption[];
  onChange?: (value: string) => void;
  defaultValue?: string;
  disabled?: boolean;
}
const TileSelector = (props: TileSelectorProps) => {
  const [selected, setSelected] = useState<string | undefined>(props.defaultValue);

  const handleClick = (value: string) => {
    setSelected(value);
    props.onChange && props.onChange(value);
  };

  return (
    <>
      <ControlLabel title={props.title} />
      <div className={styles.tileWrapper}>
        {props.options.map((option: TileSelectorOption, index: number) => (
          <div
            key={`tile-${index}`}
            data-testid="tile-list"
            className={styles.tile}
            onClick={() => handleClick(option.value)}
            style={{
              cursor: props.disabled ? 'not-allowed' : 'pointer',
              border: selected === option.value ? '1.5px solid #4305F4' : 'none',
            }}
          >
            <img src={option.thumbnail} className={styles.thumbnail} alt={option.label} />
            <div className={styles.label}>{option.label}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default TileSelector;
