export const ElementShadeOverlay = (props: { className?: string; visibility?: boolean }) => {
  return (
    <div
      className={`imageListItemOverlay ${props.className}`}
      style={{
        position: 'absolute',
        visibility: props.visibility ? 'visible' : 'hidden',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        borderRadius: '13px',
        backgroundColor: 'black',
        opacity: '0.39',
        zIndex: 2,
      }}
    ></div>
  );
};

export const ElementShadeOverlayAlways = (props: { className?: string }) => {
  return (
    <div
      className={`imageListItemOverlay ${props.className}`}
      style={{
        position: 'absolute',
        visibility: 'visible',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        borderRadius: '10px',
        backgroundColor: 'black',
        opacity: '0.20',
        // zIndex: 2
      }}
    ></div>
  );
};
