import { EffectsEnum, EffectSettings } from 'src/components/pages/studio/effectSettings/effectSettings';
import { ContentItem } from 'src/components/pages/studio/StudioContext';
import { AutumnKitchenStyle } from './styles/AutumnKitchenStyle';
const origin = window.location.origin;
const mockAssetsFolder = `${origin}/assets/mock`;

const Effects = () =>
  new Map([
    ['Lighting', EffectSettings[EffectsEnum.Lighting].options[1]],
    ['Camera', EffectSettings[EffectsEnum.Camera].options[1]],
    ['Color', EffectSettings[EffectsEnum.Color].options[1]],
  ]);

export const MockGenerationResult = (): ContentItem[] => [
  {
    content: `${mockAssetsFolder}/mock.5a285aad-686c-4fbe-ad15-b0bd97609b54.png`,
    aspectRatio: '1:1',
    loading: false,
    contentHeadline: 'A field with puppies',
    contentType: 'Product image',
    studioInputSettings: {
      asin: {
        asin: 'B08PZJN7BD',
        metadata: {
          description:
            'Air Pods To The Max\n\nAirPods Max reimagine over-ear headphones.\n\n An Apple-designed dynamic driver provides immersive high-fidelity audio.\n\n Every detail, from canopy to cushions, has been designed for an exceptional fit.\n\n Industry-leading Active Noise Cancellation blocks outside noise, while Transparency mode lets it in. And spatial audio with dynamic head tracking provides theater-like sound that surrounds you.﻿',
          dimensions: '',
          featureBullets: [
            'BREATHTAKING AUDIO QUALITY — Apple-designed dynamic driver provides high-fidelity audio. Computational audio combines custom acoustic design with the Apple H1 chip and software for breakthrough listening experiences.',
            'FOCUS ON WHAT’S PLAYING — Active Noise Cancellation blocks outside noise so you can immerse yourself in music.',
            'HEAR THE WORLD AROUND YOU — Transparency mode lets you hear and interact with the world around you.',
            'PERSONALIZED SPATIAL AUDIO — With sound that suits your unique ear shape along with dynamic head tracking, AirPods Max deliver an immersive listening experience that places sound all around you. You can also listen to select songs, shows, and movies in Dolby Atmos.',
            'ACOUSTIC-FIRST DESIGN — Designed with a knit-mesh canopy and memory foam ear cushions for an exceptional over-ear fit that perfectly seals in sound.',
            'MAGICAL EXPERIENCE — Pair AirPods Max by simply placing them near your device and tapping Connect on your screen. AirPods Max pause audio when you take them off. And Automatic Switching makes listening between your iPhone, iPad, and Mac completely effortless.',
            'LONG BATTERY LIFE — Up to 20 hours of listening, movie watching, or talk time with Active Noise Cancellation and Personalized Spatial Audio enabled.',
            'PRECISION CONTROL — Use the Digital Crown to play and pause music, to answer, end, and mute yourself on calls, and to control volume and skip between tracks.',
            'A SMARTER CASE — Store in an ultra low-power state with the slim Smart Case.',
            'LEGAL DISCLAIMERS — This is a summary of the main product features. See “Additional information” to learn more.',
          ],
          glGroup: 'gl_wireless',
          mediaCentralAssets: [
            {
              highResUri: 'https://m.media-amazon.com/images/I/81jkMpNHVsL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41uClassuxL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/81setQtwhcL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41f-pR+bBmL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/71fxJGkpHRL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/31MwaKd8qBL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/71Ke0W0H8gL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41-TVCIWfVL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/81mT7xT5FyL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41bsZu8C8GL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/71GWalfImQL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41gDL631dNL.jpg',
            },
          ],
          title:
            'Apple AirPods Max Wireless Over-Ear Headphones, Active Noise Cancelling, Transparency Mode, Personalized Spatial Audio, Dolby Atmos, Bluetooth Headphones for iPhone – Sky Blue',
          type: 'HEADPHONES',
        },
        selectedImageIndex: 0,
      },
      effects: Effects(),
      enablePromptRewriting: true,
      format: '1:1',
      prompt: 'A field with puppies',
      style: AutumnKitchenStyle(),
      advanced: {
        negativePrompt: 'Bad quality',
        seed: '1234',
        temperature: 0.9,
      },
    },
  },
  {
    content: `${mockAssetsFolder}/mock.4fe306e8-677c-4efe-8294-b80cc20acb73.png`,
    aspectRatio: '1:1',
    loading: false,
    contentHeadline: 'A field with puppies',
    contentType: 'Product image',
    studioInputSettings: {
      asin: {
        asin: 'B08PZJN7BD',
        metadata: {
          description:
            'Air Pods To The Max\n\nAirPods Max reimagine over-ear headphones.\n\n An Apple-designed dynamic driver provides immersive high-fidelity audio.\n\n Every detail, from canopy to cushions, has been designed for an exceptional fit.\n\n Industry-leading Active Noise Cancellation blocks outside noise, while Transparency mode lets it in. And spatial audio with dynamic head tracking provides theater-like sound that surrounds you.﻿',
          dimensions: '',
          featureBullets: [
            'BREATHTAKING AUDIO QUALITY — Apple-designed dynamic driver provides high-fidelity audio. Computational audio combines custom acoustic design with the Apple H1 chip and software for breakthrough listening experiences.',
            'FOCUS ON WHAT’S PLAYING — Active Noise Cancellation blocks outside noise so you can immerse yourself in music.',
            'HEAR THE WORLD AROUND YOU — Transparency mode lets you hear and interact with the world around you.',
            'PERSONALIZED SPATIAL AUDIO — With sound that suits your unique ear shape along with dynamic head tracking, AirPods Max deliver an immersive listening experience that places sound all around you. You can also listen to select songs, shows, and movies in Dolby Atmos.',
            'ACOUSTIC-FIRST DESIGN — Designed with a knit-mesh canopy and memory foam ear cushions for an exceptional over-ear fit that perfectly seals in sound.',
            'MAGICAL EXPERIENCE — Pair AirPods Max by simply placing them near your device and tapping Connect on your screen. AirPods Max pause audio when you take them off. And Automatic Switching makes listening between your iPhone, iPad, and Mac completely effortless.',
            'LONG BATTERY LIFE — Up to 20 hours of listening, movie watching, or talk time with Active Noise Cancellation and Personalized Spatial Audio enabled.',
            'PRECISION CONTROL — Use the Digital Crown to play and pause music, to answer, end, and mute yourself on calls, and to control volume and skip between tracks.',
            'A SMARTER CASE — Store in an ultra low-power state with the slim Smart Case.',
            'LEGAL DISCLAIMERS — This is a summary of the main product features. See “Additional information” to learn more.',
          ],
          glGroup: 'gl_wireless',
          mediaCentralAssets: [
            {
              highResUri: 'https://m.media-amazon.com/images/I/81jkMpNHVsL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41uClassuxL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/81setQtwhcL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41f-pR+bBmL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/71fxJGkpHRL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/31MwaKd8qBL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/71Ke0W0H8gL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41-TVCIWfVL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/81mT7xT5FyL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41bsZu8C8GL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/71GWalfImQL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41gDL631dNL.jpg',
            },
          ],
          title:
            'Apple AirPods Max Wireless Over-Ear Headphones, Active Noise Cancelling, Transparency Mode, Personalized Spatial Audio, Dolby Atmos, Bluetooth Headphones for iPhone – Sky Blue',
          type: 'HEADPHONES',
        },
        selectedImageIndex: 0,
      },
      effects: Effects(),
      enablePromptRewriting: true,
      format: '1:1',
      prompt: 'A field with puppies',
      style: AutumnKitchenStyle(),
      advanced: {
        negativePrompt: 'Bad quality',
        seed: '1234',
        temperature: 0.9,
      },
    },
  },
  {
    content: `${mockAssetsFolder}/mock.dd6dc191-475e-4fa1-a202-7068b79811ee.png`,
    aspectRatio: '1:1',
    loading: false,
    contentHeadline: 'A field with puppies',
    contentType: 'Product image',
    studioInputSettings: {
      asin: {
        asin: 'B08PZJN7BD',
        metadata: {
          description:
            'Air Pods To The Max\n\nAirPods Max reimagine over-ear headphones.\n\n An Apple-designed dynamic driver provides immersive high-fidelity audio.\n\n Every detail, from canopy to cushions, has been designed for an exceptional fit.\n\n Industry-leading Active Noise Cancellation blocks outside noise, while Transparency mode lets it in. And spatial audio with dynamic head tracking provides theater-like sound that surrounds you.﻿',
          dimensions: '',
          featureBullets: [
            'BREATHTAKING AUDIO QUALITY — Apple-designed dynamic driver provides high-fidelity audio. Computational audio combines custom acoustic design with the Apple H1 chip and software for breakthrough listening experiences.',
            'FOCUS ON WHAT’S PLAYING — Active Noise Cancellation blocks outside noise so you can immerse yourself in music.',
            'HEAR THE WORLD AROUND YOU — Transparency mode lets you hear and interact with the world around you.',
            'PERSONALIZED SPATIAL AUDIO — With sound that suits your unique ear shape along with dynamic head tracking, AirPods Max deliver an immersive listening experience that places sound all around you. You can also listen to select songs, shows, and movies in Dolby Atmos.',
            'ACOUSTIC-FIRST DESIGN — Designed with a knit-mesh canopy and memory foam ear cushions for an exceptional over-ear fit that perfectly seals in sound.',
            'MAGICAL EXPERIENCE — Pair AirPods Max by simply placing them near your device and tapping Connect on your screen. AirPods Max pause audio when you take them off. And Automatic Switching makes listening between your iPhone, iPad, and Mac completely effortless.',
            'LONG BATTERY LIFE — Up to 20 hours of listening, movie watching, or talk time with Active Noise Cancellation and Personalized Spatial Audio enabled.',
            'PRECISION CONTROL — Use the Digital Crown to play and pause music, to answer, end, and mute yourself on calls, and to control volume and skip between tracks.',
            'A SMARTER CASE — Store in an ultra low-power state with the slim Smart Case.',
            'LEGAL DISCLAIMERS — This is a summary of the main product features. See “Additional information” to learn more.',
          ],
          glGroup: 'gl_wireless',
          mediaCentralAssets: [
            {
              highResUri: 'https://m.media-amazon.com/images/I/81jkMpNHVsL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41uClassuxL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/81setQtwhcL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41f-pR+bBmL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/71fxJGkpHRL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/31MwaKd8qBL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/71Ke0W0H8gL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41-TVCIWfVL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/81mT7xT5FyL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41bsZu8C8GL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/71GWalfImQL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41gDL631dNL.jpg',
            },
          ],
          title:
            'Apple AirPods Max Wireless Over-Ear Headphones, Active Noise Cancelling, Transparency Mode, Personalized Spatial Audio, Dolby Atmos, Bluetooth Headphones for iPhone – Sky Blue',
          type: 'HEADPHONES',
        },
        selectedImageIndex: 0,
      },
      effects: Effects(),
      enablePromptRewriting: true,
      format: '1:1',
      prompt: 'A field with puppies',
      style: AutumnKitchenStyle(),
      advanced: {
        negativePrompt: 'Bad quality',
        seed: '1234',
        temperature: 0.9,
      },
    },
  },
  {
    content: `${mockAssetsFolder}/mock.914295a1-9c7a-4bd5-b7bd-537da19ad756.png`,
    aspectRatio: '1:1',
    loading: false,
    contentHeadline: 'A field with puppies',
    contentType: 'Lifestyle image',
    studioInputSettings: {
      asin: {
        asin: 'B08PZJN7BD',
        metadata: {
          description:
            'Air Pods To The Max\n\nAirPods Max reimagine over-ear headphones.\n\n An Apple-designed dynamic driver provides immersive high-fidelity audio.\n\n Every detail, from canopy to cushions, has been designed for an exceptional fit.\n\n Industry-leading Active Noise Cancellation blocks outside noise, while Transparency mode lets it in. And spatial audio with dynamic head tracking provides theater-like sound that surrounds you.﻿',
          dimensions: '',
          featureBullets: [
            'BREATHTAKING AUDIO QUALITY — Apple-designed dynamic driver provides high-fidelity audio. Computational audio combines custom acoustic design with the Apple H1 chip and software for breakthrough listening experiences.',
            'FOCUS ON WHAT’S PLAYING — Active Noise Cancellation blocks outside noise so you can immerse yourself in music.',
            'HEAR THE WORLD AROUND YOU — Transparency mode lets you hear and interact with the world around you.',
            'PERSONALIZED SPATIAL AUDIO — With sound that suits your unique ear shape along with dynamic head tracking, AirPods Max deliver an immersive listening experience that places sound all around you. You can also listen to select songs, shows, and movies in Dolby Atmos.',
            'ACOUSTIC-FIRST DESIGN — Designed with a knit-mesh canopy and memory foam ear cushions for an exceptional over-ear fit that perfectly seals in sound.',
            'MAGICAL EXPERIENCE — Pair AirPods Max by simply placing them near your device and tapping Connect on your screen. AirPods Max pause audio when you take them off. And Automatic Switching makes listening between your iPhone, iPad, and Mac completely effortless.',
            'LONG BATTERY LIFE — Up to 20 hours of listening, movie watching, or talk time with Active Noise Cancellation and Personalized Spatial Audio enabled.',
            'PRECISION CONTROL — Use the Digital Crown to play and pause music, to answer, end, and mute yourself on calls, and to control volume and skip between tracks.',
            'A SMARTER CASE — Store in an ultra low-power state with the slim Smart Case.',
            'LEGAL DISCLAIMERS — This is a summary of the main product features. See “Additional information” to learn more.',
          ],
          glGroup: 'gl_wireless',
          mediaCentralAssets: [
            {
              highResUri: 'https://m.media-amazon.com/images/I/81jkMpNHVsL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41uClassuxL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/81setQtwhcL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41f-pR+bBmL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/71fxJGkpHRL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/31MwaKd8qBL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/71Ke0W0H8gL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41-TVCIWfVL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/81mT7xT5FyL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41bsZu8C8GL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/71GWalfImQL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41gDL631dNL.jpg',
            },
          ],
          title:
            'Apple AirPods Max Wireless Over-Ear Headphones, Active Noise Cancelling, Transparency Mode, Personalized Spatial Audio, Dolby Atmos, Bluetooth Headphones for iPhone – Sky Blue',
          type: 'HEADPHONES',
        },
        selectedImageIndex: 0,
      },
      effects: Effects(),
      enablePromptRewriting: true,
      format: '1:1',
      prompt: 'A field with puppies',
      style: AutumnKitchenStyle(),
      advanced: {
        negativePrompt: 'Bad quality',
        seed: '1234',
        temperature: 0.9,
      },
    },
  },
  {
    content: `${mockAssetsFolder}/mock.a69dfc48-fcb8-40c5-94ce-3925804b6fcd.png`,
    aspectRatio: '1:1',
    loading: false,
    contentHeadline: 'A field with puppies',
    contentType: 'Lifestyle image',
    studioInputSettings: {
      asin: {
        asin: 'B08PZJN7BD',
        metadata: {
          description:
            'Air Pods To The Max\n\nAirPods Max reimagine over-ear headphones.\n\n An Apple-designed dynamic driver provides immersive high-fidelity audio.\n\n Every detail, from canopy to cushions, has been designed for an exceptional fit.\n\n Industry-leading Active Noise Cancellation blocks outside noise, while Transparency mode lets it in. And spatial audio with dynamic head tracking provides theater-like sound that surrounds you.﻿',
          dimensions: '',
          featureBullets: [
            'BREATHTAKING AUDIO QUALITY — Apple-designed dynamic driver provides high-fidelity audio. Computational audio combines custom acoustic design with the Apple H1 chip and software for breakthrough listening experiences.',
            'FOCUS ON WHAT’S PLAYING — Active Noise Cancellation blocks outside noise so you can immerse yourself in music.',
            'HEAR THE WORLD AROUND YOU — Transparency mode lets you hear and interact with the world around you.',
            'PERSONALIZED SPATIAL AUDIO — With sound that suits your unique ear shape along with dynamic head tracking, AirPods Max deliver an immersive listening experience that places sound all around you. You can also listen to select songs, shows, and movies in Dolby Atmos.',
            'ACOUSTIC-FIRST DESIGN — Designed with a knit-mesh canopy and memory foam ear cushions for an exceptional over-ear fit that perfectly seals in sound.',
            'MAGICAL EXPERIENCE — Pair AirPods Max by simply placing them near your device and tapping Connect on your screen. AirPods Max pause audio when you take them off. And Automatic Switching makes listening between your iPhone, iPad, and Mac completely effortless.',
            'LONG BATTERY LIFE — Up to 20 hours of listening, movie watching, or talk time with Active Noise Cancellation and Personalized Spatial Audio enabled.',
            'PRECISION CONTROL — Use the Digital Crown to play and pause music, to answer, end, and mute yourself on calls, and to control volume and skip between tracks.',
            'A SMARTER CASE — Store in an ultra low-power state with the slim Smart Case.',
            'LEGAL DISCLAIMERS — This is a summary of the main product features. See “Additional information” to learn more.',
          ],
          glGroup: 'gl_wireless',
          mediaCentralAssets: [
            {
              highResUri: 'https://m.media-amazon.com/images/I/81jkMpNHVsL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41uClassuxL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/81setQtwhcL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41f-pR+bBmL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/71fxJGkpHRL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/31MwaKd8qBL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/71Ke0W0H8gL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41-TVCIWfVL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/81mT7xT5FyL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41bsZu8C8GL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/71GWalfImQL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41gDL631dNL.jpg',
            },
          ],
          title:
            'Apple AirPods Max Wireless Over-Ear Headphones, Active Noise Cancelling, Transparency Mode, Personalized Spatial Audio, Dolby Atmos, Bluetooth Headphones for iPhone – Sky Blue',
          type: 'HEADPHONES',
        },
        selectedImageIndex: 0,
      },
      effects: Effects(),
      enablePromptRewriting: true,
      format: '1:1',
      prompt: 'A field with puppies',
      style: AutumnKitchenStyle(),
      advanced: {
        negativePrompt: 'Bad quality',
        seed: '1234',
        temperature: 0.9,
      },
    },
  },
  {
    content: `${mockAssetsFolder}/mock.3e86d59a-1080-41ca-84bc-98150c789857.png`,
    aspectRatio: '1:1',
    loading: false,
    contentHeadline: 'A field with puppies',
    contentType: 'Lifestyle image',
    studioInputSettings: {
      asin: {
        asin: 'B08PZJN7BD',
        metadata: {
          description:
            'Air Pods To The Max\n\nAirPods Max reimagine over-ear headphones.\n\n An Apple-designed dynamic driver provides immersive high-fidelity audio.\n\n Every detail, from canopy to cushions, has been designed for an exceptional fit.\n\n Industry-leading Active Noise Cancellation blocks outside noise, while Transparency mode lets it in. And spatial audio with dynamic head tracking provides theater-like sound that surrounds you.﻿',
          dimensions: '',
          featureBullets: [
            'BREATHTAKING AUDIO QUALITY — Apple-designed dynamic driver provides high-fidelity audio. Computational audio combines custom acoustic design with the Apple H1 chip and software for breakthrough listening experiences.',
            'FOCUS ON WHAT’S PLAYING — Active Noise Cancellation blocks outside noise so you can immerse yourself in music.',
            'HEAR THE WORLD AROUND YOU — Transparency mode lets you hear and interact with the world around you.',
            'PERSONALIZED SPATIAL AUDIO — With sound that suits your unique ear shape along with dynamic head tracking, AirPods Max deliver an immersive listening experience that places sound all around you. You can also listen to select songs, shows, and movies in Dolby Atmos.',
            'ACOUSTIC-FIRST DESIGN — Designed with a knit-mesh canopy and memory foam ear cushions for an exceptional over-ear fit that perfectly seals in sound.',
            'MAGICAL EXPERIENCE — Pair AirPods Max by simply placing them near your device and tapping Connect on your screen. AirPods Max pause audio when you take them off. And Automatic Switching makes listening between your iPhone, iPad, and Mac completely effortless.',
            'LONG BATTERY LIFE — Up to 20 hours of listening, movie watching, or talk time with Active Noise Cancellation and Personalized Spatial Audio enabled.',
            'PRECISION CONTROL — Use the Digital Crown to play and pause music, to answer, end, and mute yourself on calls, and to control volume and skip between tracks.',
            'A SMARTER CASE — Store in an ultra low-power state with the slim Smart Case.',
            'LEGAL DISCLAIMERS — This is a summary of the main product features. See “Additional information” to learn more.',
          ],
          glGroup: 'gl_wireless',
          mediaCentralAssets: [
            {
              highResUri: 'https://m.media-amazon.com/images/I/81jkMpNHVsL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41uClassuxL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/81setQtwhcL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41f-pR+bBmL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/71fxJGkpHRL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/31MwaKd8qBL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/71Ke0W0H8gL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41-TVCIWfVL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/81mT7xT5FyL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41bsZu8C8GL.jpg',
            },
            {
              highResUri: 'https://m.media-amazon.com/images/I/71GWalfImQL.jpg',
              lowResUri: 'https://m.media-amazon.com/images/I/41gDL631dNL.jpg',
            },
          ],
          title:
            'Apple AirPods Max Wireless Over-Ear Headphones, Active Noise Cancelling, Transparency Mode, Personalized Spatial Audio, Dolby Atmos, Bluetooth Headphones for iPhone – Sky Blue',
          type: 'HEADPHONES',
        },
        selectedImageIndex: 0,
      },
      effects: Effects(),
      enablePromptRewriting: true,
      format: '1:1',
      prompt: 'A field with puppies',
      style: AutumnKitchenStyle(),
      advanced: {
        negativePrompt: 'Bad quality',
        seed: '1234',
        temperature: 0.9,
      },
    },
  },
];
