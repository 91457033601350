import { Stack } from '@mui/material/';
import AnnouncementIcon from '../../../icons/announcement.svg';
import CloseIcon from '../../../icons/close.svg';

export const ConfirmationSnackbar = (props: { onExit: () => void }) => {
  return (
    <div
      id="snackbar"
      style={{
        position: 'absolute',
        zIndex: 10000,
        top: '80px',
        left: '21%',
        right: '21%',
        boxShadow: '0px 4px 8px 0px #242F3F33',
        backgroundColor: 'white',
        padding: '5px 15px 5px 15px',
        borderRadius: '10px',
      }}
    >
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} gap={3}>
        <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} gap={3}>
          <div
            style={{
              padding: '9px',
              display: 'inline-block',
              borderRadius: '50%',
              background: 'linear-gradient(90.2deg, #976AFF -0.87%, #33A9FF -0.86%, #5E65FF 15.83%, #976AFF 49.23%, #5E65FF 102.98%)',
            }}
          >
            <AnnouncementIcon />
          </div>
          <h4>Thank you for submitting feedback!</h4>
        </Stack>
        <div
          onClick={props.onExit}
          style={{
            cursor: 'pointer',
          }}
        >
          <CloseIcon />
        </div>
      </Stack>
    </div>
  );
};
