import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Navigate, RouteObject, createBrowserRouter, RouterProvider } from 'react-router-dom';
import RedirectToAuthPortal from 'src/components/common/RedirectToAuthPortal';
import { setupStore } from 'src/v2/redux/store';
import App_new from './components/App';
import App_old from './components/App_old';
import './index.scss';
import { isCreativeAgentLaunched } from './util/weblab/weblab';

const App = window.localStorage.getItem('newHome') === 'false' ? App_old : App_new;

const routes = [
  {
    path: '/',
    element: <App page="home" unAuthenticated />,
    errorElement: <App page="notfounderror" />,
  },
  {
    path: '/home', // unauthenticated home
    element: <App page="home" unAuthenticated />,
  },
  {
    path: '/play',
    element: <App page="play" />,
  },
  {
    path: '/playground',
    element: <App page="playground" />,
  },
  {
    path: '/playground/editor',
    element: <App page="playground" editor={true} />,
  },
  {
    path: '/browse',
    element: <App page="browse" />,
  },
  {
    path: '/gallery',
    element: <App page="gallery" unAuthenticated />,
  },
  {
    path: '/home/editor',
    element: <App page="home" editor={true} />,
  },
  {
    path: '/play/editor',
    element: <App page="play" editor={true} />,
  },
  {
    path: '/signin',
    element: <Navigate to="/" />,
  },
  //this is only there to keep the old links working, we should remove it sometime
  isCreativeAgentLaunched()
    ? {
        path: '/home/chat/*',
        element: <Navigate to="/home/chat/" />,
      }
    : null,
  isCreativeAgentLaunched()
    ? {
        path: '/home/chat/',
        element: <App page="chat" />,
      }
    : null,
  isCreativeAgentLaunched()
    ? {
        path: '/play/chat/',
        element: <App page="chat" />,
      }
    : null,
  isCreativeAgentLaunched()
    ? {
        path: '/chat/',
        element: <App page="chat" />,
      }
    : null,
  {
    path: '/Studio',
    element: <App page="studio" />,
  },
  {
    path: 'ap/return',
    element: <RedirectToAuthPortal />,
  },
  {
    path: '/404',
    element: <App page="notfounderror" unAuthenticated />,
  },
].filter((routes) => routes != null) as RouteObject[];

export const router = createBrowserRouter(routes, {
  basename: window.location.pathname.includes('genai') ? '/genai' : '',
});

document.body.dataset.theme = 'main';
const container = document.getElementById('root');
const root = createRoot(container!);
const store = setupStore();
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>,
);
