import { Type } from '@amzn/genaihub-typescript-client';
import React from 'react';
import ImageViewer from './ImageViewer';
import VideoViewer from './VideoViewer';
import WorkflowGuide from './WorkflowGuide';
import CardLoader from '../common/storm/CardLoader';

const WorkflowResults = (props: {
  result?: {
    status: string;
    ids: string[];
    urls: string[];
    type?: Type;
    message?: string;
  };
  guide?: unknown;
  children?: React.ReactNode;
  textResults?: {
    urls: string[];
  };
}) => {
  const { result, guide, children, textResults } = props;
  return (
    <>
      {!result?.status ? (
        <WorkflowGuide guide={guide} />
      ) : result.status === 'COMPLETED' && result?.urls.length > 0 ? (
        result.type === 'VIDEO' ? (
          <VideoViewer urls={result.urls} status={result.status} message={result.message} />
        ) : (
          <ImageViewer ids={result.ids} urls={result.urls} textUrls={textResults?.urls || []}>
            {children}
          </ImageViewer>
        )
      ) : (
        <CardLoader isError={result.status !== 'RUNNING'} />
      )}
    </>
  );
};

export default WorkflowResults;
