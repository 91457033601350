import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface HeadedContainerProps {
  children: ReactNode;
  config: {
    title: string;
    description?: string;
  };
  title?: string;
  description?: string;
  hideTitle?: boolean;
  hideSubTitle?: boolean;
}

export default function HeadedContainer(props: HeadedContainerProps) {
  const { children, config } = props;
  const { title, description } = config;

  return (
    <>
      <Box {...props}>
        <Stack direction={'column'}>
          {props.hideTitle ? (
            <></>
          ) : (
            <Typography component="h2" variant="h2" color="inherit" gutterBottom>
              {props.title || title}
            </Typography>
          )}
          {props.hideSubTitle ? (
            <></>
          ) : (
            <Typography paddingBottom={'30px'} marginBottom={0} fontWeight={300} variant="body1" color="inherit" gutterBottom>
              {props.description || description}
            </Typography>
          )}
          <>{children}</>
        </Stack>
      </Box>
    </>
  );
}
