const primary = { v1: '#976AFF', v2: '#5E65FF', hover: '#5E65FF' };
const secondary = { v1: '#FF833E', v2: '#F2F4F6', hover: '#E6E9ED' };

const styleGuide = {
  buttonBackground: { primary: primary.v2, secondary: secondary.v2 },
  primaryButtonHover: {
    '&:hover': {
      background: primary.hover,
    },
  },
  secondaryButtonHover: {
    '&:hover': {
      background: secondary.hover,
    },
  },
  navButton: {
    borderRadius: '0px',
    '&:hover': {
      background: 'transparent',
    },
  },
  navButtonText: {
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: secondary.v1,
      textDecorationThickness: '6px',
    },
  },
};

export default styleGuide;
