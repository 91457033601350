import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, IconButton, ImageList, ImageListItem, Typography, Stack } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { useState, useContext, useEffect } from 'react';
import { WorkFlowModalObject } from 'src/components/common/types';
import { EditorContext } from 'src/components/editor/EditorContext';
import { CloseButton } from 'src/components/editor/UiContols/components/closeButton';
import { ElementShadeOverlay, ElementShadeOverlayAlways } from 'src/components/editor/UiContols/components/elementShadeOverlay';
import themesDropDown from 'src/config/themes/themesDropDown';
import { themesControlName } from 'src/constants';

import themes from '../../../../../config/themes/themesDropdownList';
import ButtonWithBackground from '../buttonWithBackground/buttonWithBackground';
import themesList from '../themes.json';

const controlValuesMetadata = themesList;

const ImageGrid = ({ control, createControl }: any) => {
  const { controlName, order } = control;
  const [selectedImage, setSelectedImage] = useState<string>();
  const [category, selectedcategory] = useState('Popular');
  const context = useContext(EditorContext);
  const [children, setChildren] = useState<any>([]);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const dropdownRef = React.createRef<HTMLElement>();
  const [dropdownText, setDropdownText] = useState('Filter by category');
  const icons: any = { ...themes };

  useEffect(() => {
    if (controlName == themesControlName && context.workflowOptions[controlName]?.value === 'no_theme') {
      setSelectedImage('no_theme');
    }
  }, [context.workflowOptions]);

  const clickHandler = (value: string) => {
    setSelectedImage(value);
    const obj: WorkFlowModalObject = {};
    obj[controlName] = { value: value, type: 'text' };

    context.setWorkFlowOptions({ ...context.workflowOptions, ...obj });
  };

  const buttonClickHandler = () => {
    setShowDropDown(true);
  };

  useEffect(() => {
    //	context.childControls.map
    const ch: any = context.childControls.filter((child: any) => child.parent === controlName);
    setChildren([...ch]);
  }, [context.childControls]);

  useEffect(() => {
    if (!showDropDown) {
      setShowOptions(false);
    }
  }, [showDropDown]);

  const onChangeHandler = (value: any) => {
    selectedcategory(value);
    setDropdownText(value);
    setShowOptions(false);
  };

  const getControl = () => {
    if (children.length) {
      return [{ order }, ...children]
        .sort((a: any, b: any) => a.order - b.order)
        .map((card, index) => {
          const Comp = card.controlType ? createControl(card, index) : Control();
          return Comp;
        });
    } else {
      return Control();
    }
  };

  const toggleDropdown = () => {
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    if (showOptions) {
      dropdownRef.current?.focus();
    }
  }, [showOptions]);

  const onBlurHandler = () => {
    setShowOptions(false);
  };

  const DropDown = () => {
    const list = [...new Set(controlValuesMetadata.flatMap((item: any) => item.category.split(',')))];

    return (
      <ThemeProvider theme={themesDropDown}>
        <Box>
          <Stack direction={'column'} p={0} sx={{ zIndex: '5' }}>
            <Stack sx={{ position: 'relative', left: '-10px' }} direction={'row'} alignItems={'center'} pb={1}>
              <IconButton
                size="small"
                onClick={() => {
                  setShowDropDown(false);
                  selectedcategory('Popular');
                  setDropdownText('Filter by category');
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
              <Typography fontSize={14} textAlign={'left'} fontWeight={600} fontFamily={'Amazon Ember'}>
                Theme library
              </Typography>
            </Stack>
            <Box className="filterByCategoryWrapper">
              <Box className="filterByCategoryButton" onClick={toggleDropdown}>
                <Box className="buttonContainer">
                  <span>{dropdownText}</span>
                  <ExpandMoreIcon />
                </Box>
              </Box>
              <Box
                tabIndex={0}
                visibility={showOptions ? 'visible' : 'hidden'}
                className="filterByCategoryOptions"
                ref={dropdownRef}
                onBlur={onBlurHandler}
              >
                {list.map((option) => (
                  <span key={option} className={option === category ? 'selected' : ''} onClick={() => onChangeHandler(option)}>
                    {option}
                  </span>
                ))}
              </Box>
            </Box>
          </Stack>
        </Box>
      </ThemeProvider>
    );
  };

  const deselectTheme = (event: React.MouseEvent) => {
    clickHandler('no_theme');
    event.stopPropagation();
  };

  const Control = () => {
    return (
      <Box id="imageGrid" width={'100%'} display={'flex'} justifyContent={'center'}>
        <Stack width={'100%'} direction={'column'} gap={2} px={0} pt={showDropDown ? 0 : '5px'}>
          {showDropDown && <DropDown />}
          <ImageList
            cols={showDropDown ? 2 : 3}
            variant="standard"
            sx={{
              maxHeight: showDropDown ? '420px' : '180px',
              overflowX: 'hidden',
              overflowY: 'scroll',
              marginBottom: 0,
              justifyContent: 'center',
              cursor: 'pointer',
              mt: 0,
              position: 'relative',
              left: '-5px',
              width: 'calc(100% + 10px)',
              padding: '5px',
            }}
            style={{ gap: '10px' }}
          >
            {controlValuesMetadata
              .filter((item) => item.category.indexOf(category) != -1)
              .map((item: any) => (
                <ImageListItem
                  className={`imageListItem ${selectedImage === item.controlValue ? 'imageListItemSelected' : ''}`}
                  key={item.src}
                  sx={{ mt: 0 }}
                  onClick={() => clickHandler(item.controlValue)}
                >
                  <img
                    srcSet={icons[item.src]}
                    src={icons[item.src]}
                    alt={item.controlValue}
                    loading="lazy"
                    style={{
                      width: '100%',
                      height: '100%',
                      cursor: 'pointer',
                      borderRadius: '10px',
                      outlineStyle: 'solid',
                      outlineWidth: '3px',
                      outlineOffset: '2px',
                      // maxWidth: 'calc(100% - 10px)',
                      outlineColor: selectedImage === item.controlValue ? '#5E65FF' : 'rgba(0, 0, 0, 0)',
                    }}
                  />
                  <ElementShadeOverlayAlways />
                  <ElementShadeOverlay />
                  <CloseButton onClick={deselectTheme} />
                  {BottomBar(item.controlLabel)}
                </ImageListItem>
              ))}
          </ImageList>
          {!showDropDown && <ButtonWithBackground onClick={buttonClickHandler}>Explore the theme library</ButtonWithBackground>}
        </Stack>
      </Box>
    );
  };

  const BottomBar = (value: string) => {
    //useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    return (
      <Box className="buttomBar">
        <span>{value}</span>
      </Box>
    );
  };
  return (
    <>
      {
        <Stack gap={1} direction={'column'} px={'16px'} pt={1} alignItems={'center'}>
          {getControl()}
        </Stack>
      }
    </>
  );
};
export default ImageGrid;
