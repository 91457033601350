const getFilesFromFileSystemEntryRecursiveHelper = (fileSystemEntry: FileSystemEntry, output: { files: File[]; errored: boolean }) => {
  return new Promise((resolve) => {
    if (fileSystemEntry.isFile) {
      (fileSystemEntry as FileSystemFileEntry).file(
        // Success Callback
        (file) => {
          output.files.push(file);
          resolve(true);
        },
        // Error Callback
        (err) => {
          output.errored = true;
          console.error(err);
          resolve(true);
        },
      );
    } else {
      const directoryReader = (fileSystemEntry as FileSystemDirectoryEntry).createReader();
      directoryReader.readEntries(
        // Success Callback
        async (entries) => {
          for (let i = 0; i < entries.length; i++) {
            await getFilesFromFileSystemEntryRecursiveHelper(entries[i], output);
          }
          resolve(true);
        },
        // Error Callback
        (err) => {
          output.errored = true;
          console.error(err);
          resolve(true);
        },
      );
    }
  }).catch((err) => {
    output.errored = true;
    console.error(err);
  });
};

export const getFilesFromFileSystemEntry = async (fileSystemEntry: FileSystemEntry) => {
  const output: { files: File[]; errored: boolean } = { files: [], errored: false };
  await getFilesFromFileSystemEntryRecursiveHelper(fileSystemEntry, output);
  return output;
};
