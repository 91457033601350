import { Skeleton } from '@mui/material';
import { ReactElement, useState } from 'react';
import { ContentItem } from 'src/components/pages/studio/StudioContext';
import useAssetImageCache from 'src/hooks/useAssetImageCache';

interface ImageProps {
  src: ContentItem;
  componentLabel: string;
}

export const Image = ({ src, componentLabel }: ImageProps): ReactElement => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const assetUrl = useAssetImageCache(src);

  return (
    <>
      <img
        data-testid={`image-grid-item-${src.imageId}`}
        id={`${componentLabel}-image-grid-item-${src.imageId}`}
        src={assetUrl}
        alt={src.contentHeadline}
        onLoad={() => setImageLoaded(true)}
        style={{ display: !imageLoaded ? 'none' : '' }}
      />
      <Skeleton
        variant="rounded"
        animation={'wave'}
        sx={{ animationDuration: '0.1s' }}
        style={{ display: imageLoaded ? 'none' : '', width: '100%', height: '100%' }}
      />
    </>
  );
};
