import { useContext, useEffect } from 'react';
import { EditorContextStateP1 } from 'src/components/editor/EditorContext';
import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import batchStateUpdate from 'src/components/utils/batchStateUpdate';
import guideHelper from 'src/components/utils/guideHelper';

const TileList = (props: any) => {
  const { control, bulkUpdateCallback } = props;

  const editorContext: EditorContextStateP1 = useContext(props.editorContext);
  let controlValues = editorContext.workflowOptions;

  useEffect(() => {
    guideHelper(control.controlValues, editorContext.activateGuide, guideCallback);
  }, [editorContext.activateGuide]);

  useEffect(() => {
    // set default
    const controlName = control.controlName;
    if (!controlValues[controlName]) {
      const controlData: any = {};
      controlData[controlName] = { value: control.defaultValue };
      editorContext.setWorkFlowOptions((latestValues: any) => ({ ...latestValues, ...controlData }));
    }
  }, [control]);

  const guideCallback = (config: any) => {
    if (config) {
      batchStateUpdate({ [control.controlName]: { value: config.value } }, bulkUpdateCallback);
    }
  };

  const clickHandler = (value: any) => {
    if (!editorContext.disableControl) {
      const controlData: any = {};
      controlData[control.controlName] = { value: value };
      editorContext.setWorkFlowOptions({ ...controlValues, ...controlData });
    }
  };

  const controlValue = controlValues[control.controlName]?.value || control?.defaultValue;

  const Tile = ({ thumbnail, label, value }: { thumbnail: string; label: string; value: string }) => {
    return (
      <div
        data-testid="TileList"
        style={{
          backgroundColor: '#F2F4F6',
          borderRadius: '12px',
          height: '140px',
          width: '100%',
          cursor: editorContext.disableControl ? 'not-allowed' : 'pointer',
          border: controlValue === value && !editorContext.disableControl ? '1.5px solid #4305F4' : 'none',
          overflow: 'hidden',
        }}
        onClick={() => clickHandler(value)}
      >
        <svg width={'100%'} height={'80%'}>
          <image xlinkHref={thumbnail} width="100%" height="100%" style={{ objectFit: 'contain', height: 'auto' }} />
        </svg>
        <div
          style={{
            fontFamily: 'Amazon Ember',
            fontSize: '13px',
            fontWeight: '500',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {label}
        </div>
      </div>
    );
  };
  return (
    <>
      <ControlLabel title={control.controlLabel} />
      <div style={{ display: 'grid', gridTemplateColumns: '31% 31% 31%', width: '100%', gridColumnGap: '4%', gridRowGap: '15px' }}>
        {control.controlValues.map((cv: any, index: number) => (
          <Tile key={`tile${index}`} thumbnail={cv.thumbnail} label={cv.label} value={cv.value} />
        ))}
      </div>
    </>
  );
};

export default TileList;
