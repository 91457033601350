import { Card, Icon } from '@amzn/storm-ui';
import { times } from '@amzn/storm-ui-icons';
import { ProductThumbnail } from 'src/components/common/storm/ProductThumbnail';
import Stack from 'src/customUIComponents/Stack';

interface ProductPreviewProps {
  url: string;
  title: string;
  subTitle: string;
  callback: () => void;
}
const ProductPreview = (props: ProductPreviewProps) => {
  const { url, title, subTitle, callback } = props;
  return (
    <Card padding={10} style={{ background: '#f1f3f5', boxShadow: 'none', width: 'auto' }}>
      <Stack style={{ flexDirection: 'row', flexWrap: 'nowrap', gap: '5px' }}>
        <div style={{ flex: 1 }}>
          <Card
            padding={0}
            style={{ flex: 'none', overflow: 'hidden', maxWidth: '150px', background: 'none', boxShadow: 'none', height: 'auto', width: 'auto' }}
          >
            <ProductThumbnail imageUrl={url} size={100} />
          </Card>
        </div>
        <div style={{ flex: 8, fontSize: 'small', textAlign: 'left', textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {title}
          <br />
          {subTitle}
        </div>
        <Icon
          type={times}
          style={{ flex: 2, textAlign: 'right', cursor: 'pointer' }}
          onClick={() => {
            callback();
          }}
        />
      </Stack>
    </Card>
  );
};

export default ProductPreview;
