import Hero from 'src/components/layout/_storm/Hero';
import Stack from 'src/customUIComponents/Stack';
import Inspirations from './Inspirations';
import Playground from './Playground';
import './homeStyles.css';

const Home = () => {
  return (
    <Stack style={{ gap: '100px' }}>
      <Hero />
      <Playground cards={5} showButton wrap={false} width="100%" cardStyle={{ width: '368px', maxWidth: '368px' }} navigate="arrows" />
      <Inspirations showButton width="100%" maxItems={6} />
    </Stack>
  );
};

export default Home;
