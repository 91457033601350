import { GenericResponse } from '@amzn/genaihub-typescript-client';
import { Sha256 } from '@aws-crypto/sha256-js';
import { Box, Button, Typography, Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { ErrorCodes } from 'src/api/errorTypes';
import { WorkFlowModalObject } from 'src/components/common/types';
import { EditorContext } from 'src/components/editor/EditorContext';
import { CloseButton } from 'src/components/editor/UiContols/components/closeButton';
import { ElementShadeOverlay } from 'src/components/editor/UiContols/components/elementShadeOverlay';
import { getImageFromUrl, ImageType } from 'src/components/utils/base64Encode';
import { Metrics, referenceImageControlName, themesControlName } from 'src/constants';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { StringMetrics } from 'src/metrics';
import FileUploadDialog from './FileUploadDialog';
import { AppContext } from '../../../../../../AppContext';

const buttonStyle: React.CSSProperties = {
  backgroundColor: '#D9DEE4',
  color: 'black',
  border: 'none',
  fontFamily: 'Amazon Ember',
  fontSize: '0.7rem',
  borderColor: '#D82624',
  boxShadow: 'rgba(35, 47, 63, 0.3) 0px 0px 2px 0px, rgba(35, 47, 63, 0.15) 0px 1px 4px 0px',
};
export default function InputFileUpload({ control }: any) {
  const { controlName, description, controlMetadata } = control;
  const [showPreview, setShowPreview] = useState(false);
  const [imageOpacity, setImageOpacity] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const context = useContext(EditorContext);
  const [file, setfile] = useState<File>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const genAIBackendClient = useAIBackendHubClient();
  const appContext = useContext(AppContext);
  const { metrics } = appContext;
  const [bg, setBg] = useState<any>({});
  const [thumbSet, setThumbSet] = useState<boolean>(false);
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selected, setSelected] = useState(false);
  const [fileReferenceId, setFileReferenceId] = useState<string>();
  const isReferenceImageControl = controlName === referenceImageControlName;
  const [showReferenceImageStrength, setShowReferenceImageStrength] = useState<boolean>(false);

  useEffect(() => {
    if (isReferenceImageControl && context.workflowOptions[themesControlName]?.value !== 'no_theme' && selected) {
      setSelected(false);
      clearWorkflowOptionForCurrentControl();
      hideReferenceImageStrength();
    }

    if (context.workflowOptions && context.workflowOptions[controlName]?.content) {
      setShowPreview(true);
      setfile(context.workflowOptions[controlName].content);
      !thumbSet && getThumbNail(context.workflowOptions[controlName].content);
    }
  }, [context.workflowOptions]);

  useEffect(() => {
    if (context.fileUploadEvent && controlName == context.fileUploadEvent.controlName) {
      context.setFileUploadEvent(undefined);
      onChangeHandler({ target: { files: [context.fileUploadEvent.payload] } });
    }
  }, [context.fileUploadEvent]);

  useEffect(() => {
    if (!showReferenceImageStrength) {
      hideReferenceImageStrength();
    }
  }, [showReferenceImageStrength]);

  const onChangeHandler = async (event: any) => {
    setBg({});
    setThumbSet(false);
    appContext.setAlertEvent(undefined);
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    const [file] = event.target.files;

    if (file.size > 20971520) {
      appContext.setAlertEvent({
        type: 'error',
        code: ErrorCodes.ERROR_CODE_20036,
        control,
        resourceArgs: { size: '20MB' },
      });
      event.target.value = null;
      return;
    }

    const stringMetrics = {} as StringMetrics;
    if (!file) {
      stringMetrics.action = Metrics.Values.NotSelected;
    } else {
      stringMetrics.action = Metrics.Values.Selected;
      stringMetrics.size = file.size;
      stringMetrics.type = file.type;
    }

    metrics.trackMetrics(Metrics.Methods.FileSelected, stringMetrics, { [Metrics.Counters.Count]: 1 });
    setImageOpacity(true);
    createFileForUpload(file);
  };

  const onClickHandler = () => {
    metrics.trackMetrics(Metrics.Methods.FileSelector, { [Metrics.Names.Action]: Metrics.Actions.Open }, { [Metrics.Counters.Count]: 1 });
  };

  const createFileForUpload = (file: File) => {
    setfile(file);
    setShowPreview(true);

    if (!controlMetadata?.controlOptions?.openDialog) uploadFile(file);
    else setOpenDialog(true);
  };

  const hideReferenceImageStrength = () => {
    setShowReferenceImageStrength(false);
    context.workflowConfig;
    context.workflowConfig.filter((obj: any) => {
      if (obj.controlName === 'reference_image_strength') {
        return (obj.visible = false);
      }
    });
    context.setWorkflowConfig([...context.workflowConfig]);
  };

  const clickHandler = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (controlName === 'reference_image') {
      hideReferenceImageStrength();
    }

    setSelected(false);
    setBg({});
    setShowPreview(false);
  };

  const clearWorkflowOptionForCurrentControl = () => {
    const obj: WorkFlowModalObject = {};
    obj[controlName] = {};
    context.setWorkFlowOptions({ ...context.workflowOptions, ...obj });
  };

  const updateWorkflowOptionForCurrentControl = () => {
    if (!fileReferenceId) {
      return;
    }

    const obj: WorkFlowModalObject = {};
    obj[themesControlName] = { value: 'no_theme', type: 'text' };
    obj[controlName] = { value: fileReferenceId, type: 'image', content: file };
    context.setWorkFlowOptions((workflowOptions: any) => ({ ...workflowOptions, ...obj }));
  };

  const toHexString = (byteArray: Uint8Array) => {
    return Array.from(byteArray, function (byte) {
      return ('0' + (byte & 0xff).toString(16)).slice(-2);
    }).join('');
  };

  const uploadFile = async (fileObj: File) => {
    setLoading(true);
    try {
      const upload = async () => {
        let base64 = (
          await getImageFromUrl({
            url: URL.createObjectURL(fileObj),
            type: fileObj.type as ImageType,
          })
        ).base64Encode();
        const hash = new Sha256();
        hash.update(base64);
        const uint8Array = await hash.digest();
        const imageHash = toHexString(uint8Array);

        const uploadResponse = await genAIBackendClient.fileUploadGetPresign({
          body: {
            contentSize: fileObj?.size,
            contentType: fileObj?.type,
            contentCategory: 'PRODUCT_IMAGE',
            contentHash: imageHash,
          },
        });
        let result = uploadResponse.body;
        setFileReferenceId(result.referenceId);

        if (result.url) {
          await fetch(result.url, {
            method: 'PUT',
            headers: {
              'Content-Type': fileObj.type,
              'Content-Length': fileObj.size.toString(),
            },
            body: fileObj,
          });

          console.log('Image Uploaded successfully');
        }

        const obj: WorkFlowModalObject = {};
        obj[controlName] = { value: result.referenceId, type: 'image', content: fileObj };
        context.setWorkFlowOptions((workflowOptions: any) => ({ ...workflowOptions, ...obj }));

        return;
      };
      await upload();
    } catch (error) {
      if (error instanceof Response) {
        const errorDetails = (await error.json()) as GenericResponse;
        let resourceArgs;

        if (errorDetails.code === ErrorCodes.ERROR_CODE_20036) {
          resourceArgs = { size: '20MB' };
        }

        appContext.setAlertEvent({
          type: 'error',
          code: errorDetails.code,
          control,
          resourceArgs,
        });
      }

      setShowPreview(false);
      console.log(error);
    } finally {
      setLoading(false);
      setImageOpacity(false);
    }
  };

  const getThumbNail = (fileObj: any) => {
    setThumbSet(true);
    setTimeout(() => {
      const url: any = fileObj && URL.createObjectURL(fileObj);
      url && setBg({ background: `url(${url})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' });
    }, 100);
  };

  const showProgress = () => (
    <Box mt={1.5} width={'100%'} height={'100%'} display={'flex'} justifyContent={'center'} zIndex={2} alignItems={'center'}>
      <CircularProgress
        sx={{
          color: 'rgb(135,103,252)',
        }}
      />
    </Box>
  );

  useEffect(() => {
    setSelected(showPreview && !loading && !openDialog);
  }, [showPreview, loading, openDialog]);

  const getOutline = (): React.CSSProperties => {
    return selected && controlName === referenceImageControlName
      ? {
          outline: 'rgb(94, 101, 255) solid 3px',
          outlineOffset: '2px',
        }
      : {};
  };

  const handleClickForSelection = () => {
    if (showPreview && !loading && !openDialog) {
      setSelected(true);
    }
  };

  useEffect(() => {
    if (selected) {
      updateWorkflowOptionForCurrentControl();
    } else {
      clearWorkflowOptionForCurrentControl();
    }
  }, [selected]);

  const desktopImage = (buttonText: string, hideButton: boolean) => {
    return (
      <div
        onMouseEnter={() => {
          setMouseOver(true);
        }}
        onMouseLeave={() => {
          setMouseOver(false);
        }}
        onClick={handleClickForSelection}
        style={{
          position: 'relative',
          height: '100px',
          width: '130px',
          alignContent: 'center',
          justifyContent: 'center',
          display: 'grid',
          backgroundColor: isValidationError ? '#FEECEC' : '#EDF5FB',
          fontFamily: '',
          border: 1,
          borderStyle: 'solid',
          borderColor: isValidationError ? '#D82624' : '#E6E9ED',
          borderRadius: '8px',
          opacity: imageOpacity ? 0.9 : 1,
          margin: '5px',
          ...getOutline(),
          ...bg,
        }}
      >
        {loading && showProgress()}
        <ElementShadeOverlay className={'fileUploadShadeOverlay'} visibility={showPreview && !loading && mouseOver} />
        <CloseButton className={'fileUploadCloseButton'} onClick={clickHandler} visibility={showPreview && !loading && mouseOver} />
        {
          <Button
            component="label"
            size={'small'}
            role={undefined}
            variant="contained"
            tabIndex={-1}
            aria-label="fileuploader"
            data-testid="fileUploadButton"
            sx={{ fontSize: '0.6em' }}
            onClick={(event) => event.stopPropagation()}
            style={{ ...buttonStyle, zIndex: 2, visibility: hideButton ? (mouseOver && !loading ? 'visible' : 'hidden') : 'visible' }}
          >
            {buttonText}
            <input
              ref={inputRef}
              accept="image/png, image/jpeg"
              onChange={onChangeHandler}
              onClick={onClickHandler}
              data-testid="fileUploadInput"
              style={{
                clip: 'rect(0 0 0 0)',
                clipPath: 'inset(50%)',
                height: 1,
                overflow: 'hidden',
                position: 'absolute',
                bottom: 0,
                left: 0,
                whiteSpace: 'nowrap',
                width: 1,
              }}
              type="file"
            />
          </Button>
        }
      </div>
    );
  };
  const singleImage = () => <>{desktopImage('Replace Image', true)}</>;

  const isValidationError = appContext.alertEvent?.control?.controlName === controlName;

  return (
    <>
      {controlName === 'reference_image' && <Divider style={{ width: '130%', marginLeft: '-20%' }}></Divider>}
      {openDialog && (
        <FileUploadDialog
          control={control}
          file={file}
          setOpenDialog={setOpenDialog}
          uploadFile={uploadFile}
          cancel={() => {
            setShowPreview(false);
          }}
          onClick={onClickHandler}
          onChange={onChangeHandler}
        />
      )}
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} my={1} px={0} pb={1}>
        {description && (
          <Typography flex={1} fontSize={14} textAlign={'left'} fontWeight={400} m={0.3}>
            <div style={{ textAlign: 'center', color: 'gray', fontSize: '15px', fontFamily: 'Amazon Ember', paddingRight: '25px' }}>
              <i>Or</i>
            </div>
            {description}
          </Typography>
        )}
        {showPreview ? singleImage() : desktopImage('Upload Image', false)}
      </Box>
    </>
  );
}
