import styled from 'styled-components';

const ThumbnailImage = styled.div<ProductThumbnailProps>`
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-color: ${({ bgColor }) => (bgColor ? `var(${bgColor})` : 'var(--background-color-variant-2)')};
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 2px solid #fff;
  border-radius: 8px;
  flex-shrink: 0;

  ${({ size }) =>
    size &&
    `
     height: ${size}px;
     width: ${size}px;
   `}
`;

interface ProductThumbnailProps {
  imageUrl: string;
  size: number;
  bgColor?: string;
}

export const ProductThumbnail = (props: ProductThumbnailProps) => {
  return <ThumbnailImage {...props} aria-label={'product image'} />;
};
