import delay from './delay';

let workflowOptions: any = {};
let watch = false;
let pause: number;

export default function (controlData: any, callback: Function, overrideDelay: number = 3000) {
  pause = overrideDelay;
  //updateObject = { ...updateObject, ...value };
  if (controlData) {
    workflowOptions = { ...workflowOptions, ...controlData };
  }

  if (!watch && Object.keys(workflowOptions).length > 0) {
    watch = true;
    updateWorkFlow(callback);
  }
}

const reset = () => {
  workflowOptions = {};
  watch = false;
};

const updateWorkFlow = async (callback: Function) => {
  await delay(pause);
  callback(workflowOptions);

  reset();
};
