import React, { useState } from 'react';
import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import styles from './RangeSlider.module.scss';

export interface RangeSliderProps {
  title: string;
  min: number;
  minLabel: string;
  max: number;
  maxLabel: string;
  step: number;
  onChange?: (value: number) => void;
  defaultValue?: number;
  disabled?: boolean;
}
const RangeSlider = (props: RangeSliderProps) => {
  const [sliderValue, setSliderValue] = useState<number | undefined>(props.defaultValue);

  const handleInputChange: React.ChangeEventHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = Number(e.target.value);
    setSliderValue(value);
    props.onChange && props.onChange(value);
  };

  return (
    <>
      <ControlLabel title={props.title} />
      <div className={styles.rangeWrapper}>
        <input
          data-testid="range-slider"
          type="range"
          min={props.min}
          max={props.max}
          defaultValue={props.defaultValue}
          value={sliderValue}
          step={props.step}
          onChange={handleInputChange}
          disabled={props.disabled ?? false}
          className={styles.rangeInput}
        ></input>
        <div className={styles.rangeLabels}>
          <span>{props.minLabel}</span>
          <span className={styles.maxLabel}>{props.maxLabel}</span>
        </div>
      </div>
    </>
  );
};

export default RangeSlider;
