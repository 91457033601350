import { ContentItem, MessageRole, Message as MessageModel } from '@amzn/red-carpet-service-client';
import React from 'react';
import styled from 'styled-components';
import MessagePill from './messagePill';
import { HeaderConfig } from '../../model';
import { CHAT_THEME } from '../../theme';

const Message = styled.div`
  display: flex;
  gap: ${CHAT_THEME.spacing.basePlus};
  justify-content: flex-end;
  align-self: end;

  &.${MessageRole.ASSISTANT} {
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-self: start;
  }

  &.${MessageRole.METADATA} {
    min-width: min(400px, 100%);
    width: 80%;
  }
`;

const SenderPhoto = styled.img`
  // FIXME: this is bad, so bad, we should fix that once we have an understanding about the assistant images.
  min-width: ${CHAT_THEME.size.photo.small};
  min-height: ${CHAT_THEME.size.photo.small};
  max-width: ${CHAT_THEME.size.photo.small};
  max-height: ${CHAT_THEME.size.photo.small};
  clip-path: circle(35% at center);
  margin: -7.485px 0;
  align-self: end;

  &.${MessageRole.ASSISTANT} {
    align-self: start;
  }
`;

interface Props {
  message: MessageModel;
  headerConfig?: HeaderConfig;
  onReply?: (quotedContent: ContentItem[]) => void;
  children?: React.ReactNode;
}

export default function MessageWrapper({ message, headerConfig, onReply, children }: Props) {
  return (
    <Message className={message?.role} data-testid={'message'}>
      <MessagePill message={message} headerConfig={headerConfig} onReply={onReply}>
        {children}
      </MessagePill>
      {message?.role == MessageRole.ASSISTANT && headerConfig?.imageUrl && <SenderPhoto className={message.role} src={headerConfig?.imageUrl} />}
    </Message>
  );
}
