export interface EffectItem {
  label: string;
  options: EffectOption[];
}

export interface EffectOption {
  label: string;
  value: string;
}

export interface EffectLookup {
  effectName: EffectsEnum;
  effectItem: EffectOption;
}

export enum EffectsEnum {
  Lighting = 'Lighting',
  Camera = 'Camera',
  Color = 'Color',
}

export const NoEffect = 'None';

const lightingAndShadowEffects: EffectOption[] = [
  {
    label: NoEffect,
    value: NoEffect,
  },
  {
    label: 'Natural lighting',
    value: 'natural light fills the scene, casting soft shadows',
  },
  {
    label: 'Sunlight',
    value: 'intense sunlight fills the scene, casting deep shadows',
  },
  {
    label: 'Studio lighting',
    value: 'neutral studio lighting, casting balanced shadows',
  },
  {
    label: 'Soft lighting',
    value: 'diffused studio lighting',
  },
  {
    label: 'Dimly lit',
    value: 'dimly lit scene, dark shadows',
  },
  {
    label: 'Sunset',
    value: 'at sunset',
  },
  {
    label: 'Sharp light and shadows',
    value: 'dramatic sharp lighting with long shadows',
  },
  {
    label: 'Natural front lighting',
    value: 'front lighting',
  },
  {
    label: 'Moonlight',
    value: 'blue hued moonlight, dark shadows',
  },
  {
    label: 'Nostalgic lighting',
    value: 'strong amber light fills the space',
  },
  {
    label: 'Neon lighting',
    value: 'colorful neon light fills the space',
  },
  {
    label: 'Eerie lighting',
    value: 'dark eerie lighting',
  },
  {
    label: 'Nighttime lighting',
    value: 'nighttime lighting',
  },
];

const cameraPositionEffects: EffectOption[] = [
  {
    label: NoEffect,
    value: NoEffect,
  },
  {
    label: 'Straight on',
    value: 'straight on view',
  },
  {
    label: 'Slightly above',
    value: 'slightly above view',
  },
  {
    label: "Bird's eye view",
    value: "bird's eye view",
  },
  {
    label: 'Hero view',
    value: 'hero view',
  },
  {
    label: "Worm's eye view",
    value: "worm's eye view",
  },
  {
    label: 'Fish-eye lens',
    value: 'fish eye lens',
  },
  {
    label: 'Wide-angle lens',
    value: 'wide angle lens',
  },
  {
    label: 'Zoom lens',
    value: 'zoom lens',
  },
];

const toneAndColorEffects: EffectOption[] = [
  {
    label: NoEffect,
    value: NoEffect,
  },
  {
    label: 'Natural pastel colors',
    value: 'with a soft pastel vibe and colors',
  },
  {
    label: 'Vivid green colors',
    value: 'with a bright summer vibe and colors',
  },
  {
    label: 'Muted winter colors',
    value: 'with a winter vibe and colors',
  },
  {
    label: 'Warm autumn colors',
    value: 'with an autumn vibe and colors',
  },
];

export const EffectSettings: Record<string, EffectItem> = {
  [EffectsEnum.Lighting]: {
    label: 'Lighting & Shadow',
    options: lightingAndShadowEffects,
  },
  [EffectsEnum.Camera]: {
    label: 'Camera Position',
    options: cameraPositionEffects,
  },
  [EffectsEnum.Color]: {
    label: 'Color palette',
    options: toneAndColorEffects,
  },
};

export const EffectLookupByValue: Record<string, EffectLookup> = (() => {
  const effectLookupByValue: Record<string, EffectLookup> = {};
  for (let [key, value] of Object.entries(EffectSettings)) {
    for (const option of value.options) {
      effectLookupByValue[option.value] = {
        effectName: key as EffectsEnum,
        effectItem: option,
      };
    }
  }
  return effectLookupByValue;
})();
