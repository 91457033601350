import { Box, Button, Text } from '@amzn/storm-ui';
import { useContext, useEffect } from 'react';
import { ImageModalProductPreview } from 'src/components/imageModal/components/ImageModalProductPreview';
import { AssetType } from 'src/components/imageModal/components/utils';
import { ImageModalContext } from 'src/components/imageModal/index';
import { ContentItem, isImportedMediaWorkflow } from 'src/components/pages/studio/StudioContext';
import { isProd } from 'src/util/util';
import styled from 'styled-components';
import styles from './ImageModalPreviewTab.module.scss';
import { CopyToClipboard } from '../CopyToClipboard/CopyToClipboard';

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.large};
`;

const Section = styled.div`
  gap: ${({ theme }) => theme.spacing.large};
`;

export const ImageModalPreviewTab = ({ currentItem }: { currentItem?: ContentItem }) => {
  const {
    assetType,
    imageType,
    imageUrl,
    prompt,
    productImageDetails,
    theme,
    savedEditsImageUrl,
    studioInputSettings,
    savedEditsAssetType,
    savedEditsWorkflowId,
    handleReuseSettings,
    handleSwitchToNextContentItem,
    handleSwitchToPrevContentItem,
  } = useContext(ImageModalContext);

  // check for left an right arrow press to change asset details
  const navigationKeyListener = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowLeft':
        event.stopPropagation();
        handleSwitchToPrevContentItem();
        break;
      case 'ArrowRight':
        event.stopPropagation();
        handleSwitchToNextContentItem();
        break;
    }
  };

  // set key listeners so that arrow keys work
  useEffect(() => {
    window.addEventListener('keydown', navigationKeyListener);
    return () => {
      window.removeEventListener('keydown', navigationKeyListener);
    };
  }, [navigationKeyListener]);

  // lifestyle image modal doesn't currently support vertical 9:16 aspect ratio
  // this is tied to UserInputContainer and it also excluding 9:16 creations.
  const reuseSettingsDisabled = (isProd() && studioInputSettings?.format === '9:16') || !studioInputSettings;

  const isImportedMediaWorkflowResult = isImportedMediaWorkflow(savedEditsWorkflowId ?? currentItem?.workflowId);
  const isMediaOnly = isImportedMediaWorkflowResult || !studioInputSettings;

  const currentAssetType = savedEditsAssetType || assetType;
  const currentAssetUrl = savedEditsImageUrl || imageUrl;

  return (
    <div className={`${styles.imagePreviewTab} ${isMediaOnly ? styles.mediaOnly : ''}`} role={'tabpanel'} data-testid="Preview">
      <Panel className={styles.leftPanel}>
        {currentAssetType === AssetType.VIDEO ? (
          <video preload="auto" controls={true} autoPlay playsInline muted loop src={currentAssetUrl} data-testid={'studio-edit-main-video'} />
        ) : (
          <img src={currentAssetUrl} alt={'main image'} data-testid={'studio-edit-main-image'} />
        )}
      </Panel>

      {!isMediaOnly && (
        <Panel className={styles.rightPanel}>
          <div className={styles.rightPanelContent}>
            <Section className={styles.section}>
              {!savedEditsImageUrl && (
                <div className={styles.sectionHeader}>
                  <Text type="h3">Settings</Text>
                  {!reuseSettingsDisabled && (
                    <Button onClick={handleReuseSettings} data-testid="studio-preview-reuse-settings-button">
                      Reuse settings
                    </Button>
                  )}
                </div>
              )}
              <div className={styles.subSection}>
                <div className={styles.sectionSubHeader}>
                  <Text>{savedEditsImageUrl ? 'Original Prompt' : 'Prompt'}</Text>
                </div>
                <CopyToClipboard textCopy={prompt}>
                  <Box className={styles.box}>
                    <Text textColor={'tertiary'} fontSize={'base'}>
                      {prompt}
                    </Text>
                  </Box>
                </CopyToClipboard>
              </div>
              {productImageDetails && (
                <div className={styles.subSection}>
                  <div className={styles.sectionSubHeader}>
                    {'asin' in productImageDetails && <Text>ASIN</Text>}
                    {'name' in productImageDetails && <Text>Uploaded image</Text>}
                  </div>
                  <Box className={styles.box}>
                    <ImageModalProductPreview {...productImageDetails} />
                  </Box>
                </div>
              )}
              {!savedEditsImageUrl && (
                <div className={styles.subSection}>
                  <div className={styles.sectionSubHeader}>
                    <Text>Asset type</Text>
                  </div>
                  <Text textColor={'tertiary'}>{imageType}</Text>
                </div>
              )}
              {theme && !savedEditsImageUrl && (
                <div className={styles.subSection}>
                  <div className={styles.sectionSubHeader}>
                    <Text>Theme</Text>
                  </div>
                  <div className={styles.theme}>
                    <img src={theme.icon} alt={theme.label} />
                    <Text textColor={'tertiary'}>{theme.label}</Text>
                  </div>
                </div>
              )}
              {savedEditsImageUrl && (
                <div className={styles.subSection}>
                  <div className={styles.sectionSubHeader}>
                    <Text>Original Image</Text>
                  </div>
                  <img src={imageUrl} className={styles.originalImage} alt={'original image'} data-testid={'studio-edit-original-image'} />
                </div>
              )}
            </Section>
          </div>
        </Panel>
      )}
    </div>
  );
};
