import { Feedback, WorkflowId } from '@amzn/genaihub-typescript-client';
import { Icon, Text } from '@amzn/storm-ui';
import { IconDefinition } from '@amzn/storm-ui-icons';
import { faFaceFrown, faFaceMeh, faFaceSmile } from '@fortawesome/free-solid-svg-icons';
import { useContext, useState } from 'react';
import FeedbackPopoverStorm from 'src/components/common/storm/FeedbackPopoverStorm';
import FeedbackAlert from 'src/components/editor/FeedbackAlert';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { AppContext } from '../../../AppContext';

const ModuleFeedback = () => {
  const [openFeedback, setOpenFeedback] = useState<boolean>(false);
  const [sentiment, setSentiment] = useState<string>('');
  const [iconColor, setIconColor] = useState<string>('');

  const appContext = useContext(AppContext);
  const { selectedTool } = appContext;

  const genAIBackendClient = useAIBackendHubClient();

  const handleClick = (userSentiment: string) => {
    if (iconColor !== userSentiment) {
      // Will refactor this later
      let feedback;
      if (userSentiment === 'happy') feedback = 'LIKE';
      else if (userSentiment === 'unhappy') feedback = 'DISLIKE';
      else feedback = 'NEUTRAL';

      genAIBackendClient.submitFeedbackSentiment({
        body: {
          feedback: feedback as Feedback,
          workflowId: selectedTool as WorkflowId,
          scope: 'MODULE',
        },
      });

      setIconColor(userSentiment);
      setSentiment(userSentiment);
    }
  };

  const handleAddComment = () => {
    setOpenFeedback(true);
    setSentiment('');
  };

  const handleDismiss = () => {
    setSentiment('');
  };
  const closePopover = () => {
    setOpenFeedback(false);
  };

  const FeedbackIcon = (props: any) => {
    const { userFeedback } = props;

    let faIcon = faFaceSmile as IconDefinition;
    if (userFeedback === 'unhappy') faIcon = faFaceFrown as IconDefinition;
    else if (userFeedback === 'neutral') faIcon = faFaceMeh as IconDefinition;

    return (
      <span style={{ width: '22px' }}>
        <Icon
          onClick={() => {
            handleClick(userFeedback);
          }}
          type={faIcon}
          size={'lg'}
          style={{
            marginRight: '5px',
            marginLeft: '14px',
            color: userFeedback.toString() === iconColor.toLowerCase() ? 'green' : '#8290A4',
            cursor: 'pointer',
          }}
        />
      </span>
    );
  };

  return (
    <>
      <Text>What do you think of this tool?</Text>
      <div
        style={{
          display: 'flex',
          marginTop: '10px',
          //marginBottom: '50px',
        }}
      >
        <FeedbackAlert
          userFeedback={'happy'}
          sentiment={sentiment}
          handleDismiss={handleDismiss}
          handleAddComment={handleAddComment}
          FeedbackIcon={FeedbackIcon}
          position={'top'}
          style={{ position: 'absolute', left: '10px', bottom: '-80px' }}
        />
        <FeedbackAlert
          userFeedback={'neutral'}
          sentiment={sentiment}
          handleDismiss={handleDismiss}
          handleAddComment={handleAddComment}
          FeedbackIcon={FeedbackIcon}
          position={'top'}
          style={{ position: 'absolute', left: '10px', bottom: '-80px' }}
        />
        <FeedbackAlert
          userFeedback={'unhappy'}
          sentiment={sentiment}
          handleDismiss={handleDismiss}
          handleAddComment={handleAddComment}
          FeedbackIcon={FeedbackIcon}
          position={'top'}
          style={{ position: 'absolute', left: '10px', bottom: '-80px' }}
        />
      </div>
      <FeedbackPopoverStorm
        toggle={openFeedback}
        closePopover={closePopover}
        feedbackScope={'MODULE'}
        sentiment={(() => {
          if (iconColor === 'happy') return 'LIKE';
          if (iconColor === 'unhappy') return 'DISLIKE';
          return 'NEUTRAL';
        })()}
        imageSrc={''}
      />
    </>
  );
};

export default ModuleFeedback;
