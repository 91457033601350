import { Message, Persona } from '@amzn/red-carpet-service-client';
import { useQuery } from '@tanstack/react-query';
import { redCarpetClient } from 'src/components/assistant/client';
import { NEW_CHAT_PARAM_KEY } from 'src/components/assistant/utils/useConversationId';
import { queryClient } from 'src/components/QueryClient';

export const QUERY_KEY = ['messages'];

export interface LoadingMessageConfig {
  active?: boolean;
  text?: string;
  persona?: Persona;
  loadingImages?: boolean;
}

export interface ConversationMessagesQuery {
  messages: Message[];
  loadingMessageConfig?: LoadingMessageConfig;
}

export function useConversationsMessages(conversationId: string, alias?: string) {
  return useQuery<ConversationMessagesQuery>({
    queryKey: [...QUERY_KEY, conversationId],
    queryFn: async () => ({
      messages: conversationId !== NEW_CHAT_PARAM_KEY ? await redCarpetClient.listConversationMessages(alias, conversationId) : [],
    }),
  });
}

export function setQueryEnabled(conversationId: string, enabled: boolean) {
  queryClient.setQueryDefaults([...QUERY_KEY, conversationId], { enabled });
}

export function moveNewChatQueryData(conversationId: string) {
  const newChatQueryData = queryClient.getQueryData<ConversationMessagesQuery>([...QUERY_KEY, NEW_CHAT_PARAM_KEY]);
  queryClient.setQueryData<ConversationMessagesQuery>([...QUERY_KEY, conversationId], newChatQueryData);
}

export function addNewMessages(conversationId: string, newMessages: Message[]) {
  queryClient.setQueryData<ConversationMessagesQuery>([...QUERY_KEY, conversationId], (conversationMessagesConfig) => {
    return {
      ...conversationMessagesConfig,
      messages: [...(conversationMessagesConfig?.messages || []), ...newMessages],
    };
  });
}

export function updateLoadingMessageConfig(conversationId: string, newLoadingMessageConfig: Partial<LoadingMessageConfig>) {
  queryClient.setQueryData<ConversationMessagesQuery>([...QUERY_KEY, conversationId], (conversationMessagesConfig) => {
    return {
      messages: [],
      ...conversationMessagesConfig,
      loadingMessageConfig: { ...conversationMessagesConfig?.loadingMessageConfig, ...newLoadingMessageConfig },
    };
  });
}
