import { CSSProperties } from 'react';

const card: CSSProperties = {
  overflow: 'hidden',
  borderRadius: '12px',
  flex: '1 0 30%',
  cursor: 'pointer',
  aspectRatio: '0.7',
  boxShadow: '0px 14px 30px -10px rgba(0, 0, 0, .1)',
};

const cardTitle: CSSProperties = {
  color: 'white',
  fontSize: '24px',
  fontFamily: 'Ember Modern Display Standard',
  fontWeight: '500',
  lineHeight: '29px',
  margin: '10px 20px 10px 20px',
  textAlign: 'left',
};

const cardTitleContainer: CSSProperties = {};

const cardDescription: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: 'white',
  fontFamily: 'Amazon Ember Display',
  margin: '10px 20px 20px 20px',
  textAlign: 'left',
  fontSize: '13px',
  fontWeight: '600',
  lineHeight: '18px',
  maxHeight: '54px',
};

export { card, cardTitle, cardDescription, cardTitleContainer };
