import { Button } from '@amzn/storm-ui';
import React, { useRef } from 'react';
import { AcceptedFileTypesForStudioImageUpload, filterStudioImageUploadFiles } from 'src/components/utils/ImageUtils';
import UploadImageIcon from 'src/icons/uploadImageSmall.svg';
import styled from 'styled-components';
import styles from './StudioImportImageButton.module.scss';

export const TEST_ID_STUDIO_IMPORT_IMAGE_BUTTON = 'studio-import-image-button';
export const TEST_ID_STUDIO_IMPORT_IMAGE_BUTTON_ICON = 'studio-import-image-button-icon';
export const TEST_ID_STUDIO_IMPORT_IMAGE_FILE_INPUT = 'studio-import-image-file-input';

const StyledButton = styled(Button)`
  && {
    display: inline-flex;
    align-items: center;
    gap: 4px;

    box-shadow: none;
    background-color: var(--studio-core-colors-pure-white);
    color: var(--text-ui-primary);

    border: 2px solid var(--text-ui-primary);
    border-radius: 8px;
    padding: 6px 14px;
  }

  &&:hover {
    box-shadow: none;
    background-color: var(--background-level-2);
    color: var(--text-ui-primary);
  }

  &&:disabled {
    padding: 7px 14px;
    border: none;
    background-color: var(--background-level-3);
    color: var(--text-ui-white-on-color-background) !important;
  }
`;

interface StudioImportImageButtonProps {
  disabled?: boolean;
  /**
   * Callback called after user selects files
   * @param props
   * @param props.files - list of files selected by user
   * @param props.filtered - flag indicating if the dropped set of files was filtered
   * @returns
   */
  onSelectFiles?: (props: { files: File[]; filtered: boolean }) => void;
}

const AcceptedFileTypes = AcceptedFileTypesForStudioImageUpload.join(',');

export const StudioImportImageButton = ({ disabled, onSelectFiles }: StudioImportImageButtonProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.click();
    }
  };

  const handleFileInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files?.length) {
      const files = Array.from(event.target.files);
      const result = filterStudioImageUploadFiles({ files });
      onSelectFiles?.({ files: result.files, filtered: result.filtered });
    }
  };

  return (
    <>
      <input
        accept={AcceptedFileTypes}
        aria-hidden="true"
        className={styles.studioImportImageFileInput}
        multiple={true}
        onChange={handleFileInputChange}
        ref={inputRef}
        tabIndex={-1}
        type="file"
        data-testid={TEST_ID_STUDIO_IMPORT_IMAGE_FILE_INPUT}
        style={{ display: 'none' }}
      />
      <StyledButton disabled={disabled} onClick={handleButtonClick} data-testid={TEST_ID_STUDIO_IMPORT_IMAGE_BUTTON}>
        {!disabled && <UploadImageIcon data-testid={TEST_ID_STUDIO_IMPORT_IMAGE_BUTTON_ICON} />}
        Import Image
      </StyledButton>
    </>
  );
};
