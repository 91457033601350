import themesConfig from 'src/components/editor/UiContols/uiGeneratorControls/themes.json';

export const ALL_STYLES = 'all';
export const getFilteredStyles = (value: string) => {
  const searchValue = value.toLowerCase().trim();
  return themesConfig.filter(
    (theme) =>
      value === ALL_STYLES ||
      theme.controlValue.toLowerCase().startsWith(searchValue) ||
      theme.controlLabel.toLowerCase().startsWith(searchValue) ||
      theme.keywords.join(' ').toLowerCase().search(value.toLowerCase()) > -1 ||
      theme.category.toLowerCase().search(value.toLowerCase()) > -1,
  );
};
