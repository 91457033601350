import Genaihubbackend from '@amzn/genaihub-typescript-client';
import { useContext, useEffect, useState } from 'react';
import { GenAIHubClientProvider } from 'src/client/GenAIHubClientProvider';
import { AppContext } from '../../AppContext';

export const useAIBackendHubClient = (): Genaihubbackend => {
  const { metrics } = useContext(AppContext);
  const [instance, setInstance] = useState<Genaihubbackend>(GenAIHubClientProvider.getInstance(metrics).getGenAIHubBackendClient());

  useEffect(() => {
    setInstance(GenAIHubClientProvider.getInstance(metrics).getGenAIHubBackendClient());
  }, [metrics]);

  return instance;
};
