import { AdvertisingAccountMetadata, Cards, UserDetails } from '@amzn/genaihub-typescript-client';
import { MbmProvider } from '@amzn/react-arb-tools';
import { ThemeProvider as StormUIThemeProvider } from '@amzn/storm-ui';
import { Box, Toolbar } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import '@amzn/meridian-tokens/base/font/amazon-ember.css';
import '@amzn/meridian-tokens/base/font/amazon-ember-monospace.css';
import { queryClient } from 'src/components/QueryClient';
import { getMbmOptions, getSelectedAdvertisingAccountFromLocalStorage, saveSelectedAdvertisingAccountToLocalStorage } from 'src/helpers';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { useErrorHandler } from 'src/hooks/useErrorHandler';
import { useMetrics } from 'src/hooks/useMetrics';
import WithNavigationMetrics from 'src/metrics/WithNavigationMetrics';
import { AppContext, AppContextState, AlertEvent, AccountType } from '../../AppContext';
import { defaultTheme } from '../config/themes';
// layout
import { ColorScheme } from './common/types';
import EditorP1 from './editor/EditorP1';
import AppBarEditor from './editor/legacy/AppBarEditor';
import Editor from './editor/legacy/Editor';
import AppBar from './layout/mui/AppBar';
import Footer from './layout/mui/Footer';
import Header from './layout/mui/Header';
// pages
import Chat from './pages/conversational/ChatPage';
import errorpage from './pages/errorpage';
import Home from './pages/Home';
import InspirationsPage from './pages/InspirationsGallery';
import Tools from './pages/Tools';
import arbManifest from '../i18n/translations/arbManifest';

const mbmOptions = getMbmOptions(arbManifest);

const App = ({ page, editor }: { page: string; editor?: boolean }) => {
  const [activePage, setActivePage] = useState<string>(page);
  const [openEditor, setOpenEditor] = useState<boolean>(false);
  const [headerDimensions, setHeaderDimensions] = useState<{ width: number; pageHeaderHeight: number; navHeaderHeight: number }>({
    width: 0,
    pageHeaderHeight: 0,
    navHeaderHeight: 0,
  });
  const [footerDimensions, setFooterDimensions] = useState<{ width?: number; height?: number }>({});
  const [authenticated, setAuthenticated] = useState<boolean>(true);
  const [activeTheme, setActiveTheme] = useState<Theme>(defaultTheme);
  const [colorScheme, setColorScheme] = useState<ColorScheme>('primary');
  const [tools, setTools] = useState<Cards>([]);
  const [selectedTool, setSelectedTool] = useState<string>();
  const [alertEvent, setAlertEvent] = useState<AlertEvent | undefined>();
  const [userDetails, setUserDetails] = useState<UserDetails>();
  const [accountType, setAccountType] = useState<AccountType>();
  const [showSwitchAccountModal, setShowSwitchAccountModal] = useState(false);
  const [selectedAdvertisingAccount, setSelectedAdvertisingAccount] = useState<AdvertisingAccountMetadata | null>(null);
  const genAIBackendClient = useAIBackendHubClient();
  const metrics = useMetrics({
    pageName: `${activePage}${openEditor ? '/editor' : ''}`,
    userDetails: userDetails,
    toolname: openEditor && selectedTool ? selectedTool : undefined,
    accountType: accountType,
    selectedAccount: selectedAdvertisingAccount,
  });
  useErrorHandler(metrics);

  const appContextState: AppContextState = {
    activePage,
    setActivePage,
    openEditor,
    setOpenEditor,
    headerDimensions,
    setHeaderDimensions,
    footerDimensions,
    setFooterDimensions,
    authenticated,
    setAuthenticated,
    activeTheme,
    setActiveTheme,
    colorScheme,
    setColorScheme,
    tools,
    setTools,
    selectedTool,
    setSelectedTool,
    showLandingPage: true,
    setShowLandingPage: () => {},
    alertEvent,
    setAlertEvent,
    userDetails,
    setUserDetails,
    metrics,
    accountType,
    setAccountType,
    showSwitchAccountModal,
    setShowSwitchAccountModal,
    selectedAdvertisingAccount,
    setSelectedAdvertisingAccount,
  };

  useEffect(() => {
    if (accountType === 'external') {
      setShowSwitchAccountModal(!getSelectedAdvertisingAccountFromLocalStorage());
      setSelectedAdvertisingAccount(getSelectedAdvertisingAccountFromLocalStorage());
    }
  }, [accountType]);

  useEffect(() => {
    if (selectedAdvertisingAccount) {
      saveSelectedAdvertisingAccountToLocalStorage(selectedAdvertisingAccount);
    }
  }, [selectedAdvertisingAccount]);

  useEffect(() => {
    setActivePage(page);
    setOpenEditor(!!editor);
    localStorage.setItem('activePage', page);
    localStorage.setItem('openEditor', JSON.stringify(!!editor));
  }, [page, editor]);

  useEffect(() => {
    getToolsList();
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    const result = await genAIBackendClient.getUserDetails({});
    setUserDetails({
      alias: result.body?.alias,
    });
    setAccountType(result.body?.alias ? 'internal' : 'external');
    localStorage.setItem('userDetails', result.body?.alias || 'undefined');
  };

  const getToolsList = async () => {
    try {
      const response = await genAIBackendClient.retrieveToolsList({});
      const result = response.body;
      setTools(result.cards || []);
    } catch (error) {
      console.log(error);
    }
  };

  const pages: { [key: string]: React.FunctionComponent } = {
    home: Home,
    play: Tools,
    browse: InspirationsPage,
    notfounderror: errorpage,
    chat: Chat,
  };

  const getAppBar = () => {
    console.log('appBar', openEditor);
    return openEditor ? <AppBarEditor /> : <AppBar />;
  };

  const getContent = () => {
    const Content = pages[activePage];
    return openEditor ? selectedTool === 'TEXT_TO_IMAGE' ? <Editor /> : <EditorP1 type="editor" module={selectedTool} /> : <Content />;
  };

  return (
    <MbmProvider {...mbmOptions}>
      <AppContext.Provider value={appContextState}>
        <ThemeProvider theme={activeTheme}>
          <StormUIThemeProvider>
            <QueryClientProvider client={queryClient}>
              <CssBaseline />
              {(userDetails || (!userDetails && activePage === 'notfounderror')) && (
                <WithNavigationMetrics activePage={`${activePage}${openEditor ? '/editor' : ''}`}>
                  <Header>{getAppBar()}</Header>
                  <Box display={'flex'} height={openEditor ? '100%' : 'auto'} minHeight={!openEditor ? '100%' : 'unset'} flexDirection={'column'}>
                    <Toolbar />
                    {getContent()}
                  </Box>
                  {/*<Settings />*/}

                  {!openEditor && <Footer />}
                </WithNavigationMetrics>
              )}
            </QueryClientProvider>
          </StormUIThemeProvider>
        </ThemeProvider>
      </AppContext.Provider>
    </MbmProvider>
  );
};

export default App;
