import { BoundingBox } from '@amzn/genaihub-typescript-client';
import type Konva from 'konva';
import { FC, useContext, useEffect, useRef } from 'react';
import { Image as KonvaImage, Layer, Stage, Transformer } from 'react-konva';
import useImage from 'use-image';
import { ImageModalContext } from '../../ImageModalContext';
import { adjustBoundingBox, ASPECT_RATIO, getSizeWithAspectRatio } from '../utils';
export const ProductPositionCanvas: FC<{
  backgroundImageURL: string;
  productImageURL: string;
  boundingBox: BoundingBox;
  canvasWidth: number;
  ready: boolean;
  onReposition: (newPosition: BoundingBox) => void;
}> = ({ onReposition, ready, canvasWidth, backgroundImageURL, boundingBox, productImageURL }) => {
  const [backgroundImage] = useImage(backgroundImageURL);
  const [productImage] = useImage(productImageURL);
  const imageRef = useRef<Konva.Image>(null);
  const transformerRef = useRef<Konva.Transformer>(null);
  const { savedEditsImageAspectRatio, studioInputSettings } = useContext(ImageModalContext);
  const aspectRatio = (savedEditsImageAspectRatio || studioInputSettings?.format || '1:1') as ASPECT_RATIO;
  const canvasHeight = getSizeWithAspectRatio({
    width: canvasWidth,
    aspectRatio,
  }).height;

  const multiplier = canvasWidth / (backgroundImage?.naturalWidth || canvasWidth);
  const adjustedBoundingbox = adjustBoundingBox(boundingBox, multiplier);

  useEffect(() => {
    if (imageRef.current && transformerRef.current) {
      transformerRef.current.nodes([imageRef.current]);
    }
  }, [imageRef.current, transformerRef]);

  const getAttributesFromEvent = (currentTarget: Konva.Node) => {
    const {
      attrs: { x, y, width, height, scaleX, scaleY },
    } = currentTarget;
    return onReposition(
      adjustBoundingBox(
        {
          width: width * scaleX,
          height: height * scaleY,
          left: x,
          top: y,
        },
        1 / multiplier,
      ),
    );
  };

  useEffect(() => {
    imageRef.current?.scaleX(1);
    imageRef.current?.scaleY(1);
  }, [boundingBox]);

  const stageRef = useRef<Konva.Stage>(null);
  return (
    <Stage ref={stageRef} width={canvasWidth} height={canvasHeight} x={0} y={0}>
      <Layer>
        {backgroundImage && <KonvaImage width={canvasWidth} height={canvasHeight} image={backgroundImage} cornerRadius={10} />}
        {ready && (
          <>
            <KonvaImage
              draggable
              x={adjustedBoundingbox.left}
              y={adjustedBoundingbox.top}
              width={adjustedBoundingbox.width}
              height={adjustedBoundingbox.height}
              image={productImage}
              ref={imageRef}
              onDragEnd={(e) => {
                getAttributesFromEvent(e.currentTarget);
              }}
              dragBoundFunc={(vec: Konva.Vector2d) => {
                if (!imageRef.current || !stageRef.current) {
                  return vec;
                }
                const newVec = { ...vec };
                if (vec.x < 0) {
                  newVec.x = 0;
                }
                if (vec.y < 0) {
                  newVec.y = 0;
                }
                const {
                  attrs: { width, height, scaleX, scaleY },
                } = imageRef.current;
                const actualWidth = width * scaleX;
                const actualHeight = height * scaleY;
                if (vec.x + actualWidth > stageRef.current.width()) {
                  newVec.x = stageRef.current.width() - actualWidth;
                }
                if (vec.y + actualHeight > stageRef.current.height()) {
                  newVec.y = stageRef.current.height() - actualHeight;
                }

                return newVec;
              }}
              onTransformEnd={(e) => {
                getAttributesFromEvent(e.currentTarget);
              }}
            />
            <Transformer
              keepRatio={true}
              enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
              ref={transformerRef}
              rotateEnabled={false}
              anchorStyleFunc={(anchor) => {
                anchor.cornerRadius(1);
              }}
              flipEnabled={false}
              anchorStroke="#30C1FF"
              borderStroke="#30C1FF"
              boundBoxFunc={(oldBox, newBox) => {
                if (!stageRef.current) return oldBox;
                const { x, y, width, height } = newBox;
                const isOut = x < 0 || y < 0 || x + width > stageRef.current.width() || y + height > stageRef.current.height();
                if (isOut) {
                  return oldBox;
                }
                return newBox;
              }}
              onTransformEnd={(e) => {
                getAttributesFromEvent(e.currentTarget);
              }}
            />
          </>
        )}
      </Layer>
    </Stage>
  );
};
