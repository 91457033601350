import { Asset } from '@amzn/genaihub-typescript-client';
import { Skeleton } from '@mui/material';
import { CSSProperties, MouseEventHandler, useEffect, useRef, useState } from 'react';
import { AssetType } from 'src/components/imageModal/components/utils';
import styles from './AssetContentCard.module.scss';
import PlayIcon from '../../../images/icons/playIcon.svg';
import VideoIcon from '../../../images/icons/videoicon.svg';
import ViewIcon from '../../../images/icons/viewicon.svg';

export const TEST_ID_ASSET_CONTENT_CARD = 'asset-content-card';
export const TEST_ID_ASSET_CONTENT_CARD_OVERLAY = 'asset-content-card-overlay';
export const TEST_ID_ASSET_CONTENT_CARD_ICON_VIDEO = 'asset-content-card-icon-video';
export const TEST_ID_ASSET_CONTENT_CARD_ICON_VIEW = 'asset-content-card-icon-view';

export interface AssetGalleryProps {
  asset?: Asset;
  showOverlay?: boolean;
  controls?: boolean;
  autoPlayTime?: number;
  viewIcon?: boolean;
  fixedSize?: boolean;
  clickHandler?: MouseEventHandler<HTMLDivElement>;
  loadedCallback?: () => void;
  className?: string;
  style?: CSSProperties;
  containerTestId?: string;
  referenceId?: string;
}

export default function AssetContentCard(props: AssetGalleryProps) {
  const { asset, showOverlay, controls, autoPlayTime, fixedSize, viewIcon, className, style, clickHandler, loadedCallback } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const videoTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const video = useRef<HTMLVideoElement>(null);
  const [isVideoPlaying, setVideoPlaying] = useState<boolean>(false);
  const previousAsset = useRef(asset);

  const onLoad = () => {
    setIsLoaded(true);
    loadedCallback?.();
  };

  const onMouseEnter = () => {
    if (!controls && video.current) {
      if (autoPlayTime) {
        video.current.currentTime = 0;
      }
      // play is asyncronous and can throw an error
      video.current.play().catch((e) => console.error(e));

      // set up a time out to pause the video
      if (autoPlayTime) {
        if (videoTimeout.current) {
          clearTimeout(videoTimeout.current);
        }
        videoTimeout.current = setTimeout(() => {
          if (video.current) {
            video.current.pause();
            video.current.currentTime = 0;
          }
        }, autoPlayTime);
      }
    }
  };

  const onMouseLeave = () => {
    if (!controls && video.current) {
      video.current.pause();

      if (autoPlayTime) {
        video.current.currentTime = 0;
        if (videoTimeout.current) {
          clearTimeout(videoTimeout.current);
        }
      }
    }
  };

  useEffect(() => {
    preLoadVideo();

    return () => {
      if (videoTimeout.current) {
        clearTimeout(videoTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    // update the current previous asset with the current one
    if (previousAsset.current?.uri !== asset?.uri) {
      previousAsset.current = asset;
      // if the asset changes and it had loaded reload the video and update state
      if (isLoaded) {
        preLoadVideo();
        setIsLoaded(false);
      }
    }
  }, [isLoaded, asset]);

  function preLoadVideo() {
    if (asset?.type === AssetType.VIDEO) {
      try {
        video.current?.load();
      } catch (err) {
        console.log('Error pre-loading AssetContentCard video', asset?.uri);
      }
    }
  }

  return (
    <div
      className={`asset-card ${styles['asset-card']} ${showOverlay ? styles['show-overlay'] : ''} ${fixedSize ? styles['fixed-size'] : ''} ${
        isLoaded ? styles.loaded : ''
      } ${className ? className : ''}`}
      onClick={clickHandler}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={style}
      data-testid={props.containerTestId ?? `${TEST_ID_ASSET_CONTENT_CARD}${asset?.uri ? `-${asset?.uri}` : ''}`}
    >
      {asset?.type === AssetType.IMAGE ? (
        <img
          className={`${styles.content} ${styles.image}`}
          srcSet={asset.uri}
          src={asset.uri}
          alt={asset.uri}
          data-testid={props.referenceId ?? `${TEST_ID_ASSET_CONTENT_CARD}-image-${asset?.uri}`}
          onLoad={onLoad}
          loading="lazy"
        />
      ) : asset?.type === AssetType.VIDEO ? (
        <>
          {!(showOverlay || controls || isVideoPlaying) && (
            <div className={styles.videoIcon}>
              <PlayIcon />
            </div>
          )}
          <video
            ref={video}
            className={`${styles.content} ${styles.video}`}
            onLoadStart={onLoad}
            onPlaying={() => setVideoPlaying(true)}
            onPause={() => setVideoPlaying(false)}
            preload={showOverlay ? 'auto' : 'metadata'}
            controls={controls}
            playsInline={!controls}
            data-testid={props.referenceId ?? `${TEST_ID_ASSET_CONTENT_CARD}-video-${asset?.uri}`}
            muted
            loop
          >
            <source src={asset.uri} type="video/mp4" />
          </video>
        </>
      ) : (
        <Skeleton data-testid={`${TEST_ID_ASSET_CONTENT_CARD}-placeholder`} className={styles.placeholder} variant="rounded" animation={false} />
      )}
      {asset && asset?.type && !isLoaded && (
        <Skeleton data-testid={`${TEST_ID_ASSET_CONTENT_CARD}-placeholder`} className={`${styles.placeholder} ${styles.loading}`} variant="rounded" />
      )}
      {asset && showOverlay && isLoaded && (
        <div data-testid={TEST_ID_ASSET_CONTENT_CARD_OVERLAY} className={`overlay ${styles.overlay}`}>
          {asset?.type === AssetType.VIDEO && (
            <div className={`icon ${styles.icon} ${styles.video}`}>
              <VideoIcon data-testid={TEST_ID_ASSET_CONTENT_CARD_ICON_VIDEO} />
            </div>
          )}
          {viewIcon && (
            <div className={`${styles.icon} ${styles.view}`}>
              <ViewIcon data-testid={TEST_ID_ASSET_CONTENT_CARD_ICON_VIEW} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
