import { ContentItem } from '@amzn/red-carpet-service-client';
import { Text } from '@amzn/storm-ui';
import { format } from 'date-fns';
import { useCallback, useContext } from 'react';
import { AsinSummary } from 'src/components/assistant/model';
import useConversationId from 'src/components/assistant/utils/useConversationId';
import { ConversationContext } from 'src/components/pages/conversational/ChatPage';
import styled from 'styled-components';

const Wrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.micro};
  position: relative;
  padding: ${({ theme }) => `${theme.spacing.medium} ${theme.spacing.base}`};
  background: ${({ isSelected, theme }) => (isSelected ? theme.palette.gray[50] : 'transparent')};
  border-radius: ${({ isSelected }) => (isSelected ? '12px' : '0px')};
  cursor: pointer;
  border-color: ${({ isSelected }) => (isSelected ? 'transparent !important' : 'auto')};

  && + div[data-testid='chat-list-item'] {
    border-color: ${({ isSelected }) => (isSelected ? 'transparent !important' : 'auto')};
  }
`;

const DateText = styled(Text)<{ isSelected: boolean }>`
  color: ${({ isSelected, theme }) => (isSelected ? '#4C40D6' : theme.palette.gray[600])};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0.02em;
  text-align: left;
`;

const Summary = styled(Text)`
  font-size: 15px;
  font-style: italic;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const PreviewImage = styled.img`
  width: 45px;
  height: 45px;
  object-position: 50% 50%;
  object-fit: cover;
  border-radius: 7px;
`;

const PreviewExtraImages = styled.div`
  width: 45px;
  height: 45px;
  background-color: ${({ theme }) => theme.palette.gray[500]};
  color: ${({ theme }) => theme.palette.white};
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.small};
`;

const AssistsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.micro};
`;

const AsinDivider = styled.div`
  height: 45px;
  width: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
`;

interface Props {
  summary?: string;
  chatId?: string;
  updatedAt?: Date;
  asin?: AsinSummary;
  assets?: ContentItem[];
}

export default function Item({ summary, chatId, updatedAt, asin, assets }: Props) {
  const { setIsSideNavOpen, compactScreen } = useContext(ConversationContext);
  const { conversationId, setConversationId } = useConversationId();

  const switchChat = useCallback(
    (chatId: string) => {
      setConversationId(chatId);
      if (compactScreen) setIsSideNavOpen(false);
    },
    [compactScreen],
  );

  return (
    <Wrapper isSelected={chatId === conversationId} data-testid="chat-list-item" onClick={() => chatId && switchChat(chatId)}>
      {updatedAt && (
        <DateText isSelected={chatId === conversationId} fontSize="small">
          {format(updatedAt, 'dd MMMM yyyy')}
        </DateText>
      )}
      <Summary data-testid="chat-list-item-summary">{summary}</Summary>
      {(asin || assets?.length) && (
        <ImagesWrapper>
          {asin && <PreviewImage data-testid="chat-list-item-asin" src={asin?.image} alt={asin?.title} />}
          {asin && !!assets?.length && <AsinDivider />}
          {assets?.length && (
            <AssistsWrapper>
              {assets.slice(0, 3).map((asset, index) => {
                if (asset.image) return <PreviewImage data-testid="chat-list-item-asset-preview" key={index} src={asset.image?.source} />;
                throw new Error('Unsupported asset type');
              })}
              {assets.length > 3 && <PreviewExtraImages>+{assets.length - 3}</PreviewExtraImages>}
            </AssistsWrapper>
          )}
        </ImagesWrapper>
      )}
    </Wrapper>
  );
}
