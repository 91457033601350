import { createTheme } from '@mui/material';

const defaultTheme = createTheme({
  palette: {
    primary: { main: '#FF5401' },
    background: {
      default: '#FAFAFA',
    },
  },
  typography: {
    fontFamily: ['Amazon Ember', '"Helvetica Neue"', 'Arial'].join(','),
    h6: {
      fontFamily: 'Amazon Ember Monospace',
      color: 'var(--Text-Base, #232F3F)',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '150%',
      textTransform: 'capitalize',
    },
    h5: {
      fontFamily: 'Amazon Ember Monospace',
      color: 'black',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    h4: {
      fontFamily: 'Amazon Ember Monospace',
      color: 'black',
      fontSize: '28px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    h2: {
      fontFamily: 'Amazon Ember Monospace',
      color: 'black',
      fontSize: '37px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '44px',
    },
    body1: {
      fontFamily: 'Amazon Ember',
      color: 'black',
      fontSize: '17px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '21px',
    },
    subtitle1: {
      fontFamily: 'Amazon Ember Monospace',
      color: '#FFF',
      fontSize: '35px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    subtitle2: {
      color: '#FFF',
      fontFamily: 'Amazon Ember',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineWeight: 'normal',
    },
  },
  components: {
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: false, // No more ripple, on the whole application 💣!
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {},
        containedPrimary: {
          // Some CSS
          fontFamily: 'Amazon Ember Monospace',
          textTransform: 'capitalize',
          cursor: 'pointer',
          color: '#111',
          border: '1px solid',
          borderColor: '#F0C246 #EBBC3C #BF9A3B',
          background: 'linear-gradient(#F9CF6F, #F6BD42 80%, #F6BD41)',
          borderRadius: '3px',
          fontSize: '1rem',
          fontStyle: 'normal',
          fontWeight: '400',
          padding: '5px 5px',
          textDecoration: 'none',
          textAlign: 'center', // this is only to override small buttons < 10rem width
        },
        containedSecondary: {
          display: 'inline',
          color: 'black',
          borderColor: '#D9D9D9 #CCC #B3B3B3',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: '3px',
          background: 'linear-gradient(#EDEDED,#E2E2E2)',
          fontSize: '1.313rem',
          fontStyle: 'normal',
          fontWeight: '500',
          padding: '5px 5px',
          minWidth: '8rem',
        },
      },
    },
  },
});

export default defaultTheme;
