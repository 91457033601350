import { Box, Card, Divider, Stack, Typography } from '@mui/material';
import { Fragment, ReactElement, useContext } from 'react';
import { ErrorText } from 'src/components/common/alerts/errorText';
import { positions } from './Editor';
import { AppContext } from '../../../../AppContext';
import * as ControlComponents from '../UiContols/uiGeneratorControls';
import Tooltip from '../UiContols/uiGeneratorControls/mui/Tooltip';

import './UIGenerator.scss';

interface Control {
  controlType: string;
  controlName: string;
  controlLabel?: string;
  visible: boolean;
  parent?: boolean;
  tooltip?: string;
  section?: string;
  order: number;
}

interface UIGeneratorProps {
  config?: Control[];
  submit?: () => void;
}

const UIGenerator = ({ config = [], submit = () => {} }: UIGeneratorProps): ReactElement => {
  const controls = config;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const controlComponents: any = ControlComponents;
  const appContext = useContext(AppContext);

  const selectControlComponent = (controlType: string) => {
    return controlType in controlComponents ? controlComponents[controlType] : () => {};
  };

  const createControlBody = (item: Control, index: number) => {
    const ControlComponent = selectControlComponent(item.controlType);
    return (
      <Fragment>
        <Box key={item.controlName + '_' + index}>
          {item.parent && (
            <>
              {item.controlLabel && item.visible && (
                <Stack flex={1} direction="row" gap={0.5} alignItems={'center'} margin={1}>
                  <Typography fontSize={'0.9rem'} textAlign={'left'} fontWeight={600}>
                    {item.controlLabel}
                  </Typography>
                  {item.tooltip && <Tooltip title={item.tooltip} />}
                </Stack>
              )}
            </>
          )}

          {item.visible && <ControlComponent control={item} submit={submit} createControl={createControlBody} />}
          {appContext.alertEvent?.control?.controlName === item.controlName &&
            appContext.alertEvent?.control?.position !== positions.canvasBottom && (
              <Box display={'flex'}>
                <ErrorText {...appContext.alertEvent} />
              </Box>
            )}
        </Box>
      </Fragment>
    );
  };

  const Blank = () => <></>;

  const createControlCard = (item: Control | undefined, index: number): ReactElement => {
    if (!item) return <Blank key={'blank' + index} />;

    const control = (
      <>
        {item.visible && (
          <>
            {!item.parent && (
              <>
                <Card className="controlBox" key={'card_' + index} sx={{ borderRadius: '8px', flexShrink: 0 }} variant="outlined">
                  {item.section && (
                    <>
                      <Box sx={{ padding: '16px', display: 'flex', alignItems: 'center' }} gap={1}>
                        <Typography fontSize={17} textAlign={'left'} fontWeight={600}>
                          {item.section}
                        </Typography>
                        {item.tooltip && <Tooltip title={item.tooltip} />}
                      </Box>
                      <Divider />
                    </>
                  )}
                  {createControlBody(item, index)}
                </Card>
              </>
            )}
          </>
        )}
      </>
    );

    return control;
  };

  return (
    <>
      {controls.length === 0 && <></>}
      {controls.length > 0 && <></> &&
        controls.sort((a, b) => a.order - b.order).map((item: Control, index: number) => createControlCard(item, index))}
    </>
  );
};

export default UIGenerator;
