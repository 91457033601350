import { GenAISparkleIcon } from '@amzn/storm-ui';
import React from 'react';
import { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './heroGenerate.module.scss';
import { AppContext } from '../../../AppContext';
import BasicTextInputWithSubmit from '../editor/UiContols/uiGeneratorControls/storm/BasicTextInputWithSubmit';

const HeroGenerate = () => {
  const tool = 'LIFESTYLE_IMAGERY_PROMPT_ONLY';
  const path = '/studio';
  // const backgroundImages = ['https://dcs5n60wvlme6.cloudfront.net/static-ui-assets/hero/heroStatic.jpg'];
  const backgroundVideos = ['https://dcs5n60wvlme6.cloudfront.net/static-ui-assets/hero/herovideoslow 1912x720.mp4'];
  const linkTo = useNavigate();
  const context = useContext(AppContext);
  const generateText = useRef('');
  const placeholderText = 'Add an ASIN or describe your image'; //  (Example: Product placed on a kitchen counter or B0BFZVFG6N)

  const clickHandler = (text: string) => {
    generateText.current = text;
    if (generateText.current.length > 0) {
      // if (context.selectedTool === tool) {
      redirect();
      // } else {
      // context.setSelectedTool(tool);
      // window.localStorage.setItem('selectedTool', tool);
      // }
    }
  };

  const redirect = () => {
    let _path = `${path}?generate=true`;
    if (generateText.current.length === 10 && generateText.current.replace(/[A-Z0-9]/g, '') === '') _path += `&ASIN=${generateText.current}`;
    else _path += `&prompt=${generateText.current}`;
    linkTo(_path);
  };

  useEffect(() => {
    if (context.selectedTool === tool && generateText.current.length > 0) {
      redirect();
    }
  }, [context.selectedTool]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.videoBackground}>
        <video autoPlay muted loop data-testid="hero-background-video">
          <source src={backgroundVideos[0]} type="video/mp4" />
        </video>
      </div>
      <div className={styles.transparentOverlay}></div>
      <div
        className={styles.content}
        onClick={(e: React.MouseEvent) => {
          const el = e.target as HTMLElement;

          if ((el.nodeName === 'P' && el.parentNode?.nodeName === 'BUTTON') || el.nodeName === 'INPUT' || el.nodeName === 'BUTTON') {
            // cases we don't want to do the click action
            return;
          } else {
            linkTo(path);
          }
        }}
        data-testid="hero-page-link"
      >
        <text className={styles.mainTitle}>STUDIO</text>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            marginBottom: '20px',
          }}
        >
          <text className={styles.title}>What would you</text>
          <text className={styles.title}>like to create today?</text>
        </div>

        <BasicTextInputWithSubmit
          text="Generate"
          placeholderText={placeholderText}
          useReturn={true}
          clickHandler={clickHandler}
          inputTestId="hero-prompt-input"
          submitButtonTestId="hero-generate-button"
        >
          <GenAISparkleIcon />
        </BasicTextInputWithSubmit>
      </div>
    </div>
  );
};

export default HeroGenerate;
