import { AppBar, Button, Container, Toolbar, Typography, Stack } from '@mui/material/';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styleGuide from 'src/config/themes/styleGuide';
import { Metrics } from 'src/constants';
import { AppContext } from '../../../../AppContext';
import { navigation } from '../../../config';
import Logo from '../../common/Logo';
import AppBarMenu from '../../menus/AppBarMenu';
import { getPageName } from '../../utils/getPageName';

//test

function ResponsiveAppBar() {
  const context = useContext(AppContext);
  const { metrics } = context;
  const activeNav = { fontWeight: '700' };

  const linkTo = useNavigate();

  const handleNavButtonClick = (path: string) => {
    metrics.trackMetrics(
      Metrics.Methods.NavBarClick,
      { [Metrics.Names.ButtonId]: getPageName(path) || Metrics.Values.Home },
      { [Metrics.Counters.Clicks]: 1 },
    );
    linkTo(path);
  };

  return (
    <AppBar style={{ backgroundColor: 'white', boxShadow: 'none' }}>
      <Container maxWidth={false} style={{ padding: 0, marginRight: 'auto' }}>
        <Toolbar className="page-margin" style={{ padding: 0 }}>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}>
            <Logo />
          </Typography>

          <Stack data-testid="header-nav-menu" direction={'row'} justifyContent={'center'} alignItems={'center'} gap={0}>
            {navigation.map((item, index) => (
              <Button
                variant="text"
                key={index}
                sx={{ ...styleGuide.navButton, padding: 0, margin: 0, minWidth: '70px' }}
                onClick={() => {
                  handleNavButtonClick(item.path);
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ ...styleGuide.navButtonText }}
                  style={item.label.toLocaleLowerCase() === context.activePage ? activeNav : {}}
                >
                  {item.label}
                </Typography>
              </Button>
            ))}

            {context.authenticated ? (
              <AppBarMenu />
            ) : (
              <Button variant="contained" sx={{ ...styleGuide.primaryButtonHover }}>
                Sign up
              </Button>
            )}
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
