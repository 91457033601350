import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import { useContext } from 'react';
import './SignIn.scss';
import { AUTH_PORTAL_ASSOCIATION_HANDLER } from 'src/constants';
import { AppContext } from '../../../AppContext';

interface SignInProps {
  open: boolean;
  handleClose: () => void;
}

export default function SignIn(props: SignInProps) {
  const context = useContext(AppContext);
  const handleClose = () => {
    props.handleClose();
  };

  const onClickLogin = () => {
    const data = {
      clientContext: 'value1',
      'openid.return_to': `${window.location.origin}/home/editor`,
      'openid.identity': 'http://specs.openid.net/auth/2.0/identifier_select',
      'openid.assoc_handle': AUTH_PORTAL_ASSOCIATION_HANDLER,
      'openid.mode': 'checkid_setup',
      'openid.claimed_id': 'http://specs.openid.net/auth/2.0/identifier_select',
      'openid.ns': 'http://specs.openid.net/auth/2.0',
      mons_redirect: 'sign_in',
    };
    const queryParams = new URLSearchParams(data);
    window.location.replace(`https://development.amazon.com/ap/signin?${queryParams}`);
  };

  return (
    <>
      <Dialog onClose={handleClose} open={props.open}>
        <DialogTitle>
          <b>Sign in or create account</b>
        </DialogTitle>
        <DialogContent id={'dialog-content'}>
          <Button variant="outlined" onClick={onClickLogin} color={context.colorScheme}>
            <img src="http://g-ecx.images-amazon.com/images/G/01/lwa/btnLWA_gry_32x32._CB372227043_.png" alt="" />
            &nbsp;Continue with Amazon
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
