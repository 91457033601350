function getQueryStringParam(param: string, url: string = window.location.href): string | null {
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  return params.get(param);
}

function getAllQueryStringParams(url: string = window.location.href): Record<string, string> {
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  const paramObj: Record<string, string> = {};

  params.forEach((value, key) => {
    paramObj[key] = value;
  });

  return paramObj;
}

export { getQueryStringParam, getAllQueryStringParams };
