export async function getErrorMessage(error: unknown | Error | Response): Promise<string> {
  let message = '';
  if (error instanceof Response) {
    message = `${error.status}`;
    let body = await error.clone().json();

    if (error.statusText && error.statusText !== '') {
      message += `: ${error.statusText}`;
    } else if (body) {
      message += `: ${JSON.stringify(body)}`;
    }
  } else if (error instanceof Error) {
    message = error.message;
  }

  return message;
}
