import { RetrieveAssetsOutput } from '@amzn/genaihub-typescript-client';
import { useContext, useEffect, useRef, useState } from 'react';
import { convertFeed } from 'src/components/pages/studio/contentTile/convertFeed';
import { ContentItem, StudioContext } from 'src/components/pages/studio/StudioContext';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { AICS_WEBLAB_FEEDS_DEACTIVATION, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { getIsManagerAccount } from 'src/v2/utils/utils';
import { AppContext } from '../../../../AppContext';

const INITIAL_PAGE_SIZE = 12;
const SUBSEQUENT_PAGE_SIZE = 12;

export const useFeedImages = () => {
  const [assetsNextToken, setAssetsNextToken] = useState<string | undefined>('');
  const [callFeeds, setCallFeeds] = useState<boolean>(false);
  const [emptyPlaceholders, setEmptyPlaceholders] = useState<ContentItem[] | undefined>([]);
  const [isFeedsCallRunning, setIsFeedsCallRunning] = useState(false);
  const [secondFeedsCall, setSecondFeedsCall] = useState(false);
  const [isManagerAccount, setIsManagerAccount] = useState(false);
  const [feedImages, setFeedImages] = useState<ContentItem[] | undefined>([]);

  const studioContext = useContext(StudioContext);
  const appContext = useContext(AppContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const genAIBackendClient = useAIBackendHubClient();

  // Asset Feed Effects
  const generateEmptyPlaceholders = (aspectRatio: string | undefined, num: number) => {
    return Array<ContentItem>(num).fill({ loading: false, aspectRatio, isEmptyPlaceholder: true });
  };

  const getNextAsset = async () => {
    if (!isFeedsCallRunning && secondFeedsCall && assetsNextToken) {
      getAssets(assetsNextToken);
    }
  };

  const getAssets = async (nextToken?: string) => {
    if (isWeblabInTreatment(AICS_WEBLAB_FEEDS_DEACTIVATION, WeblabTreatment.T1) || isManagerAccount) {
      return setCallFeeds(false);
    }

    if (isFeedsCallRunning || nextToken === undefined) return;

    let response: RetrieveAssetsOutput;
    setIsFeedsCallRunning(true);

    try {
      if (nextToken != '') {
        setEmptyPlaceholders(generateEmptyPlaceholders(studioContext.format, SUBSEQUENT_PAGE_SIZE));
        response = await genAIBackendClient.retrieveAssets({
          pageSize: SUBSEQUENT_PAGE_SIZE,
          cursor: nextToken,
          entityId: appContext.selectedAdvertisingAccount?.alternateIds?.[0],
        });
        setSecondFeedsCall(false);
      } else {
        setEmptyPlaceholders(generateEmptyPlaceholders(studioContext.format, INITIAL_PAGE_SIZE));
        response = await genAIBackendClient.retrieveAssets({
          pageSize: INITIAL_PAGE_SIZE,
          entityId: appContext.selectedAdvertisingAccount?.alternateIds?.[0],
        });
        setSecondFeedsCall(true);
      }

      // filter out unexpected nulls in response
      const results = response.body.assets?.filter((item) => !!item) || [];
      const isPlayground = appContext.activePage === 'playground';
      const convertedContents = await convertFeed(genAIBackendClient, results, isPlayground);

      setAssetsNextToken(response.body.nextToken);
      if (feedImages) {
        setFeedImages(feedImages.concat(convertedContents));
      }

      // Uncomment below if needed later when implementing this hook in studio context
      // studioContext.appendResults(convertedContents);
    } catch (error) {
      console.error('Feeds: Error while fetching assets for feed', error);
    } finally {
      setIsFeedsCallRunning(false);
      // Scroll up by 1 px to prevent an edge case where user reach the end of scollable area
      // while there are more content to load
      const container = containerRef.current;
      if (container && Math.ceil(container.scrollTop + container.clientHeight) >= container.scrollHeight) {
        container.scrollTop -= 1;
      }
    }
  };

  useEffect(() => {
    // update the aspect ratio of the tile whenever user change the selection
    setEmptyPlaceholders(generateEmptyPlaceholders(studioContext.format, SUBSEQUENT_PAGE_SIZE));
  }, [studioContext.format]);

  // call feeds only if account is non-manager type and/or there's already a local account saved
  useEffect(() => {
    if (getIsManagerAccount(appContext)) {
      setIsManagerAccount(true);
      return;
    }
    setIsManagerAccount(false);
    setCallFeeds(true);
  }, [appContext.accountType, appContext.selectedAdvertisingAccount]);

  useEffect(() => {
    getNextAsset();
  }, [isFeedsCallRunning]);

  useEffect(() => {
    if (callFeeds) getAssets('');
  }, [callFeeds]);

  return {
    assetsNextToken,
    emptyPlaceholders,
    feedImages,
    getAssets,
    isFeedsCallRunning,
  };
};
