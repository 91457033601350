import { SyntheticEvent } from 'react';

export function getApiBaseUrl() {
  const hostname = window.location.hostname;
  if (process.env.NODE_ENV === 'development') {
    return retrieveApiUrlBasedOnDeveloperAccount();
  } else if (hostname.includes('beta.ailab.amazon')) {
    return `https://beta.ailab.amazon/beta`;
  } else if (hostname.includes('gamma.ailab.amazon')) {
    return `https://gamma.ailab.amazon/gamma`;
  } else if (hostname.includes('beta.aistudio.amazon')) {
    return `https://beta.aistudio.amazon/beta`;
  } else if (hostname.includes('gamma.aistudio.amazon')) {
    return `https://gamma.aistudio.amazon/gamma`;
  } else if (hostname.includes('aistudio.amazon')) {
    return `https://aistudio.amazon/prod`;
  } else {
    return `https://ailab.amazon/prod`;
  }
}

export function isProd() {
  const prodUrls = ['https://ailab.amazon/prod', 'https://aistudio.amazon/prod'];
  return prodUrls.includes(getApiBaseUrl());
}

function retrieveApiUrlBasedOnDeveloperAccount() {
  const baseDevelopmentUrl = `https://beta.aistudio.amazon/beta`;
  return process.env.ALIAS ? `${baseDevelopmentUrl}-${process.env.ALIAS}` : baseDevelopmentUrl;
}

export const handleStormOuterModalClick = (event: SyntheticEvent, setterAction: () => void): void => {
  const element = event.nativeEvent.target as HTMLElement;
  if (!element) return;
  const isModalOuterContainer = element.getAttribute('role') === 'presentation';
  if (isModalOuterContainer) {
    setterAction();
  }
};
