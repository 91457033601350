import { ContentItem, Message, MessageRole } from '@amzn/red-carpet-service-client';
import React from 'react';
import Header from 'src/components/assistant/components/messageWrapper/header';
import styled from 'styled-components';
import { HeaderConfig } from '../../model';
import { CHAT_THEME } from '../../theme';

export const MessagePillWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${CHAT_THEME.border.rounded.radius};
  background: ${CHAT_THEME.color.aiPurple};
  padding-top: ${(props) => props.theme.spacing.small};
  padding-bottom: ${CHAT_THEME.spacing.smallPlus};
  padding-inline: ${CHAT_THEME.spacing.basePlus};
  gap: ${(props) => props.theme.spacing.mini};
  color: ${(props) => props.theme.palette.white};
  max-width: 800px;

  &.${MessageRole.ASSISTANT} {
    background: ${(props) => props.theme.palette.gray[50]};
    color: unset;
    max-width: ${CHAT_THEME.maxImageHeight * 1.91 + CHAT_THEME.spacing.smallPlus + 2}px;
  }
`;

interface Props {
  message: Message;
  headerConfig?: HeaderConfig;
  onReply?: (contentItem: ContentItem[]) => void;
  children: React.ReactNode;
}

export default function MessagePill({ message, headerConfig, onReply, children }: Props) {
  const handleReply = () => {
    if (!message.content || !onReply) return;
    onReply(message.content);
  };

  const showHeader = headerConfig?.hasActions || headerConfig?.name;

  return (
    <MessagePillWrapper data-testid={'message-pill'} className={message.role}>
      {showHeader && <Header role={message.role} headerConfig={headerConfig} onReply={handleReply} message={message} />}
      {children}
    </MessagePillWrapper>
  );
}
