import { useBundle } from '@amzn/react-arb-tools';
import { Box, Button, Container } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useRouteError } from 'react-router-dom';
import SignIn from 'src/components/signin/Signin';
import { Metrics } from 'src/constants';
import { ALERTS_MESSAGE_BUNDLE_NAME } from 'src/constants';
import { t } from 'src/helpers';
import { AppContext } from '../../../AppContext';
import { getPageName } from '../utils/getPageName';

const ErrorPage = () => {
  let error = useRouteError();
  const [bundle] = useBundle(ALERTS_MESSAGE_BUNDLE_NAME);
  const context = useContext(AppContext);
  const { metrics } = context;
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const linkTo = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const handleClickEvent = (page: string) => {
    metrics.trackMetrics(
      Metrics.Methods.ErrorPage,
      { [Metrics.Names.ButtonId]: Metrics.Buttons.GoBack, [Metrics.Names.Destination]: getPageName(page) },
      { [Metrics.Counters.Clicks]: 1 },
    );

    linkTo(page);
  };
  console.log(error);
  const nullSafeState = state || {};
  return (
    <Container
      className="mainBoxShadow"
      maxWidth="xl"
      sx={{ mt: '3em', mb: '3em', backgroundColor: 'var(--background-color-variant-2)', borderRadius: '8px' }}
    >
      <Box
        display={'flex'}
        gap={'10px'}
        flexDirection={'column'}
        sx={{
          position: 'relative',
          p: { xs: 3, md: 6 },
          pr: { md: 0 },
          textAlign: 'center',
        }}
      >
        <Box display={'flex'} justifyContent={'center'}>
          <div
            style={{
              borderRadius: '50%',
              background: 'linear-gradient(180deg, #6236FF 50%, #FFFFFF 100%)',
              width: '31px',
              height: '31px',
              position: 'relative',
              left: '0.4%',
            }}
          ></div>
          <div
            style={{
              borderRadius: '50%',
              border: '2px solid red',
              backgroundColor: 'transparent',
              width: '31px',
              height: '31px',
              position: 'relative',
              right: '0.4%',
            }}
          ></div>
        </Box>
        <h2>{nullSafeState['message'] || t(bundle, 'generic_error_text')}</h2>
        <Container maxWidth="xl" sx={{ textAlign: 'center', paddingBottom: '10px' }}>
          {context.authenticated && (
            <Button
              style={{
                fontFamily: 'Amazon Ember',
                padding: '10px 18px 10px 18px',
                background: 'linear-gradient(90deg, #5E65FF 37.5%, #976AFF 100%)',
                borderRadius: '100px',
              }}
              variant="contained"
              size="medium"
              color="primary"
              onClick={() => {
                handleClickEvent(nullSafeState['route'] || '/');
              }}
            >
              {nullSafeState['name'] || 'Refresh page'}
            </Button>
          )}
        </Container>
        <a style={{ textDecoration: 'none', fontSize: '0.95rem', color: '#6236FF' }} href={window.location.origin}>
          Back to homepage
        </a>
      </Box>
      <SignIn open={showSignInDialog} handleClose={() => setShowSignInDialog(false)} />
    </Container>
  );
};

export default ErrorPage;
