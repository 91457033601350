// FIXME: move to the smithy model
import { Conversation } from '@amzn/red-carpet-service-client';

export interface HeaderConfig {
  name?: string;
  nameColor?: string;
  imageUrl?: string;
  hasActions?: boolean;
}

export interface AsinSummary {
  asin: string;
  title: string;
  image?: string;
}

export type ConversationModel = Omit<Conversation, 'metadata'> & { summary?: string; asin?: AsinSummary };

export function apiConversationToConversationModel(apiConversation: Conversation): ConversationModel {
  const summary = (apiConversation.metadata?.find((metadataItem) => metadataItem.name === 'summary')?.content as { content?: string }).content;
  const asin = apiConversation.metadata?.find((metadataItem) => metadataItem.name === 'asin_summary')?.content as AsinSummary | undefined;
  return {
    ...apiConversation,
    summary,
    asin,
  };
}
