import { ContentItem } from '@amzn/red-carpet-service-client';
import { Text } from '@amzn/storm-ui';
import { CHAT_THEME } from 'src/components/assistant/theme';
import styled from 'styled-components';

// FIXME: this is a copy-paste from the MessageReply component until we have UI mocks for how this bubble
//  should look like in the chat history

interface Props {
  contentItem?: ContentItem;
  quotedMessageId: string;
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const MessageBody = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${CHAT_THEME.spacing.microPlus};

  padding-inline: ${({ theme }) => theme.spacing.small};
  padding-block: ${CHAT_THEME.spacing.miniPlus};

  color: ${(props) => props.theme.palette.gray[700]};
  background-color: ${(props) => props.theme.palette.gray[50]};

  border-radius: 13px;
  max-width: 50%;
`;

const Title = styled(Text)`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`;

const Content = styled(Text)`
  font-size: 13px;
  line-height: 19.5px;
  text-align: left;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const imageHeight = 100;

const ImageContent = styled.img`
  width: ${imageHeight * 1.91}px;
  height: ${imageHeight}px;
  border-radius: 12px;
`;

export default function QuoteMessage({ contentItem }: Props) {
  return (
    <Wrapper>
      <MessageBody>
        <Title>replying to</Title>
        {contentItem?.text?.content && <Content>{contentItem.text?.content}</Content>}
        {contentItem?.image?.source && <ImageContent src={contentItem.image?.source} />}
      </MessageBody>
    </Wrapper>
  );
}
