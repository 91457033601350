import { Button } from '@amzn/storm-ui';
import React from 'react';
import styled from 'styled-components';

export const SecondaryButton = styled((props: React.ComponentProps<typeof Button>) => <Button {...props} transparent={true} />)`
  && {
    color: var(--AI-purple, #4305f4);
  }

  &&:hover {
    color: var(--AI-purple, #4305f4);
    background-color: transparent;
  }

  &&:disabled {
    color: var(--disabled-button-color);
  }
`;

export const SecondaryButtonInverted = styled((props: React.ComponentProps<typeof Button>) => <Button {...props} transparent={true} />)`
  && {
    color: white;
  }

  &&:hover {
    color: white;
    background-color: transparent;
  }

  &&:disabled {
    color: var(--disabled-button-color);
  }
`;
