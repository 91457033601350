import { Text } from '@amzn/storm-ui';

const Title = (props: any) => {
  const { title, style, className, ...passthrough } = props;
  return (
    <div style={{ ...style }} className={className} {...passthrough}>
      <Text
        style={{
          color: '#232F3F',
          fontFamily: 'Ember Modern Display Standard',
          fontSize: '44px',
          letterSpacing: '1px',
          fontWeight: '700',
          lineHeight: '42px',
        }}
      >
        {title}
      </Text>
    </div>
  );
};

export default Title;
