export interface LoadedImage {
  el: HTMLImageElement;
  height: number;
  width: number;
  url: string;
}

export interface GetImageFromUrlProps {
  url: string;
  onFetchDimensions?: (props: { width: number; height: number }) => void;
}

export function getImageFromUrl({ url, onFetchDimensions }: GetImageFromUrlProps): Promise<LoadedImage> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.crossOrigin = 'anonymous';

    const poll =
      onFetchDimensions &&
      setInterval(() => {
        if (image.naturalWidth || image.naturalHeight) {
          clearInterval(poll);
          onFetchDimensions({
            width: image.naturalWidth,
            height: image.naturalHeight,
          });
        }
      }, 100);

    image.onload = () => {
      if (poll) clearInterval(poll);
      resolve({
        el: image,
        height: image.naturalHeight,
        width: image.naturalWidth,
        url,
      });
    };

    image.onerror = () => {
      if (poll) clearInterval(poll);
      reject(`Error while getting image from url: '${url}'`);
    };
  });
}

// greatest common denominator
const gcd = (a: number, b: number): number => (!b ? a : gcd(b, a % b));

interface GetAspectRatioProps {
  width: number;
  height: number;
}

export function getAspectRatio({ width, height }: GetAspectRatioProps) {
  const _width = Math.abs(width);
  const _height = Math.abs(height);
  if (!_width && !_height) return '0:0';
  const gcdResult = gcd(_width, _height);
  return `${_width / gcdResult}:${_height / gcdResult}`;
}

interface GetAspectRatioFromFileProps {
  file: File;
}

export const getAspectRatioFromFile = async ({
  file,
}: GetAspectRatioFromFileProps): Promise<{ aspectRatio: string; file: File; width: number; height: number; url: string }> => {
  try {
    const url = URL.createObjectURL(file);
    const { width, height } = await getImageFromUrl({ url });
    const aspectRatio = getAspectRatio({ width, height });
    return { aspectRatio, file, width, height, url };
  } catch (err) {
    console.error('Error while loading file', err);
    return Promise.reject(`Error while getting aspect ratio of file: '${file.name}'`);
  }
};

export const AcceptedFileTypesForStudioImageUpload = ['image/png', 'image/jpeg'];
// Backend restricts user uploads to 20MB
export const MAX_FILE_SIZE = 20971520;

export function filterStudioImageUploadFiles({ files }: { files: File[] }) {
  let filtered = false;
  const _files = files.filter((file) => {
    if (file.size <= MAX_FILE_SIZE && AcceptedFileTypesForStudioImageUpload.includes(file.type)) {
      return true;
    } else if (file.name !== '.DS_Store') {
      filtered = true;
    }
    return false;
  });

  return {
    filtered,
    files: _files,
  };
}
