/* istanbul ignore file */
import { LocalizationContextBuilder, MessageBundle, LocalizationContext } from '@amzn/arb-tools';
import { AdvertisingAccountMetadata } from '@amzn/genaihub-typescript-client';
import { DEFAULT_LOCALE, selectedEntityLocalStorageKey } from './constants';
import translations from './i18n/translations';
import arbManifest from './i18n/translations/arbManifest';

const LOCALIZATION_CONTEXTS = new Map<string, LocalizationContext>();

export function getLocalizationContext(locale: string) {
  let lc = LOCALIZATION_CONTEXTS.get(locale);

  if (!lc) {
    lc = new LocalizationContextBuilder().withLocale(locale).withDefaultLocale(DEFAULT_LOCALE).build();

    LOCALIZATION_CONTEXTS.set(locale, lc);
  }

  return lc;
}

export function t(bundle: MessageBundle, stringId: string, args?: Record<string, unknown>) {
  if (!bundle || !stringId) {
    return undefined;
  }

  if (!args) {
    return bundle.getMessage(stringId);
  }

  return bundle.formatMessage(stringId, args);
}

export function getMbmOptions(arbManifest: { endpointMap: Record<string, Record<string, string>> }) {
  let currentLocale = DEFAULT_LOCALE;
  const documentLocale = document?.querySelector('html')!.getAttribute('lang');

  if (documentLocale) {
    currentLocale = documentLocale;
  }

  const localizationContext = getLocalizationContext(currentLocale);

  return {
    arbManifest,
    defaultLocalizationContext: localizationContext,
    resolveBundleUrl: (resourcePackPath: string | undefined, locale: string, bundle: string) =>
      resolveBundleUrlWithFallbacks(resourcePackPath, locale, bundle),
  };
}

export function resolveBundleUrlWithFallbacks(resourcePackPath: string | undefined, locale: string, bundle: string) {
  if (resourcePackPath && translations[resourcePackPath]) {
    return translations[resourcePackPath];
  }

  if (!resourcePackPath && arbManifest.endpointMap?.[locale]?.[bundle]) {
    return translations[arbManifest.endpointMap[locale][bundle]];
  }

  if (arbManifest.endpointMap?.[DEFAULT_LOCALE]?.[bundle]) {
    return translations[arbManifest.endpointMap[DEFAULT_LOCALE][bundle]];
  }

  return undefined;
}

interface Operation {
  method: string;
  requestUri: string;
}

export const operationExecuter = async (operation: Operation, request?: XMLHttpRequestResponseType) => {
  return fetch('https://genaihub.ads.amazon.dev/beta/genaihub' + operation.requestUri, {
    method: operation.method,
    credentials: 'include',
    body: request && JSON.stringify(request),
  });
};

export const aspectsRatios = {
  '1:1': {
    width: 1024,
    height: 1024,
  },
  '16:9': {
    width: 1344,
    height: 768,
  },
  '3:4': {
    width: 768,
    height: 1204,
  },
  '4:3': {
    width: 1024,
    height: 768,
  },
};

export const waitUntilImagesLoaded = (imageList: (string | undefined)[]) => {
  if (!(imageList && imageList.length > 0)) {
    return;
  }

  const validImages: string[] = imageList.filter((image) => !!image) as string[];

  return new Promise<void>((resolve) => {
    let counter = 0;

    validImages.forEach((image) => {
      const imgElement = new Image();
      const resolveImage = () => {
        counter++;
        if (counter === validImages.length) {
          resolve();
        }
      };

      imgElement.onload = () => {
        imgElement.onload = null;
        resolveImage();
      };
      imgElement.onerror = () => {
        imgElement.onerror = null;
        resolveImage();
      };
      imgElement.src = image;
    });
  });
};

export const sanitiseText = (text: string) => {
  return text.replace(/[^\p{L}\p{N} ,.]/gu, '');
};

export type KeysOfPropertyWithType<Type, Attribute> = {
  [Key in keyof Type]: Type[Key] extends Attribute ? Key : never;
}[keyof Type];

export const getSelectedAdvertisingAccountFromLocalStorage = () => {
  const objectAsJson = localStorage.getItem(selectedEntityLocalStorageKey);
  if (!objectAsJson) {
    return null;
  }

  try {
    return JSON.parse(objectAsJson) as AdvertisingAccountMetadata;
  } catch (e) {
    localStorage.removeItem(selectedEntityLocalStorageKey);
  }

  return null;
};

export const saveSelectedAdvertisingAccountToLocalStorage = (selectedAdvertisingAccount?: AdvertisingAccountMetadata | null) => {
  if (!selectedAdvertisingAccount) {
    return clearSelectedAdvertingAccountFromLocalStorage();
  }

  localStorage.setItem(selectedEntityLocalStorageKey, JSON.stringify(selectedAdvertisingAccount));
};

export const clearSelectedAdvertingAccountFromLocalStorage = () => {
  localStorage.removeItem(selectedEntityLocalStorageKey);
};
