import { Button } from '@amzn/storm-ui';
import { KeyboardEvent, ReactElement, SyntheticEvent } from 'react';
import { ContentItem } from 'src/components/pages/studio/StudioContext';
import { Image } from 'src/v2/components/Image/Image';
import styles from './ImageGrid.module.scss';
import { ImageGridProps } from './types';

export const ImageGrid = ({ componentLabel, images, onFeedSelect, scrollHandler }: ImageGridProps): ReactElement => {
  const handleMouseDown = (e: SyntheticEvent, item: ContentItem) => {
    onFeedSelect(e, item);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLButtonElement> | KeyboardEvent<HTMLAnchorElement>, item: ContentItem) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onFeedSelect(e, item);
    }
  };

  return (
    <div className={styles.imageGrid} data-testid={`image-grid-component-${componentLabel}`}>
      <div onScroll={scrollHandler} className={styles.images}>
        {images.map((item, index) => {
          if (item.assetType === 'VIDEO') return null;
          return (
            <Button
              data-testid={`image-grid-button-${index}`}
              key={`image-grid-button-${index}`}
              className={styles.buttonWrapper}
              onKeyDownCapture={(e) => handleKeyDown(e, item)}
              onMouseDown={(e) => handleMouseDown(e, item)}
            >
              <Image src={item} componentLabel={componentLabel} />
            </Button>
          );
        })}
      </div>
    </div>
  );
};
