import { Text } from '@amzn/storm-ui';
import { AsinSummary } from 'src/components/assistant/model';
import styled from 'styled-components';
import { CHAT_THEME } from '../../../theme';

interface Props {
  content: AsinSummary;
}

const AsinWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: ${(props) => props.theme.spacing.medium};
`;

const Metadata = styled.div`
  display: flex;
  flex-direction: column;
`;

const AsinImage = styled.img`
  max-width: ${CHAT_THEME.size.photo.base};
  max-height: ${CHAT_THEME.size.photo.base};
  border-radius: ${CHAT_THEME.border.roundedThin.radius};
`;

const AsinText = styled(Text)`
  opacity: 0.9;
`;

export default function AsinMessage({ content: { title, asin, image } }: Props) {
  return (
    <AsinWrapper data-testid={'asin-message'}>
      <AsinImage src={image} />
      <Metadata>
        <Text>{title}</Text>
        <AsinText fontSize="tiny">ASIN: {asin}</AsinText>
      </Metadata>
    </AsinWrapper>
  );
}
