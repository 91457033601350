import { Metadata, Operation, OperationExecutor, ShapeMemberMap } from '@amzn/genaihub-typescript-client/structures';
import * as KatalMetrics from '@amzn/katal-metrics';
import { Metrics } from 'src/constants';
import { MetricsProvider } from 'src/metrics';
import { CsrfTokenUtils } from 'src/util/csrfTokenUtils';
import { getApiBaseUrl, isProd } from 'src/util/util';

interface ReplacementValues {
  [key: string]: string;
}

const baseUrl = getApiBaseUrl();
console.log(baseUrl);
const includeCredentials = 'include';

export class OperationExecutorBuilder {
  public build(metrics: MetricsProvider): OperationExecutor {
    return async (metadata, operation, request) => {
      const httpMetricsPublisher = metrics.getMetrics().newChildActionPublisherForMethod(operation.name);
      const queryString = this.buildQueryString(request, operation.input?.members);
      const convertedUri = this.replaceValues(request, operation.http.requestUri);
      const isControlPanel = window.location.pathname.startsWith('/admin');
      let requestUrl = `${baseUrl}${convertedUri}${queryString}`;
      if (this.isNARegionRequest(operation)) {
        const tempUrl = new URL(requestUrl);
        tempUrl.pathname = '/na' + tempUrl.pathname;
        requestUrl = tempUrl.toString();
      }

      if (
        !isControlPanel &&
        (operation.name === 'RetrieveInspirationsList' || operation.name === 'RetrieveToolsList' || operation.name === 'RetrieveWorkflowsList')
      ) {
        const cache = await caches.open('v1');
        const body = request?.body ? `&req_body=${JSON.stringify(request.body)}` : '';
        const cachedResponse = await caches.match(`${baseUrl}${convertedUri}${queryString}${body}`);
        const expiryResponse = await caches.match(`${baseUrl}${convertedUri}${queryString}${body}&cache-expires`);

        if (isProd() && cachedResponse !== undefined && expiryResponse !== undefined && !(await this.checkIfExpired(expiryResponse))) {
          // track cache usage
          httpMetricsPublisher.publishCounter(Metrics.Names.FromCache, 1);
          return { body: await cachedResponse.json() };
        } else {
          const fetchResponse = await this.fetchFromServer(metadata, operation, request, requestUrl, httpMetricsPublisher);

          // cache
          if (!fetchResponse) {
            return { body: 'error' };
          }
          const responseClone = fetchResponse.clone();
          await cache.put(`${requestUrl}${body}`, responseClone);

          // cache for 6 hours
          const expiryTime = new Date(Date.now() + 6 * 60 * 60 * 1000).getTime();
          await cache.put(`${requestUrl}${body}&cache-expires`, new Response(JSON.stringify({ expiryTime })));

          let responseOrEmpty;

          try {
            responseOrEmpty = await fetchResponse.json();
          } catch (_) {
            responseOrEmpty = '';
          }

          return { body: responseOrEmpty };
        }
      } else {
        const resRaw = await this.fetchFromServer(metadata, operation, request, requestUrl, httpMetricsPublisher);
        if (!resRaw) {
          return { body: 'error: resRaw' };
        }

        let responseOrEmpty;

        try {
          responseOrEmpty = await resRaw.json();
        } catch (_) {
          responseOrEmpty = '';
        }

        return { body: responseOrEmpty };
      }
    };
  }

  async fetchFromServer(metadata: Metadata, operation: Operation, request: any, requestUrl: string, publisher: KatalMetrics.Publisher) {
    const httpRequestMetric = new KatalMetrics.Metric.HttpRequest(operation.name);
    let response: Response | undefined = undefined;

    publisher.publishCounter(Metrics.Names.FromFetch, 1);
    httpRequestMetric.url = requestUrl;

    try {
      const headers = new Headers();
      this.setRequestHeaders(headers, request, operation?.input?.members);
      CsrfTokenUtils.setCSRFTokenHeaderParam(headers);

      let requestBody: string;

      if (operation.name === 'PublishAssetFromUrl')
        requestBody = JSON.stringify(request); //  || operation.name === 'DeleteAsset'
      else requestBody = request?.body && JSON.stringify(request.body);

      response = await fetch(requestUrl, {
        method: operation.http.method,
        credentials: includeCredentials,
        body: requestBody,
        redirect: 'manual',
        headers,
      });
      if (response.type === 'opaqueredirect') {
        location.reload();
        return;
      }
    } catch (error) {
      // https://developer.mozilla.org/en-US/docs/Web/API/fetch#exceptions
      httpRequestMetric.setFailure();
      httpRequestMetric.statusText = (error as Error).message;
      publisher.publish(httpRequestMetric);
      throw error;
    }

    // check response
    if (!response?.ok) {
      // set failure
      httpRequestMetric.setFailure();
      httpRequestMetric.statusCode = response?.status;
      httpRequestMetric.statusText = response?.statusText;
      throw response;
      // no error handling here
    } else {
      // set succeess
      httpRequestMetric.setSuccess();
    }

    CsrfTokenUtils.renewCSRFTokenMetadata(response.headers);
    // publish metric
    publisher.publish(httpRequestMetric);

    return response;
  }

  setRequestHeaders(headers: Headers, request: any, memberMap?: ShapeMemberMap) {
    try {
      for (const member in memberMap) {
        if (memberMap[member].location === 'header' && request[member]) {
          headers.set(memberMap[member].locationName!, request[member]);
        }
      }
    } catch (e) {
      console.error("can't set request headers", e);
    }
  }

  async checkIfExpired(response: Response) {
    const json = await response.json();
    const expiryTime = json.expiryTime;
    return expiryTime < Date.now();
  }

  buildQueryString(request: any, memberMap?: ShapeMemberMap) {
    const queryStringList = [];
    let queryString = '';
    for (const member in memberMap) {
      if (memberMap[member].location !== 'querystring' || !request[member]) continue;

      queryStringList.push(`${memberMap[member].locationName}=${encodeURIComponent(request[member])}`);
    }

    if (queryStringList.length > 0) {
      queryString = `?${queryStringList.join('&')}`;
    }

    return queryString;
  }

  replaceValues<T extends ReplacementValues>(jsonObj: T, originalString: string): string {
    let replacedString = originalString;
    for (const key in jsonObj) {
      if (Object.prototype.hasOwnProperty.call(jsonObj, key)) {
        replacedString = replacedString.replace(`{${key}}`, jsonObj[key]);
      }
    }
    return replacedString;
  }

  isNARegionRequest(operation: Operation) {
    const requests = ['QueryAdvertisingAccounts', 'RetrieveASINMetadataByASINId'];
    return requests.includes(operation.name);
  }
}

export default OperationExecutorBuilder; //new OperationExecutorBuilder().build();
