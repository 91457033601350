import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Button, Card, CardActionArea, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styleGuide from 'src/config/themes/styleGuide';
import { Metrics } from 'src/constants';
import { AppContext } from '../../../../AppContext';

interface CardProps {
  post: {
    type: string;
    description: string;
    thumbnail: string;
    title: string;
    videoPreview: string;
    spotlight: string;
    path?: string;
  };
  gridColumns: number;
  isAuthenticated: () => boolean;
}

export default function AIModel(props: CardProps) {
  const context = useContext(AppContext);
  const { metrics } = context;
  const linkTo = useNavigate();
  const { post, gridColumns } = props;
  const type = post.type ? post.type : 'TEXT_TO_IMAGE';

  const handleClick = () => {
    metrics.trackMetrics(
      Metrics.Methods.ToolCard,
      { [Metrics.Names.ButtonId]: Metrics.Buttons.Try, [Metrics.Names.ToolName]: post.title },
      { [Metrics.Counters.Clicks]: 1 },
    );

    context.setSelectedTool(type);
    window.localStorage.setItem('selectedTool', type);
    linkTo(post.path ? `/${context.activePage}${post.path}` : `/${context.activePage}/editor`);
  };

  const comingSoon = post?.description.indexOf('Coming soon') !== -1;

  const handleDisabledLink = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  return (
    <Grid item xs={12} md={gridColumns}>
      <Card style={{ boxShadow: 'var(--box-shadow)', borderRadius: '12px', height: '100%' }}>
        <CardActionArea component="a" href="#" onClick={comingSoon ? handleDisabledLink : handleClick}>
          <CardMedia
            component="img"
            sx={{
              maxHeight: '270px',
              minHeight: '270px',
              width: '100%',
              height: '100%',
            }}
            image={post.thumbnail}
            alt={post.title}
          />

          <CardContent
            sx={{
              flex: 1,
              border: 1,
              borderColor: 'white',
              borderStyle: 'solid',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              background: 'white',
              marginTop: '-5px',
              zIndex: '999',
              position: 'relative',
            }}
          >
            <Stack gap={2}>
              <Typography className={'hide-text-overflow'} component="h2" variant="h3">
                {post.title}
              </Typography>

              <Typography
                className={'hide-text-overflow-2lines'}
                paragraph
                component="h2"
                variant="body1"
                dangerouslySetInnerHTML={{ __html: post.description }}
              ></Typography>
            </Stack>
            <Button
              variant="contained"
              size="small"
              disabled={comingSoon}
              color={context.colorScheme}
              onClick={handleClick}
              sx={{ ...styleGuide.primaryButtonHover }}
              style={{ padding: '9px 18px', borderRadius: '100px' }}
            >
              {comingSoon ? (
                <>Play</>
              ) : (
                <>
                  <ArrowOutwardIcon fontSize="small" style={{ fontSize: 15, marginRight: '5px' }} />
                  Play
                </>
              )}
            </Button>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
