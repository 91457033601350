import { CSRF_META_NAME } from 'src/constants';

export class CsrfTokenUtils {
  static getCSRFTokenFromMetadata() {
    const csrfTokenMeta = document.querySelector(`meta[name='${CSRF_META_NAME}']`);

    if (!csrfTokenMeta) {
      console.error(`Meta tag with name ${CSRF_META_NAME} not found.`);
    }

    return csrfTokenMeta?.getAttribute('content') ?? '';
  }

  static setCSRFTokenHeaderParam(headers: Headers) {
    const csrfToken = this.getCSRFTokenFromMetadata();

    if (csrfToken) {
      headers.set(CSRF_META_NAME, csrfToken);
    }
  }

  static renewCSRFTokenMetadata(header: Headers): void {
    const newCSRFToken = header.get(CSRF_META_NAME);
    if (!newCSRFToken) {
      return;
    }

    const csrfTokenMeta = document.querySelector(`meta[name='${CSRF_META_NAME}']`);

    if (!csrfTokenMeta) {
      console.error(`Meta tag with name ${CSRF_META_NAME} not found.`);
    }

    csrfTokenMeta?.setAttribute('content', newCSRFToken);
  }
}
