export const getWorkflowNameById = (workflowId?: string) => {
  switch (workflowId) {
    case 'BRAND_IDENTITY_TEXT_TO_IMAGE':
      return 'Generate brand images';
    case 'GENERATIVE_RESIZING':
      return 'Generative Resizing';
    case 'IMAGE_EDITOR':
      return 'Edit Images';
    case 'IMAGE_THEMING':
      return 'Image Theming';
    case 'LIFESTYLE_IMAGERY':
    case 'LIFESTYLE_IMAGERY_PROMPT_ONLY':
      return 'Lifestyle Imagery';
    case 'PARALLAX_MOTION':
      return 'Parallax Motion';
    case 'TEXT_TO_IMAGE':
    default:
      return 'Generate Images';
  }
};

export const getContentTypeByWorkflowId = (workflowId?: string) => {
  switch (workflowId) {
    case 'LIFESTYLE_IMAGERY':
      return 'Lifestyle image';
    case 'TEXT_TO_IMAGE':
      return 'Product image';
    case 'PARALLAX_MOTION':
      return 'Live image';
    case 'IMPORT_IMAGE':
      return 'Uploaded image';
    default:
      return '';
  }
};
