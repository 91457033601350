import { Stack } from '@mui/material';
import UIGenerator from './UIGenerator';

interface Control {
  controlType: string;
  controlName: string;
  controlLabel?: string;
  visible: boolean;
  parent?: boolean;
  tooltip?: string;
  section?: string;
  order: number;
}
interface RightPanelProps {
  componentList: Control[];
}

const RightPanel = ({ componentList }: RightPanelProps) => {
  return (
    <>
      <Stack
        minWidth={'310px'}
        maxWidth={'310px'}
        direction={'column'}
        padding={'5px'}
        spacing={2}
        style={{
          overflow: 'scroll',
          height: '100%',
          flexWrap: 'nowrap',
          position: 'absolute',
          right: 0,
        }}
      >
        <UIGenerator config={componentList} />
      </Stack>
    </>
  );
};

export default RightPanel;
