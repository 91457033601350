import { ContentItem, Message as MessageModel, MessageRole, QuotedMessage } from '@amzn/red-carpet-service-client';
import { Divider } from '@amzn/storm-ui';
import { useContext } from 'react';
import ImagesGrid from 'src/components/assistant/components/ImagesGrid';
import Image from 'src/components/assistant/components/ImagesGrid/image';
import Message from 'src/components/assistant/components/messageWrapper';
import { MessagePillWrapper } from 'src/components/assistant/components/messageWrapper/messagePill';
import AsinMessage from 'src/components/assistant/components/messageWrapper/messageTypes/asinMessage';
import LoadingMessage from 'src/components/assistant/components/messageWrapper/messageTypes/loadingMessage';
import TextMessage from 'src/components/assistant/components/messageWrapper/messageTypes/textMessage';
import QuoteMessage from 'src/components/assistant/components/messageWrapper/QuoteMessage';
import { AsinSummary, HeaderConfig } from 'src/components/assistant/model';
import { useConversationsMessages } from 'src/components/assistant/queries/conversationMessages';
import { CHAT_THEME } from 'src/components/assistant/theme';
import getPersonaImageSource from 'src/components/assistant/utils/getPersonaImageSource';
import useConversationId from 'src/components/assistant/utils/useConversationId';
import { ConversationContext } from 'src/components/pages/conversational/ChatPage';
import styled from 'styled-components';
import { AppContext } from '../../../../../AppContext';

const MessageWrapper = styled.div<{ isSideNavOpen: boolean }>`
  display: flex;
  width: 100%;

  // ASSISTANT messages

  &.${MessageRole.ASSISTANT} {
    margin-bottom: ${(props) => props.theme.spacing.xlarge};
    justify-content: start;
    ${MessagePillWrapper} {
      border-top-left-radius: ${CHAT_THEME.border.sharp.radius};
    }
  }

  &.${MessageRole.ASSISTANT} + &.${MessageRole.ASSISTANT} {
    margin-bottom: ${CHAT_THEME.spacing.smallPlus};
    ${MessagePillWrapper} {
      border-top-left-radius: ${CHAT_THEME.border.rounded.radius};
    }
  }

  &.${MessageRole.USER}
    + &.${MessageRole.ASSISTANT},
    &.${MessageRole.ASSISTANT}
    + &.${MessageRole.USER},
    &.${MessageRole.ASSISTANT}
    + &.${MessageRole.METADATA} {
    margin-bottom: ${(props) => props.theme.spacing.xlarge};
  }

  // USER messages

  &.${MessageRole.USER} + &.${MessageRole.USER} {
    margin-bottom: ${CHAT_THEME.spacing.smallPlus};
  }

  &.${MessageRole.USER} {
    justify-content: end;
    ${MessagePillWrapper} {
      border-bottom-right-radius: ${CHAT_THEME.border.sharp.radius};
    }
  }

  // METADATA messages

  &.${MessageRole.METADATA} + &.${MessageRole.USER} {
    margin-bottom: ${(props) => props.theme.spacing.mini};
  }

  &.${MessageRole.METADATA} {
    justify-content: end;
    ${MessagePillWrapper} {
      border-top-right-radius: ${CHAT_THEME.border.sharp.radius};
    }
  }

  // ImagesGrid messages

  &.ImagesGrid + &.${MessageRole.ASSISTANT} {
    margin-bottom: ${CHAT_THEME.spacing.smallPlus};
  }

  &.ImagesGrid + &.${MessageRole.USER} {
    margin-bottom: ${(props) => props.theme.spacing.xlarge};
  }

  &.ImagesGrid {
    max-width: 100%;
    justify-content: center;
    margin-bottom: ${(props) => props.theme.spacing.xlarge};
    padding-left: 0 52px;
  }

  // Quote messages

  &.QuoteMessage {
    margin-bottom: ${CHAT_THEME.spacing.smallPlus};
  }
`;

const StyledDivider = styled(Divider)`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.76px;
  text-align: center;
`;

interface Props {
  messages?: MessageModel[];
  showLoadingMessage?: boolean;
  headerConfig?: HeaderConfig;
  setReplyMessage: (message: QuotedMessage) => void;
  sendReplyMessage: (quotedMessage: QuotedMessage, messageText: string) => void;
}

interface MessageRendererProps {
  message: MessageModel;
  setReplyMessage?: (message: QuotedMessage) => void;
  sendReplyMessage?: (quotedMessage: QuotedMessage, messageText: string) => void;
}

export const DEFAULT_ASSISTANT_HEADER_CONFIG: HeaderConfig = {
  hasActions: true,
  imageUrl: getPersonaImageSource().image,
  name: 'Creative Agent',
  nameColor: '#ff7453',
};

function MessageRenderer({ message, setReplyMessage, sendReplyMessage }: MessageRendererProps) {
  const { isSideNavOpen } = useContext(ConversationContext);

  const headerConfig: HeaderConfig = {
    ...(message.role == MessageRole.ASSISTANT ? DEFAULT_ASSISTANT_HEADER_CONFIG : {}),
    ...(message.persona
      ? { name: message.persona, nameColor: getPersonaImageSource(message.persona).color, imageUrl: getPersonaImageSource(message.persona).image }
      : {}),
    ...(message.quotedMessage ? { hasActions: false, imageUrl: undefined, name: undefined } : {}),
  };
  const onReply = (content: ContentItem[]) => {
    if (setReplyMessage && message.messageId) {
      setReplyMessage({ quotedMessageId: message.messageId, content });
    }
  };

  const saveToAssetLibrary = (content: ContentItem[]) => {
    if (sendReplyMessage && message.messageId) {
      sendReplyMessage({ quotedMessageId: message.messageId, content }, 'Save the image to Asset Library.');
    }
  };

  if (!message.content || message.content.length == 0) throw Error('Message content cannot be empty');

  if (message.quotedMessage) {
    return (
      <MessageWrapper className={'QuoteMessage'} isSideNavOpen={isSideNavOpen}>
        <QuoteMessage contentItem={message.content[0]} quotedMessageId={message.quotedMessage} />
      </MessageWrapper>
    );
  }

  if (message.content.some((content) => content.metadata?.name == 'persona_joined')) {
    const content = message.content.find((content) => content.metadata?.name == 'persona_joined');
    if (
      !content?.metadata?.content ||
      typeof content.metadata.content !== 'object' ||
      !('id' in content.metadata.content) ||
      typeof content.metadata.content.id !== 'string'
    )
      return null;

    return (
      <MessageWrapper className={'PersonaJoined'} isSideNavOpen={isSideNavOpen}>
        <StyledDivider text={`${content.metadata.content.id?.toUpperCase()} has joined the conversation`} />
      </MessageWrapper>
    );
  }

  if (message.content.every((content) => content.image)) {
    return (
      <MessageWrapper className={'ImagesGrid'} isSideNavOpen={isSideNavOpen}>
        <ImagesGrid message={message} onReply={onReply} onSaveImage={saveToAssetLibrary} />
      </MessageWrapper>
    );
  }

  return (
    <>
      <MessageWrapper className={message.role} isSideNavOpen={isSideNavOpen}>
        <Message message={message} headerConfig={headerConfig} onReply={onReply}>
          {message.content.map((content, index) => {
            if (content.image?.source) {
              return (
                <Image
                  key={`${message.messageId}+${index}`}
                  message={message}
                  contentItemIndex={index}
                  onReply={onReply}
                  onSave={saveToAssetLibrary}
                />
              );
            }
            if (content.text?.content) {
              return <TextMessage key={`${message.messageId}+${index}`} content={content.text.content} />;
            }

            if (content.metadata?.name == 'asin_metadata') {
              return <AsinMessage key={`${message.messageId}+${index}`} content={content.metadata.content as unknown as AsinSummary} />;
            }
          })}
        </Message>
      </MessageWrapper>
    </>
  );
}

export default function ChatMessage({ messages, setReplyMessage, showLoadingMessage, sendReplyMessage }: Props) {
  const { conversationId } = useConversationId();
  const { userDetails } = useContext(AppContext);
  const { isSideNavOpen } = useContext(ConversationContext);

  const conversationMessagesQuery = useConversationsMessages(conversationId, userDetails?.alias);

  const loadingMessageConfig = conversationMessagesQuery.data?.loadingMessageConfig;

  const loadingText = loadingMessageConfig?.text || 'Thinking';

  const loadingMessage: MessageModel = {
    role: MessageRole.ASSISTANT,
    content: loadingMessageConfig?.loadingImages ? Array(4).fill({ image: { source: undefined, description: loadingText } }) : [],
    messageId: 'loading',
    conversationId: undefined,
    createdAt: new Date(),
    persona: loadingMessageConfig?.persona,
  };

  const messagesList = [
    ...(messages?.map((message) => (
      <MessageRenderer key={message.messageId} message={message} setReplyMessage={setReplyMessage} sendReplyMessage={sendReplyMessage} />
    )) ?? []),
    ...(showLoadingMessage
      ? [
          <MessageWrapper
            key={'loading-message'}
            className={loadingMessageConfig?.loadingImages ? 'ImagesGrid' : MessageRole.ASSISTANT}
            isSideNavOpen={isSideNavOpen}
          >
            {loadingMessageConfig?.loadingImages ? (
              <ImagesGrid message={loadingMessage} onReply={() => {}} onSaveImage={() => {}} />
            ) : (
              <Message message={loadingMessage} headerConfig={{ ...DEFAULT_ASSISTANT_HEADER_CONFIG, hasActions: false }}>
                <LoadingMessage loadingMessage={loadingText} />
              </Message>
            )}
          </MessageWrapper>,
        ]
      : []),
  ];

  return <>{messagesList.reverse()}</>;
}
