import { ContentItem, Message } from '@amzn/red-carpet-service-client';
import { UIDPrefix } from '@amzn/storm-ui';
import Image from 'src/components/assistant/components/ImagesGrid/image';
import { CHAT_THEME } from 'src/components/assistant/theme';
import styled from 'styled-components';

interface Props {
  message: Message;
  onReply: (contentItem: ContentItem[]) => void;
  onSaveImage: (contentItem: ContentItem[]) => void;
}

const ImagesGridWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  width: calc(100% - 104px);
  min-width: ${CHAT_THEME.compactScreenBreakPoint}px;
  max-width: 1200px;
  gap: ${CHAT_THEME.spacing.smallPlus};
  flex-wrap: wrap;
  justify-content: center;
`;

const TrailingItems = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${CHAT_THEME.spacing.smallPlus};
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

export default function ImagesGrid({ message, onReply, onSaveImage }: Props) {
  function renderImage(index: number) {
    return (
      <UIDPrefix key={`${message.messageId}-${index}`} value={`ImagesGrid-${message.messageId}-${index}`}>
        <Image message={message} contentItemIndex={index} onReply={onReply} onSave={onSaveImage} />
      </UIDPrefix>
    );
  }

  // If there are 3 or fewer items, default wrap behavior is already the most balanced
  if (message.content && message.content.length <= 3) {
    return <ImagesGridWrapper>{message.content.map((contentItem, index) => renderImage(index))}</ImagesGridWrapper>;
  }

  // Otherwise we ensure the last item never wraps by itself by grouping it with the second last item
  const leading = message.content?.slice(0, -2) || [];
  const trailing = message.content?.slice(-2) || [];

  return (
    <ImagesGridWrapper>
      {leading.map((contentItem, index) => renderImage(index))}
      <TrailingItems>{trailing.map((contentItem, index) => renderImage(leading.length + index))}</TrailingItems>
    </ImagesGridWrapper>
  );
}
