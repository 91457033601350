import { WorkflowId } from '@amzn/genaihub-typescript-client';
import { AssetType, WorkflowId as LocalWorkflowId } from 'src/components/imageModal/components/utils';

interface CopyOptions {
  usePlural?: boolean; // 'video' -> 'videos'
  useArticle?: boolean; // 'image' -> 'an image'
  useCapital?: boolean; // 'image' -> 'Image'
}

export function getAssetTypeText(assetType: AssetType | undefined, options?: CopyOptions): string {
  let assetCopy;
  let article;
  switch (assetType) {
    case AssetType.VIDEO:
      assetCopy = 'video';
      article = 'a';
      break;
    case AssetType.IMAGE:
    default:
      assetCopy = 'image';
      article = 'an';
      break;
  }

  if (options?.useCapital) assetCopy = assetCopy.charAt(0).toUpperCase() + assetCopy.slice(1);
  if (options?.usePlural) assetCopy = `${assetCopy}s`;
  if (options?.useArticle) assetCopy = `${article} ${assetCopy}`;

  return assetCopy;
}

export function getAssetTypeFromWorkflowId(workflowId: WorkflowId): AssetType {
  return workflowId === LocalWorkflowId.PARALLAX_MOTION ? AssetType.VIDEO : AssetType.IMAGE;
}
