export const DEFAULT_LOCALE = 'en-US';
export const HOMEPAGE_MESSAGE_BUNDLE_NAME = 'pages.homepage';
export const ALERTS_MESSAGE_BUNDLE_NAME = 'pages.alerts';
export const AUTH_PORTAL_ASSOCIATION_HANDLER = 'amzn_genaihub_us';
export const SIGN_OUT_PATH = '/sign-out';

export const enum AlertTargets {
  workflowLandingPage = 'workflowLandingPage',
  workFlowPage = 'workFlowPage',
  textPrompt = 'textPrompt',
  workflowPageBottomBar = 'workflowPageBottomBar',
}

export const productImageControlName = 'product_image';
export const referenceImageControlName = 'reference_image';
export const themesControlName = 'themes';

export const EVENT_LOCAL_STORAGE = 'local-storage';
export const LOCAL_STORAGE_KEY_PRODUCTS = 'products';

declare global {
  interface WindowEventMap {
    [EVENT_LOCAL_STORAGE]: StorageEvent;
  }
}

export const METRICS_DOMAINS = {
  'prod': 'test',
  beta: 'test',
  gamma: 'test',
  prod: 'prod',
};
export const METRICS_REALM = 'USAmazon';
export const METRICS_SITE = 'GenAIHub-Frontendwebsite';
export const METRICS_SERVICE_NAME = 'GenAIHub';
export const Metrics = {
  Actions: {
    Open: 'Open',
    Close: 'Close',
  },
  Buttons: {
    AuthChange: 'AuthChange',
    GoBack: 'GoBack',
    Logo: 'Logo',
    Scheme: 'Scheme',
    Theme: 'Theme',
    Try: 'Try',
    ViewAll: 'ViewAll',
  },
  Counters: {
    Clicks: 'Clicks',
    Conversations: 'Conversations',
    Count: 'Count',
    Dislike: 'Dislike',
    Download: 'Download',
    Failure: 'Failure',
    Like: 'Like',
    UserMessages: 'UserMessages',
    AgentMessages: 'AgentMessages',
    Save: 'Save',
    Success: 'Success',
    Timeout: 'Timeout',
    ImagesGenerated: 'ImagesGenerated',
    ApiError: 'ApiError',
    UiError: 'UiError',
  },
  Dimensions: {
    AccountType: 'AccountType',
    ConversationId: 'ConversationId',
    EntityId: 'EntityId',
    PageName: 'PageName',
    SessionId: 'SessionId',
    Stage: 'Stage',
    ToolName: 'ToolName',
    UserAlias: 'UserAlias',
  },
  Methods: {
    ButtonClick: 'ButtonClick',
    DropDown: 'DropDown',
    ChatFeedback: 'ChatFeedback',
    ChatMessages: 'ChatMessages',
    ChatImage: 'ChatImage',
    CreationTime: 'CreationTime',
    EnterTextPrompt: 'EnterTextPrompt',
    ErrorPage: 'ErrorPage',
    FileSelected: 'FileSelected',
    FileSelector: 'FileSelector',
    FooterClick: 'FooterClick',
    HomePage: 'HomePage',
    InperationPreview: 'InperationPreview',
    LogoClick: 'LogoClick',
    NavBarClick: 'NavBarClick',
    PageMetrics: 'PageMetrics',
    SaveAsset: 'SaveAsset',
    SettingsDrawer: 'SettingsDrawer',
    ToggleGroup: 'ToggleGroup',
    ToolCard: 'ToolCard',
    UserIcon: 'UserIcon',
    UserMenuClick: 'UserMenuClick',
    WorkflowMetrics: 'WorkflowMetrics',
    Fatal: 'Fatal',
  },
  Names: {
    Action: 'Action',
    Authenticated: 'Authenticated',
    ButtonId: 'ButtonId',
    BatchId: 'BatchId',
    Destination: 'Destination',
    Error: 'Error',
    Feedback: 'Feedback',
    FromCache: 'FromCache',
    FromFetch: 'FromFetch',
    Scheme: 'Scheme',
    Selected: 'Selected',
    Theme: 'Theme',
    Time: 'Time',
    Timeout: 'Timeout',
    ToolName: 'ToolName',
    Unselected: 'Unselected',
    UserBounced: 'UserBounced',
    UserDwellTime: 'UserDwellTime',
    UserGlanceCount: 'UserGlanceCount',
    UserViewCount: 'UserViewCount',
    Value: 'Value',
    WorkflowId: 'WorkflowId',
  },
  Values: {
    Abandoned: 'Abandoned',
    Dislike: 'Dislike',
    Home: 'Home',
    Like: 'Like',
    NotSelected: 'NotSelected',
    Selected: 'Selected',
    Unknown: 'Unknown',
  },
  Timers: {
    TextInput: 'TextInput',
  },
};

export const CSRF_META_NAME = 'anti-csrftoken-a2z';

export const STUDIO = 'STUDIO';
export const US_MARKETPLACE_ID = 'ATVPDKIKX0DER';

export const enum FA_ICON_SIZE {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
}

export const enum HEADING_TEXT_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export const selectedEntityLocalStorageKey = 'selectedEntity';
