import { Feedback, GenericResponse, WorkflowId } from '@amzn/genaihub-typescript-client';
import { Publisher } from '@amzn/katal-metrics';
import { Box, Container, Skeleton, Stack } from '@mui/material';
import { CSSProperties, Fragment, useContext, useEffect, useRef, useState } from 'react';
import { isTextPromptError } from 'src/api/errorTypes';
import { AlertBox } from 'src/components/common/alerts/alertBox';
import { ErrorText } from 'src/components/common/alerts/errorText';
import { ConfirmationSnackbar } from 'src/components/common/mui/confirmationSnackbar';
import FeedbackPopover from 'src/components/common/mui/FeedbackPopover';
import { ImagePlaceholder } from 'src/components/common/mui/ImagePlaceholder';
import { EditorContext, EditorContextState } from 'src/components/editor/EditorContext';
import { MagnifiedImage } from 'src/components/magnifiedImage/magnifiedImage';
import { WorkflowLandingPage } from 'src/components/pages/workflowLandingPage/WorkflowLandingPage';
import { Metrics } from 'src/constants';
import { AlertTargets } from 'src/constants';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { CounterMetrics, StringMetrics } from 'src/metrics';
import styles from './Editor.module.scss';
import ImageResults from './ImageResults';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import UIGenerator from './UIGenerator';
import { AppContext } from '../../../../AppContext';
import workflowResponse from '../../../config/wfResponse.json';
import ImageViewer from '../../common/mui/ImageViewer';
import { GenerationOverlay } from '../../generationOverlay/generationOverlay';
import FileUploadProgress from '../UiContols/components/FileUploadProgress';

export interface WORKFLOW_OPTIONS {
  workflowOption: WORkFLOW_OPTION;
}

interface WORkFLOW_OPTION {
  name: string;
  type: string;
}

type FileUploadEvent = {
  payload: File;
  controlName: string;
};

export const positions = {
  canvasTop: 'CANVAS_TOP',
  canvasBottom: 'CANVAS_BOTTOM',
  leftPanel: 'LEFT_PANEL',
  rightPanel: 'RIGHT_PANEL',
};

const Editor = () => {
  const defaultAspectRatio = '1.91:1';
  const [imageGallery, setImageGallery] = useState<any[]>([]);
  const [showResult, setShowResult] = useState(false);
  const [batchId, setBatchId] = useState<string | null | undefined>();
  const [polling, setPolling] = useState(false);
  const [jobInProgress, setJobInProgress] = useState<boolean>(false);
  // editor states tbd //
  const [openFileUploadDialog, setOpenFileUploadDialog] = useState<boolean>(false);
  const [workflowOptions, setWorkFlowOptions] = useState<any>({});
  const [componentVisibility, setComponentVisibility] = useState<any>({});
  const [workflowConfig, setWorkflowConfig] = useState<any>(undefined);
  const [childControls, setChildControls] = useState<any>([]);
  const genAIBackendClient = useAIBackendHubClient();
  const appContext = useContext(AppContext);
  const { metrics, selectedTool } = appContext;
  const [lastSubmittedWorkflowOptions, setLastSubmittedWorkflowOptions] = useState<any>();
  const [aspectRatio, setAspectRatio] = useState<string>(defaultAspectRatio);
  const [showLandingPage, setShowLandingPage] = useState<boolean>(true);
  const [fileUploadEvent, setFileUploadEvent] = useState<FileUploadEvent>();
  const serviceCallTimeout = useRef<ReturnType<typeof setTimeout>>();
  const servicePollInterval = useRef<ReturnType<typeof setInterval>>();
  const [showConfirmationSnackbar, setShowConfirmationSnackBar] = useState<boolean>(false);
  const [showZoomedImage, setShowZoomedImage] = useState<boolean>(false);
  const [showFeedbackDialog, setShowFeedbackDialog] = useState<boolean>(false);
  const [loadedImages, setLoadedImages] = useState<Record<string, boolean>>({});
  const [selectedImage, setSelectedImage] = useState<{
    imageSrc?: string;
    feedback?: Feedback;
  }>();
  const [feedbackSentiment, setFeedbackSentiment] = useState<object>({
    textPrompt: '',
    workflowId: '',
    contentCreationTime: 0.0,
  });
  const [ratedImages, setRatedImages] = useState<Record<string, boolean>>({});
  const [contentCreationTimeStart, setContentCreationTimeStart] = useState<number>(0.0);
  const [workflowMetrics, setWorkflowMetrics] = useState<Publisher>();
  const imageOrientation: string = 'l';

  interface aspectRatioStylesType {
    [key: string]: CSSProperties;
  }

  const aspectRatioStyles: aspectRatioStylesType = {
    '1.91:1': styles.ratio_1_91_by_1,
    '1:1': styles.ratio_1_by_1,
    '9:16': styles.ratio_9_by_16,
    '4:5': styles.ratio_4_by_5,
  };

  const editorContextState: EditorContextState = {
    workflowConfig,
    setWorkflowConfig,
    workflowOptions,
    setWorkFlowOptions,
    openFileUploadDialog,
    setOpenFileUploadDialog,
    childControls,
    setChildControls,
    fileUploadEvent,
    setFileUploadEvent,
    componentVisibility,
    setComponentVisibility,
    jobInProgress,
    setJobInProgress,
  };

  appContext.showLandingPage = showLandingPage;
  appContext.setShowLandingPage = setShowLandingPage;
  const positions = {
    canvasTop: 'CANVAS_TOP',
    canvasBottom: 'CANVAS_BOTTOM',
    leftPanel: 'LEFT_PANEL',
    rightPanel: 'RIGHT_PANEL',
  };
  const beforeUnloadListener = (imageGenInProgress: boolean) => {
    return (event: any) => {
      if (imageGenInProgress) {
        event.preventDefault();
        //Send something to back end
        return 'Image generation is in progress';
      }
    };
  };

  useEffect(() => {
    console.log(componentVisibility);
  }, [componentVisibility]);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    if (!selectedTool) {
      const storedTool = localStorage.getItem('selectedTool');
      if (storedTool) {
        appContext.setSelectedTool(storedTool); // make sure we remeber what it is
      }
    } else {
      getWorkflows();
    }
  }, [selectedTool]);

  useEffect(() => {
    const controls = workflowConfig;

    if (controls?.length) {
      const children = controls.filter((item: any) => item.parent);
      if (children.length) {
        setChildControls([...children]);
      }
    }
  }, [workflowConfig]);

  const handleError = async (error?: any) => {
    if (error instanceof Response) {
      const errorDetails = (await error.json()) as GenericResponse;

      appContext.setAlertEvent({
        type: 'error',
        code: errorDetails.code,
        target: isTextPromptError(errorDetails.code) ? AlertTargets.textPrompt : AlertTargets.workFlowPage,
      });
    } else {
      appContext.setAlertEvent({
        type: 'error',
        target: AlertTargets.workFlowPage,
      });
    }
  };

  const getWorkflows = async () => {
    try {
      // const response = await genAIBackendClient.retrieveWorkflowsList({ body: { workflowType: selectedTool } });
      //const result: any = response.body;
      const result: any = workflowResponse;
      if (result.data[0]?.controls) {
        let defaults: any = {};
        const controls = result.data[0]?.controls;

        controls
          .filter((control: any) => control.defaultValue !== undefined)
          .map((control: any) => {
            defaults[control.controlName] = { value: control.defaultValue };
          });

        setWorkFlowOptions(defaults);
        setWorkflowConfig(controls);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clearPollingState = () => {
    clearTimeout(serviceCallTimeout.current);
    clearInterval(servicePollInterval.current);
    setPolling(false);
    window.onbeforeunload = null;
  };

  const trackWorkflowMetrics = (strings?: StringMetrics, counters?: CounterMetrics): Publisher => {
    let publisher: Publisher;
    if (workflowMetrics) {
      // If there is metrics then use them
      publisher = metrics.trackMetricsWithPublisher(workflowMetrics, strings, counters);
    } else {
      // If not then create a new action child and save it for late
      publisher = metrics.trackMetrics(Metrics.Methods.WorkflowMetrics, strings, counters);
      setWorkflowMetrics(publisher);
    }
    return publisher;
  };

  const trackContentCreationTime = (): number => {
    const creationTimeInMilliseconds = Date.now() - contentCreationTimeStart;
    trackWorkflowMetrics({}, { [Metrics.Names.Time]: creationTimeInMilliseconds });
    return creationTimeInMilliseconds;
  };

  // References for unmount
  const jobInProgressRef = useRef<boolean>(jobInProgress); // For unmount
  const trackWorkflowMetricsRef = useRef<Function>(trackWorkflowMetrics); // For unmount
  const trackContentCreationTimeRef = useRef<Function>(trackContentCreationTime); // For unmount

  // Update references for unmount
  useEffect(() => {
    // Updated the references for the unmount function
    jobInProgressRef.current = jobInProgress;
    trackWorkflowMetricsRef.current = trackWorkflowMetrics;
    trackContentCreationTimeRef.current = trackContentCreationTime;
  }, [jobInProgress, trackWorkflowMetrics, trackContentCreationTime]);

  // Listen for unmount of Editor to report abandondments
  // To listen for the unmount of th
  useEffect(() => {
    // On unmount of the Editor checks for an in progress job
    // If there is a job running, report an abandondedment
    // Maybe bad-smelling, but it works

    return () => {
      // need refs to the reactive states and functions
      const inProgress = jobInProgressRef.current;
      const trackWorkflowMetrics = trackWorkflowMetricsRef.current;
      const trackContentCreationTime = trackContentCreationTimeRef.current;

      if (inProgress) {
        console.log('Workflow abandonded', inProgress);
        trackContentCreationTime();
        trackWorkflowMetrics({ [Metrics.Names.Error]: Metrics.Values.Abandoned }, { [Metrics.Values.Abandoned]: 1 });
      }
    };
  }, []);

  useEffect(() => {
    if (!polling) {
      setJobInProgress(false);
      return;
    }

    const timeout = setTimeout(() => {
      // Track workflow timeouts after sixty seconds
      trackWorkflowMetrics({}, { [Metrics.Counters.Timeout]: 1 });
      clearPollingState();
      appContext.setAlertEvent({
        type: 'error',
        target: AlertTargets.workFlowPage,
        resourceKey: 'image_generation_failure_text',
      });
    }, 60000);

    const interval = setInterval(async () => {
      try {
        const response = await genAIBackendClient.retrieveResultByWorkflowIdAndBatchId({
          batchId: batchId as string,
          workflowId: appContext.selectedTool as WorkflowId,
        });

        const result = response.body as any;

        if (result.jobs != null && result.jobs.length > 0) {
          if (!['RUNNING', 'COMPLETED'].includes(result.jobs[0].status)) {
            console.log('Workflow status (polling)', result.jobs[0].status);
            throw new Error(result.jobs[0].status);
          }
          if (result.jobs[0].status !== 'COMPLETED') return;
          clearTimeout(serviceCallTimeout.current);
          clearInterval(servicePollInterval.current);

          if (result.jobs[0].urls.length === 0) {
            throw new Error('No images returned');
          }

          const numImages: number = result.jobs[0].urls.length;
          const images = result.jobs[0].urls.map((url: string) => ({ src: url, orientation: imageOrientation }));
          const computedTimeInSeconds = trackContentCreationTime() / 1000;

          for (const genImage of images) {
            const bgImg = new Image();
            bgImg.onload = function () {
              setLoadedImages((l) => {
                return { ...l, [genImage.src]: true };
              });
            };
            bgImg.src = genImage.src;
          }

          setImageGallery(images);
          setFeedbackSentiment({ ...feedbackSentiment, contentCreationTime: computedTimeInSeconds });
          trackWorkflowMetrics({}, { GeneratedImageCount: numImages, [Metrics.Counters.Success]: 1 });
        } else {
          clearPollingState();
        }
      } catch (error) {
        console.log(error);
        const errorMessage: string = error instanceof Response ? `${error.status}: ${error.statusText}` : ((error as Error).message ?? '');
        trackWorkflowMetrics({ [Metrics.Names.Error]: errorMessage }, { [Metrics.Counters.Failure]: 1 });
        trackContentCreationTime();
        clearPollingState();
        handleError(error);
      }
    }, 3000);

    servicePollInterval.current = interval;
    serviceCallTimeout.current = timeout;
    //Clearing the interval
    return () => {
      clearPollingState();
    };
  }, [polling]);

  const handleSubmit = async () => {
    if (polling) return;

    setContentCreationTimeStart(Date.now());

    const workflowSubmition: { [key: string]: string } = {};
    for (let key in workflowOptions) {
      workflowSubmition[key] = workflowOptions[key].value ?? '';
    }

    // Sets the workflow metrics to be used later
    // Maintains the actionId for the whole process
    const publisher = metrics.trackMetrics(
      Metrics.Methods.WorkflowMetrics,
      {
        ...workflowSubmition,
        [Metrics.Names.WorkflowId]: appContext.selectedTool ?? Metrics.Values.Unknown,
      },
      { [Metrics.Counters.Count]: 1 },
    );
    setWorkflowMetrics(publisher);

    window.onbeforeunload = beforeUnloadListener(true);
    appContext.setAlertEvent(undefined);

    try {
      setShowLandingPage(false);
      setJobInProgress(true);
      setShowResult(false);
      setLastSubmittedWorkflowOptions({ ...workflowSubmition });
      setFeedbackSentiment({ workflowId: appContext.selectedTool, textPrompt: workflowOptions.text_prompt.value });

      const response = await genAIBackendClient.submitWorkflowById({
        workflowId: appContext.selectedTool as WorkflowId,
        body: {
          workflowOptions: workflowSubmition,
        },
      });
      const workflowResult = await response.body;

      // workflowMetrics isn't available yet use used stored publisher
      if (workflowResult.batchId) {
        metrics.trackMetricsWithPublisher(publisher, { [Metrics.Names.BatchId]: workflowResult.batchId });
      }

      setBatchId(workflowResult.batchId);
      setPolling(true);
    } catch (error) {
      const errorMessage: string = error instanceof Response ? `${error.status}: ${error.statusText}` : ((error as Error).message ?? '');
      metrics.trackMetricsWithPublisher(publisher, { [Metrics.Names.Error]: errorMessage }, { [Metrics.Counters.Failure]: 1 });
      setJobInProgress(false);
      clearPollingState();
      console.log(error);
      handleError(error);
    }
  };

  const [openViewer, setOpenViewer] = useState<boolean>(false);
  const viewerImage: string = '';

  const renderRightPanel = () => {
    const controls = workflowConfig || [];
    return controls.filter((control: any) => control.position == positions.rightPanel);
  };
  const renderLeftPanel = () => {
    const controls = workflowConfig || [];
    return controls.filter((control: any) => control.position == positions.leftPanel);
  };

  const renderCanvas_top = () => {
    const controls = workflowConfig || [];
    return controls.filter((control: any) => control.position == positions.canvasTop);
  };

  const renderCanvas_bottom = () => {
    const controls = workflowConfig || [];
    return controls.filter((control: any) => control.position == positions.canvasBottom);
  };

  const doesImageExist = (index: number) => {
    return imageGallery && imageGallery.length > 0 && imageGallery[index];
  };

  const showSnackbar = (imageSrc: string) => {
    setRatedImages({ ...ratedImages, [imageSrc]: true });
    setShowConfirmationSnackBar(true);
    setTimeout(() => {
      setShowConfirmationSnackBar(false);
    }, 3000);
  };

  const EditorSkeleton = () => (
    <Stack direction="row" gap={2} margin={5}>
      <Box>
        <Skeleton sx={{ bgcolor: 'grey.200', height: { xs: '90vh', md: '90vh' }, width: { xs: '90vw', md: '65vw' } }} variant="rectangular" />
      </Box>
      <Box>
        <Skeleton sx={{ bgcolor: 'grey.200', height: { xs: '90vh', md: '90vh' }, width: '30vw' }} variant="rectangular" />
      </Box>
    </Stack>
  );

  useEffect(() => {
    if (lastSubmittedWorkflowOptions) {
      setAspectRatio(lastSubmittedWorkflowOptions.aspect_ratio);
    }
  }, [lastSubmittedWorkflowOptions]);

  useEffect(() => {
    if (workflowOptions.aspect_ratio) {
      setAspectRatio(showResult || jobInProgress ? lastSubmittedWorkflowOptions.aspect_ratio : workflowOptions.aspect_ratio.value);
    }
  }, [workflowOptions]);

  const selectImage = (imageSrc: string, feedback?: Feedback) => {
    setSelectedImage({
      imageSrc,
      feedback,
    });
    setShowZoomedImage(false);
    setShowFeedbackDialog(true);
  };

  const zoomImage = (imageSrc: string) => {
    setSelectedImage({
      imageSrc,
    });
    setShowZoomedImage(true);
    setShowFeedbackDialog(false);
  };

  useEffect(() => {
    let count = 0;
    for (const genImage of imageGallery) {
      if (!loadedImages[genImage.src]) {
        setShowResult(false);
        return;
      } else count++;
    }

    if (count != 0 && count == imageGallery.length) {
      setPolling(false);
      setShowResult(true);
    }
  }, [loadedImages]);

  return (
    <EditorContext.Provider value={editorContextState}>
      {showLandingPage ? (
        <WorkflowLandingPage>{workflowConfig && <UIGenerator config={renderCanvas_bottom()} submit={handleSubmit} />}</WorkflowLandingPage>
      ) : (
        <Fragment>
          {!workflowConfig && <EditorSkeleton />}
          {workflowConfig && (
            <Container
              maxWidth={false}
              className={'page-margin'}
              sx={{ mt: '1em', mb: '1em', height: '100%', display: 'flex', justifyContent: 'center' }}
              style={{ padding: 0, width: 'auto' }}
            >
              <ImageViewer
                src={viewerImage}
                imageGallery={imageGallery}
                cols={4}
                openViewer={openViewer}
                setOpenViewer={setOpenViewer}
                MultiViewComponent={ImageResults}
              ></ImageViewer>

              <Stack
                height={'100%'}
                direction="row"
                justifyContent={'center'}
                style={{ width: '100%', maxWidth: '2100px', flexWrap: 'nowrap' }}
                gap={'80px'}
              >
                {
                  //<Paper elevation={3} sx={{ ml: '1em', display: { xs: 'none', md: 'block' } }}>
                  <LeftPanel componentList={renderLeftPanel()} />
                  //</Paper>
                }

                <Box flex={1} rowGap={'15px'} display={'flex'} flexDirection={'column'} alignItems={'center'} paddingTop={'5px'}>
                  {appContext.alertEvent?.target === AlertTargets.workFlowPage && (
                    <Box width={'90%'}>
                      <AlertBox alertEvent={appContext.alertEvent} onClose={() => appContext.setAlertEvent(undefined)} />
                    </Box>
                  )}

                  <UIGenerator config={renderCanvas_top()} submit={handleSubmit} />
                  <FileUploadProgress
                    fileList={Object.keys(workflowOptions).filter((item: any) => workflowOptions[item].type === 'image')}
                  ></FileUploadProgress>
                  {
                    <Box width={'100%'} display={'flex'} height={'100%'} flexDirection={'column'} gap={'15px'}>
                      <Box height={'100%'} width={'100%'} columnGap={'15px'} className={styles.contentPlaceholderBox}>
                        <div className={`${styles.contentPlaceholderChild} ${aspectRatioStyles[aspectRatio]}`}>
                          {showResult && doesImageExist(0) ? (
                            <Fragment>
                              <div className={styles.image} style={{ background: `url(${imageGallery[0].src})` }} />
                              <GenerationOverlay
                                onZoom={zoomImage}
                                onSelected={selectImage}
                                disableSentiment={ratedImages[imageGallery[0].src]}
                                imageSrc={imageGallery[0].src}
                              />
                            </Fragment>
                          ) : (
                            <ImagePlaceholder animate={jobInProgress} showMessage={showResult && !doesImageExist(0)} />
                          )}
                        </div>
                        <div className={`${styles.contentPlaceholderChild} ${aspectRatioStyles[aspectRatio]}`}>
                          {showResult && doesImageExist(1) ? (
                            <Fragment>
                              <div className={styles.image} style={{ background: `url(${imageGallery[1].src})` }} />
                              <GenerationOverlay
                                onZoom={zoomImage}
                                onSelected={selectImage}
                                disableSentiment={ratedImages[imageGallery[1].src]}
                                imageSrc={imageGallery[1].src}
                              />
                            </Fragment>
                          ) : (
                            <ImagePlaceholder animate={jobInProgress} showMessage={showResult && !doesImageExist(1)} />
                          )}
                        </div>
                      </Box>
                      <Box height={'100%'} width={'100%'} columnGap={'15px'} className={styles.contentPlaceholderBox}>
                        <div className={`${styles.contentPlaceholderChild} ${aspectRatioStyles[aspectRatio]}`}>
                          {showResult && doesImageExist(2) ? (
                            <Fragment>
                              <div className={styles.image} style={{ background: `url(${imageGallery[2].src})` }} />
                              <GenerationOverlay
                                onZoom={zoomImage}
                                onSelected={selectImage}
                                disableSentiment={ratedImages[imageGallery[2].src]}
                                imageSrc={imageGallery[2].src}
                              />
                            </Fragment>
                          ) : (
                            <ImagePlaceholder animate={jobInProgress} showMessage={showResult && !doesImageExist(2)} />
                          )}
                        </div>
                        <div className={`${styles.contentPlaceholderChild} ${aspectRatioStyles[aspectRatio]}`}>
                          {showResult && doesImageExist(3) ? (
                            <Fragment>
                              <div className={styles.image} style={{ background: `url(${imageGallery[3].src})` }} />
                              <GenerationOverlay
                                onZoom={zoomImage}
                                onSelected={selectImage}
                                disableSentiment={ratedImages[imageGallery[3].src]}
                                imageSrc={imageGallery[3].src}
                              />
                            </Fragment>
                          ) : (
                            <ImagePlaceholder animate={jobInProgress} showMessage={showResult && !doesImageExist(3)} />
                          )}
                        </div>
                      </Box>
                    </Box>
                  }
                  <div className={styles.controls} style={{ width: `calc(100%-400px)`, zIndex: 1000 }}>
                    <UIGenerator config={renderCanvas_bottom()} submit={handleSubmit} />
                    {appContext.alertEvent?.control?.position === positions.canvasBottom && (
                      <Box display={'flex'} paddingTop={'10px'}>
                        <ErrorText {...appContext.alertEvent} />
                      </Box>
                    )}
                  </div>
                  {/*
                  <Paper elevation={3} sx={{ ml: '1em', display: { xs: 'block', md: 'none' } }}>
                    {
                      //	<MobileControls />
                      <MobilePanel componentList={renderRightPanel()} />
                    }
                  </Paper>
                  */}
                </Box>
                <Box position={'relative'} minWidth={'310px'} maxWidth={'310px'}>
                  <RightPanel componentList={renderRightPanel()} />
                </Box>
              </Stack>
            </Container>
          )}
          {showConfirmationSnackbar && <ConfirmationSnackbar onExit={() => setShowConfirmationSnackBar(false)} />}
          {showZoomedImage && selectedImage?.imageSrc && (
            <MagnifiedImage
              onSelected={selectImage}
              disableSentiment={ratedImages[selectedImage.imageSrc]}
              aspectRatio={aspectRatio}
              onBlur={() => setShowZoomedImage(false)}
              imageUrl={selectedImage.imageSrc}
            />
          )}
          {selectedImage?.imageSrc && selectedImage?.feedback && (
            <FeedbackPopover
              src={selectedImage.imageSrc}
              feedbackSentiment={feedbackSentiment}
              feedback={selectedImage.feedback}
              openResultViewer={showFeedbackDialog}
              setOpenResultViewer={setShowFeedbackDialog}
              setFeedbackSent={showSnackbar}
            ></FeedbackPopover>
          )}
        </Fragment>
      )}
    </EditorContext.Provider>
  );
};
export default Editor;
