export enum Stage {
  BETA = 'beta',
  GAMMA = 'gamma',
  PROD = 'prod',
}

export const STAGE = 'prod' as Stage;

export const getAdConsoleUrlByStage = (stage: Stage = STAGE) => {
  switch (stage) {
    case Stage.BETA:
      return 'https://advertising.integ.amazon.com';
    case Stage.GAMMA:
      return 'https://advertising-preprod.amazon.com';
    case Stage.PROD:
    default:
      return 'https://advertising.amazon.com';
  }
};
