import { RESIZE_DIRECTION } from 'src/components/imageModal/components/utils/types';

export const getResizeDirection = (direction: string | undefined) => {
  switch (direction?.toUpperCase()) {
    case 'HORIZONTAL_LEFT':
      return RESIZE_DIRECTION.HORIZONTAL_LEFT;
    case 'HORIZONTAL_MIDDLE':
      return RESIZE_DIRECTION.HORIZONTAL_MIDDLE;
    case 'HORIZONTAL_RIGHT':
      return RESIZE_DIRECTION.HORIZONTAL_RIGHT;
    case 'VERTICAL_DOWN':
      return RESIZE_DIRECTION.VERTICAL_DOWN;
    case 'VERTICAL_MIDDLE':
      return RESIZE_DIRECTION.VERTICAL_MIDDLE;
    case 'VERTICAL_UP':
      return RESIZE_DIRECTION.VERTICAL_UP;
    default:
      return RESIZE_DIRECTION.HORIZONTAL_MIDDLE;
  }
};
