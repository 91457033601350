import React, { useState, useLayoutEffect } from 'react';

export function useContainerDimensions(ref: React.RefObject<Element>) {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setDimensions({
          width: entry.target.clientWidth,
          height: entry.target.clientHeight,
        });
      }
    });

    if (ref.current) observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref?.current]);

  return dimensions;
}
