import { FC } from 'react';
import { AssetType } from 'src/components/imageModal/components/utils';
import { getAssetTypeText } from 'src/components/utils/assetUtils';

export const FAILURE_TEXT = 'Sorry, something went wrong. Please try again later.';
export const PROCESSING_TEXT = 'This is taking longer than expected. To save your asset, wait a little longer or try again later.';

export const SUCCESS_TEXT = 'saved to feed';

export const PublishAssetProcessingNotification = () => {
  return <>{PROCESSING_TEXT}</>;
};

export const PublishAssetFailureNotification = () => {
  return <>{FAILURE_TEXT}</>;
};

export const PublishAssetSuccessNotification: FC<{ assetType: AssetType }> = (props: { assetType: AssetType }) => {
  return (
    <div data-testid="publish-asset-success-notification">
      {getAssetTypeText(props.assetType, { useCapital: true })} {SUCCESS_TEXT}
    </div>
  );
};
